import { ActionFunction } from 'redux-actions';

import Dashboard from '~/app/backoffice/components/Dashboard';
import actions from '~/app/entities/actions';
import { userSchema } from '~/app/entities/schema';
import { TableSelectionMode } from '~/app/shared/components/constants';
import ItemPicker from '~/app/shared/components/ItemPicker';
import Modal, { ModalBody } from '~/app/shared/components/Modal';
import {
  ActionCallback,
  ActionCallbackProps,
  FetchFilterOptions,
} from '~/app/shared/components/types';
import { useToggles } from '~/app/shared/hooks';
import { isEmpty, upperFirst } from 'lodash-es';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Typography } from '~/common/components/Typography';
import { Stack } from '@mui/material';
import rql from '~/vendor/rql';

interface PeoplePickerProps {
  dialogTitle?: string;
  helpText?: string;
  fetchItemsAction?: ActionFunction<any>;
  fetchItemsOptions?: Record<string, any>;
  fetchFiltersAction?: ActionFunction<any>;
  fetchFiltersOptions?: FetchFilterOptions;
  initialFiltersConfig?: Record<string, any>[];
  pageSize?: number;
  rowsPerPageOptions?: number[];
  handleClose: () => void;
  handleBack?: () => void;
  handleCancel?: () => void;
  actionButtonLabel?: string;
  actionButtonCallback: ActionCallback;
  actionButtonIsDisabled?: (props: ActionCallbackProps) => boolean;
  intialSelectedIds?: string[] | number[];
  defaultOrdering?: string;
  enableSelectAll?: boolean;
  selectionMode?: TableSelectionMode;
  cacheKey?: string;
  cancelLabel?: string;
}

const DEFAULT_PAGE_SIZE = 5;

const PeoplePicker = ({
  dialogTitle = 'Select people',
  helpText,
  fetchItemsAction = actions.userData.retrieveListRql,
  fetchItemsOptions = {},
  fetchFiltersAction = actions.userData.retrieveFilters,
  fetchFiltersOptions,
  initialFiltersConfig,
  pageSize,
  rowsPerPageOptions = [5, 15, 25, 50, 100, 250],
  handleClose,
  handleBack,
  handleCancel,
  actionButtonLabel = 'Confirm',
  actionButtonCallback,
  actionButtonIsDisabled = ({ selectedItems }: ActionCallbackProps) => isEmpty(selectedItems),
  intialSelectedIds = [],
  defaultOrdering = 'name',
  enableSelectAll = false,
  selectionMode = TableSelectionMode.single,
  cacheKey = '',
  cancelLabel = 'Cancel',
}: PeoplePickerProps) => {
  const { toggle_mentor_preferences: toggleMentorPreferences } = useToggles();

  const isMentor = ({
    is_mentor,
    has_mentor_profile,
  }: {
    is_mentor: boolean;
    has_mentor_profile: boolean;
  }): boolean => {
    return toggleMentorPreferences ? is_mentor || has_mentor_profile : is_mentor;
  };

  const defaultFetchItemsOptions = {
    view_mode: ['people_picker'],
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Person',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1.5,
      display: 'flex',
      renderCell: ({ row: user }) => <Dashboard.UserCell data={user} showAvatar highlighted />,
    },
    {
      field: 'email',
      headerName: 'Email',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1.5,
    },
    {
      field: 'role.name',
      headerName: 'Role',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1,
      renderCell: ({ row: { role, is_mentor, has_mentor_profile } }) =>
        upperFirst(role) + (isMentor({ is_mentor, has_mentor_profile }) ? ', Mentor' : ''),
    },
    {
      field: 'department',
      headerName: 'Department',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'Location',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1,
      renderCell: ({ row: { location } }) => (
        <Dashboard.LocationCell data={{ location, allowsLocal: true }} />
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1.5,
    },
    {
      field: 'working_since',
      headerName: 'Hire date',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1,
      renderCell: ({ row: { working_since } }) =>
        working_since && <Dashboard.DateCell data={working_since} includeYear />,
    },
  ];

  return (
    <Modal title={dialogTitle} width={1240} handleClose={handleClose} handleBack={handleBack}>
      <ModalBody>
        {helpText && (
          <Stack pb={2}>
            <Typography>{helpText}</Typography>
          </Stack>
        )}
        <ItemPicker
          initialFiltersConfig={initialFiltersConfig}
          fetchAction={fetchItemsAction}
          fetchActionOptions={rql({
            ...defaultFetchItemsOptions,
            ...fetchItemsOptions,
          })}
          schema={userSchema}
          filtersAction={fetchFiltersAction}
          fetchFiltersOptions={fetchFiltersOptions}
          defaultOrdering={defaultOrdering}
          columns={columns}
          pageSize={pageSize ?? DEFAULT_PAGE_SIZE}
          rowsPerPageOptions={rowsPerPageOptions}
          actions={[
            {
              color: 'error',
              label: cancelLabel,
              callback: handleCancel ?? handleClose,
            },
            {
              label: actionButtonLabel,
              callback: actionButtonCallback,
              isDisabled: actionButtonIsDisabled,
            },
          ]}
          filterBarInputWidth="240px"
          disabledItemsIds={intialSelectedIds}
          enableSelectAll={enableSelectAll}
          selectionMode={selectionMode}
          cacheKey={cacheKey}
        />
      </ModalBody>
    </Modal>
  );
};

export default PeoplePicker;

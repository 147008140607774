import React, { Suspense } from 'react';

import { lazyWithRetry } from '~/services/utils';
import Loading from '~/app/shared/components/Loading';
import { ErrorBoundary } from '~/app/shared';

const TextEditor = lazyWithRetry(() => import(/* webpackChunkName: "TextEditor" */ './TextEditor'));

const LazyTextEditor = (props) => (
  <ErrorBoundary>
    <Suspense fallback={<Loading />}>
      <TextEditor {...props} />
    </Suspense>
  </ErrorBoundary>
);

export default LazyTextEditor;

import colors from '~/services/colors';
import { Progress } from '~/app/shared-content-item/interfaces';
import { get, isNil, isNumber, round } from 'lodash-es';
import { Typography } from '~/common/components/Typography';
import { LinearProgressWithLabel } from '~/common/components/LinearProgressWithLabel';
import { styled } from '@mui/material';

interface AssignmentProgressBarProps {
  progress?: Progress;
  defaultProgressValue?: number | null;
  className?: string;
}

const Wrapper = styled('div')`
  margin: 0px;
`;

const AssignmentProgressBar = (props: AssignmentProgressBarProps) => {
  const { progress, defaultProgressValue = 0, className } = props;

  // Use || instead of get's default param to allow the defaultProgressValue to take precedence
  // over the progress.progress (i.e.: 0 || null = null; null || 0 = 0)
  const progressValue = get(progress, 'progress') ?? defaultProgressValue;
  const hasNumberProgress = !isNil(progressValue) && isNumber(progressValue);
  const progressPercentageValue = hasNumberProgress ? round(progressValue * 100, 1) : undefined;

  return (
    <Wrapper className={className}>
      {hasNumberProgress && (
        <LinearProgressWithLabel
          variant="determinate"
          labelVariant="caption"
          value={progressPercentageValue}
        />
      )}
      {!hasNumberProgress && (
        <Typography textAlign="left" variant="caption" color={colors.neutral600}>
          No data available
        </Typography>
      )}
    </Wrapper>
  );
};

export default AssignmentProgressBar;

import { FilterDef } from '~/app/filters/components/RQLFilterBar/RQLFilterBar';
import { FiltersDefinition, FiltersDefinitionProps } from '~/app/filters/types';
import { get } from 'lodash-es';

type OwnFiltersDefinitionProps = Omit<
  FiltersDefinitionProps,
  'ordering' | 'setOrdering' | 'fixedFilters'
>;

export const useMyHubFiltersDefinition = (props: OwnFiltersDefinitionProps): FiltersDefinition => {
  const { filters, updateFilter } = props;

  const allFilters: FilterDef[] = [
    {
      type: 'search_bar',
      placeholder: 'Search by title, tags, locations, names, skills...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
      },
    },
    {
      type: 'model_select',
      label: 'Tags',
      value: get(filters, 'tag', null),
      multiple: true,
      sortOptions: true,
      queryName: 'tags',
      onChange: (newValue) => updateFilter({ tag: newValue }),
    },
  ];

  return {
    filters: allFilters,
    moreFilters: [],
  };
};

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ScheduledTrackContentItemCard } from '~/features/scheduled_tracks/components/ScheduledTrackContentItemCard';
import { DefaultContentItemCard } from '~/features/contentitems/components/DefaultContentItemCard/DefaultContentItemCard';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { EventContentItemCard } from '~/features/events/components/EventContentItemCard';
import { TrackContentItemCard } from '~/features/tracks/components/TrackContentItemCard/TrackContentItemCard';

type OptimalContentItemCardProps = {
  contentItem: ContentItem;
};

export function OptimalContentItemCard(props: OptimalContentItemCardProps) {
  const { contentItem } = props;

  const contentType = contentItem.content_type;

  if (contentType === CONTENT_TYPES.event) {
    return <EventContentItemCard event={contentItem} />;
  }

  if (contentType === CONTENT_TYPES.scheduled_track) {
    return <ScheduledTrackContentItemCard scheduledTrack={contentItem} />;
  }

  if (contentType === CONTENT_TYPES.track) {
    return <TrackContentItemCard track={contentItem} />;
  }

  return <DefaultContentItemCard contentItem={contentItem} />;
}

function joinTags(tags) {
  return tags.join('\n ');
}

const tagsCheatsheet = {
  account_validated_notification: joinTags([
    '`{{ user_name }}`: full name of the person sending invite',
    '`{{ organization_name }}`: the name of your organization, as set in [System Settings](/a/system-settings/site/#general)',
  ]),
  assignment_reminder_notification: joinTags([
    "`{{ assignee_name }}`: assignee's full name",
    "`{{ assignee_first_name }}`: assignee's first name",
    '`{{ inviter_name }}`: name of whoever assigned the user to the content item',
    '`{{ inviter_email }}`: email of whoever assigned the user to the content item',
    "`{{ content_name }}`: content item's name",
    '`{{ content_link }}`: URL to the content item',
    '`{{ assigned_date }}`: the date when this content item was assigned',
    '`{{ due_date }}`: the date when this content item is due',
    "`{{ assignment_state }}`: the state of the assignment (e.g. 'not_started', 'in_progress', 'completed', 'dropped', 'exempted')",
    '`{{ is_past_due }}`: boolean that informs whether the assignment is past due or not',
  ]),
  second_assignment_reminder_notification: joinTags([
    "`{{ assignee_name }}`: assignee's full name",
    "`{{ assignee_first_name }}`: assignee's first name",
    '`{{ inviter_name }}`: name of whoever assigned the user to the content item',
    '`{{ inviter_email }}`: email of whoever assigned the user to the content item',
    "`{{ content_name }}`: content item's name",
    '`{{ content_link }}`: URL to the content item',
    '`{{ assigned_date }}`: the date when this content item was assigned',
    '`{{ due_date }}`: the date when this content item is due',
    "`{{ assignment_state }}`: the state of the assignment (e.g. 'not_started', 'in_progress', 'completed', 'dropped', 'exempted')",
    '`{{ is_past_due }}`: boolean that informs whether the assignment is past due or not',
  ]),
  third_assignment_reminder_notification: joinTags([
    "`{{ assignee_name }}`: assignee's full name",
    "`{{ assignee_first_name }}`: assignee's first name",
    '`{{ inviter_name }}`: name of whoever assigned the user to the content item',
    '`{{ inviter_email }}`: email of whoever assigned the user to the content item',
    "`{{ content_name }}`: content item's name",
    '`{{ content_link }}`: URL to the content item',
    '`{{ assigned_date }}`: the date when this content item was assigned',
    '`{{ due_date }}`: the date when this content item is due',
    "`{{ assignment_state }}`: the state of the assignment (e.g. 'not_started', 'in_progress', 'completed', 'dropped', 'exempted')",
    '`{{ is_past_due }}`: boolean that informs whether the assignment is past due or not',
  ]),
  session_reminder_notification: joinTags([
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    "`{{ attendee_name }}`: attendee's full name",
    "`{{ attendee_first_name }}`: attendee's first name",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ managers }}`: all program managers for this session's program, if set",
    "`{{ manager.name }}`: the name of the manager of this session's program, if set",
    "`{{ manager.email }}`: the email of the manager of this session's program, if set",
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: the name of this session's program",
    '`{{ session_start_time }}`: the date and time when the session starts (unformatted)',
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_time_info_in_attendee_timezone }}`: the date and time info for this session in the attendee's timezone",
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    '`{{ enrolled_at }}`: the date when the user was enrolled to this session',
    "`{{ cancellation_cutoff_offset }}`: pre-session cut-off offset, formatted as hours, or '1 day' if exactly 24 hours",
    '`{{ cancellation_cutoff_time_info }}`: pre-session cut-off, formatted as date and time',
    '`{{ now }}`: the current date and time',
  ]),
  second_session_reminder_notification: joinTags([
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    "`{{ attendee_name }}`: attendee's full name",
    "`{{ attendee_first_name }}`: attendee's first name",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ managers }}`: all program managers for this session's program, if set",
    "`{{ manager.name }}`: the name of the manager of this session's program, if set",
    "`{{ manager.email }}`: the email of the manager of this session's program, if set",
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: the name of this session's program",
    '`{{ session_start_time }}`: the date and time when the session starts (unformatted)',
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_time_info_in_attendee_timezone }}`: the date and time info for this session in the attendee's timezone",
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    '`{{ enrolled_at }}`: the date when the user was enrolled to this session',
    "`{{ cancellation_cutoff_offset }}`: pre-session cut-off offset, formatted as hours, or '1 day' if exactly 24 hours",
    '`{{ cancellation_cutoff_time_info }}`: pre-session cut-off, formatted as date and time',
    '`{{ now }}`: the current date and time',
  ]),
  third_session_reminder_notification: joinTags([
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    "`{{ attendee_name }}`: attendee's full name",
    "`{{ attendee_first_name }}`: attendee's first name",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ managers }}`: all program managers for this session's program, if set",
    "`{{ manager.name }}`: the name of the manager of this session's program, if set",
    "`{{ manager.email }}`: the email of the manager of this session's program, if set",
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: the name of this session's program",
    '`{{ session_start_time }}`: the date and time when the session starts (unformatted)',
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_time_info_in_attendee_timezone }}`: the date and time info for this session in the attendee's timezone",
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    '`{{ enrolled_at }}`: the date when the user was enrolled to this session',
    "`{{ cancellation_cutoff_offset }}`: pre-session cut-off offset, formatted as hours, or '1 day' if exactly 24 hours",
    '`{{ cancellation_cutoff_time_info }}`: pre-session cut-off, formatted as date and time',
    '`{{ now }}`: the current date and time',
  ]),
  session_host_reminder_notification: joinTags([
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    "`{{ host_timezone }}`: the timezone of this session's host",
    '`{{ attendees_names }}`: attendeess full names',
    '`{{ session_agenda }}`: attendee agenda(s) for this session',
    "`{{ managers }}`: all program managers for this session's program, if set",
    "`{{ manager.name }}`: the name of the manager of this session's program, if set",
    "`{{ manager.email }}`: the email of the manager of this session's program, if set",
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: the name of this session's program",
    '`{{ session_start_time }}`: the date and time when the session starts (unformatted)',
    '`{{ session_timezone }}`: the timezone for this session',
    '`{{ session_time_info }}`: the date and time info for this session (formatted)',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    '`{{ enrolled_at }}`: the date when the user was enrolled to this session',
    "`{{ cancellation_cutoff_offset }}`: pre-session cut-off offset, formatted as hours, or '1 day' if exactly 24 hours",
    '`{{ cancellation_cutoff_time_info }}`: pre-session cut-off, formatted as date and time',
    '`{{ now }}`: the current date and time',
  ]),
  event_checkin_reminder_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`: the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_feedback_reminder_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_presenter_reminder_notification: joinTags([
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_presenter_report_notification: joinTags([
    "`{{ user_name }}`: presenter's full name",
    "`{{ first_name }}`: attendee's first name",
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_reminder_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_waitlist_reminder_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  second_event_reminder_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  third_event_reminder_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_invite_notification: joinTags([
    '`{{ user_name }}`: full name of the person sending the invite',
    '`{{ first_name }}`: first name of the person sending the invite',
    "`{{ attendee_name }}`: the attendee's full name",
    "`{{ attendee_first_name }}`: the attendee's first name",
    "`{{ attendee_email }}`: the attendee's email",
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_low_utilization_notification: joinTags([
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  account_password_reset_notification: joinTags([
    '`{{ user_name }}`: full name of the person sending invite',
    '`{{ organization_name }}`: the name of your organization, as set in [System Settings](/a/system-settings/site/#general)',
  ]),
  account_signup_confirmation_notification: joinTags([
    '`{{ user_name }}`: full name of the person sending invite',
    '`{{ organization_name }}`: the name of your organization, as set in [System Settings](/a/system-settings/site/#general)',
  ]),
  event_registration_confirmation_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_waitlist_confirmation_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_waitlist_unenrollment_confirmation_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_unenrollment_confirmation_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ dropped_reason}}`: explanation as to why this user has been unenrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_external_registration_required_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_enrollment_promoted_to_going_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_enrollment_demoted_to_wait_list_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_enrollment_demoted_from_wait_list_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_enrollment_switch_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_created_notification: joinTags([
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  events_recurring_created_notification: joinTags([
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
    '`{{ events_data }}`: all created events, each with the properties defined below:',
    '`{{ events_data.timeslots }}`: timeslots for this event, each with the properties as defined above',
    '`{{ events_data.event_link }}`: URL link to this event',
  ]),
  event_deleted_notification: joinTags([
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_updated_notification: joinTags([
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  timeslots_ics_invite_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  timeslots_ics_cancel_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  timeslots_ics_update_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_type_requested_notification: joinTags([
    '`{{ event_type_name }}`: the name of this event',
    '`{{ event_type_link }}`: URL link to this event',
    '`{{ attendee_name }}`: the name of the user that requested this event type',
    '`{{ attendee_email }}`: the email of the user that requested this event type',
    "`{{ attendee_profile_url }}`: URL link to the user's profile",
    '`{{ message }}`: the message the user left when requesting the event',
    '`{{ quantity }}`: the amount of people who are interested in this event',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  event_no_show_notification: joinTags([
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  event_alert_notification: joinTags([
    "`{{ user_name }}`: attendee's full name",
    '`{{ alert_term }}`: the search term that user set to trigger this alert',
    '`{{ alerts_link }}`: the link to this alert',
    '`{{ events }}`: all matching events for this alert',
    '`{{ event.name }}`: the name of the current event in the list of matching events',
    '`{{ event.link }}`: URL link to this event',
    '`{{ event.starts_at }}`: start time for the current event',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  survey_notification: joinTags([
    "`{{ survey_title }}`: the survey's title",
    "`{{ survey_link }}`: URL link to this event's survey",
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  survey_reminder_notification: joinTags([
    "`{{ survey_title }}`: the survey's title",
    "`{{ survey_link }}`: URL link to this event's survey",
    "`{{ user_name }}`: the attendee's full name",
    "`{{ first_name }}`: the attendee's first name",
    "`{{ user_timezone }}`:the attendee's timezone",
    "`{{ enrollment_type }}`: the type of this enrollment ('In Person' or 'Online')",
    '`{{ is_enrolled }}`: a boolean indicator of whether the attendee has been enrolled',
    '`{{ is_waitlisted }}`: a boolean indicator of whether the attendee has been waitlisted',
    '`{{ waitlist_position }}`: the current waitlist position of this enrollment (e.g. 1, 2, 3, etc)',
    '**DEPRECATED* `{{ attendance_method }}`: the attendance method for this enrollment',
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  content_survey_notification: joinTags([
    "`{{ survey_title }}`: the survey's title",
    '`{{ content_name }}`: the name of this item',
    '`{{ content_link }}`: URL link to this item',
    '`{{ resource_info }}`: all the resources associated with this item',
    "`{{ survey_link }}`: URL link to this item's survey",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  group_members_removal_notification: joinTags([
    '`{{ actor_name }}`: person who performed the action to remove the members',
    "`{{ group_name }}`: group's name",
    '`{{ removed_members }}`: list of people who were removed',
    '`{{ removed_member.name }}`: name of the person who was removed',
  ]),
  group_members_addition_notification: joinTags([
    '`{{ actor_name }}`: person who performed the action to remove the members',
    "`{{ group_name }}`: group's name",
    '`{{ added_members }}`: list of people who were removed',
    '`{{ added_member.name }}`: name of the person who was removed',
  ]),
  group_deletion_notification: joinTags([
    '`{{ actor_name }}`: person who performed the action to remove the members',
    "`{{ group_name }}`: group's name",
  ]),
  group_edition_notification: joinTags([
    '`{{ actor_name }}`: person who performed the action to remove the members',
    "`{{ group_name }}`: group's name",
  ]),
  content_assignment_notification: joinTags([
    '`{{ actor_name }}`: name of the person who performed the action',
    '`{{ actor_email }}`: email of the person who performed the action',
    '`{{ content_name }}`: name of the content that has been assigned',
    '`{{ content_link }}`: link to the content that has been assigned',
    '`{{ content_description }}`: description of the content that has been assigned',
    '`{{ user_name }}`: name of the assigned user',
    '`{{ first_name }}`: first name of the assigned user',
    '`{{ due_date }}`: date when the assignment is due',
    '`{{ assigned_date }}`: the date when this content item was assigned',
    "`{{ assignment_state }}`: the state of the assignment (e.g. 'not_started', 'in_progress', 'completed', 'dropped', 'exempted')",
  ]),
  assignment_completion_notification: joinTags([
    '`{{ actor_name }}`: name of the person who performed the action',
    '`{{ actor_email }}`: email of the person who performed the action',
    '`{{ content_name }}`: name of the content that has been assigned',
    '`{{ content_link }}`: link to the content that has been assigned',
    '`{{ content_description }}`: description of the content that has been assigned',
    '`{{ user_name }}`: name of the assigned user',
    '`{{ first_name }}`: first name of the assigned user',
    '`{{ due_date }}`: date when the assignment is due',
    '`{{ assigned_date }}`: the date when this content item was assigned',
    "`{{ assignment_state }}`: the state of the assignment (e.g. 'not_started', 'in_progress', 'completed', 'dropped', 'exempted')",
    '`{{ is_past_due }}`: a boolean indicator of whether this assignment is past due',
  ]),
  sessions_additional_created_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    '`{{ sessions }}`: all sessions, including {{ session_time_info }},{{ session_location_info }}, {{ host_name }}, {{ host_email }} and{{ attendance_method }} for them',
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ program_link }}`: URL link to this session's program",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ host_profile_url }}`: URL link to the host's profile.",
  ]),
  sessions_recurring_created_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    '`{{ sessions }}`: all sessions, including {{ session_time_info }},{{ session_location_info }}, {{ host_name }}, {{ host_email }} and{{ attendance_method }} for them',
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ program_link }}`: URL link to this session's program",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ host_profile_url }}`: URL link to the host's profile.",
  ]),
  sessions_recurring_enrollment_created_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    '`{{ sessions }}`: all sessions, including {{ session_time_info }},{{ session_location_info }}, {{ host_name }}, {{ host_email }} and{{ attendance_method }} for them',
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ program_link }}`: URL link to this session's program",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    "`{{ attendee_profile_url }}`: URL link to the attendee's profile.",
  ]),
  session_created_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_deleted_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ attendee_name }}`: the name of this session's attendee",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_expired_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_updated_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    '`{{ attendance_method }}`: whether session happens in person or online',
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ attendee_name }}`: the name of this session's attendee",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    "`{{ agenda_link }}`: URL link to session's agenda.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_dropped_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ attendee_name }}`: the name of this session's attendee",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_removed_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ attendee_name }}`: the name of this session's attendee",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    '`{{ actor_name }}`: the name of the person who removed the attendee',
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_canceled_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ attendee_name }}`: the name of this session's attendee",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_demotion_host_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    '`{{ unenrolled_users }}`: list of users who were unenrolled from the session.',
    '`{{ unenrolled_user.name }}`: name of user who was unenrolled from the session in the loop.',
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_demotion_attendee_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ attendee_name }}`: the name of this session's attendee",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_enrolled_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ program_link }}`: URL link to this session's program",
    '`{{ session_time_info }}`: the date and time info for this session',
    "`{{ session_location_info }}`: the location's info for this session",
    '`{{ session_agenda }}`: the agenda the attendee left for this session',
    '`{{ attendance_method }}`: whether this session will occur in person or online',
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ attendee_name }}`: the name of this session's attendee",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ manager_name }}`: the name of this program's manager",
    "`{{ manager_email }}`: the email of this program's manager",
    "`{{ managers_contact }}`: the names and emails of this program's managers",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_email }}`: the email of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    "`{{ agenda_link }}`: URL link to session's agenda.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_feedback_reminder_notification: joinTags([
    "`{{ program_title }}`: the name of this session's program",
    "`{{ attendee_name }}`: the name of this session's attendee",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ feedback_url }}`: URL link to the external feedback app',
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  session_requested_notification: joinTags([
    '`{{ host_name }}`: the name of the host for this session',
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ attendee_name }}`: the name of the user that requested this session',
    '`{{ attendee_email }}`: the email of the user that requested this session',
    "`{{ attendee_profile_url }}`: URL link to the user's profile",
    "`{{ attendee_mentorship_preferences }}`: list of attendee's mentorship preferences.",
    '`{{ message }}`: the message the user left when requesting the session',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    '`{{ create_new_session_url }}`: URL link to the mentorship session create form',
  ]),
  mentorship_requested_notification: joinTags([
    '`{{ user_name }}`: The full name of the user that is receiving the request',
    '`{{ first_name }}`: The first name of the user that is receiving the request',
    '`{{ mentor_full_name }}`: The full name of the user that wants to mentor',
    '`{{ mentor_first_name }}`: The first name of the user that wants to mentor',
    '`{{ mentor_profile_link }}`: The profile link of the user that wants to mentor',
    '`{{ mentees_link }}`: The link to the mentee list page',
    '`{{ user_profile_edit_link }}`: The link to the user edit page',
    '`{{ message }}`: the message the user left when offering to mentor',
  ]),
  mentorship_suggest_mentor_notification: joinTags([
    '`{{ sender_name }}`: The full name of the user who sent the suggestion',
    '`{{ receiver_name }}`: The full name of the user being suggested as mentor (recipient of the email)',
    '`{{ suggested_name }}`: The full name of the user is being suggested as mentee',
    '`{{ suggested_profile_link }}`: The profile link of the user being suggested as mentee',
    '`{{ receiver_mentorship_requests_link }}`: Link to the My Mentorship Messages page of the recipient',
    '`{{ message }}`: The message the user left when sending the suggestion.',
  ]),
  mentorship_suggest_mentee_notification: joinTags([
    '`{{ sender_name }}`: The full name of the user who sent the suggestion',
    '`{{ receiver_name }}`: The full name of the user being suggested as mentee (recipient of the email)',
    '`{{ suggested_name }}`: The full name of the user is being suggested as mentor',
    '`{{ suggested_profile_link }}`: The profile link of the user being suggested as mentor',
    '`{{ receiver_mentorship_requests_link }}`: Link to the My Mentorship Messages page of the recipient',
    '`{{ message }}`: The message the user left when sending the suggestion.',
  ]),
  mentorship_suggest_accepted_notification: joinTags([
    '`{{ sender_name }}`: The full name of the user who sent the suggestion',
    '`{{ receiver_name }}`: The full name of the user being suggested as mentee (recipient of the email)',
    '`{{ suggested_name }}`: The full name of the user is being suggested as mentor',
    '`{{ sender_mentorship_requests_link }}`: Link to the My Mentorship Messages page of the sender',
  ]),
  event_decline_gc_notification: joinTags([
    '`{{ event_name }}`: the name of this event',
    "`{{ event_type_name }}`: the name of this event's event type",
    '`{{ event_link }}`: URL link to this event',
    "`{{ event_type_link }}`: URL link to this event's event type",
    '`{{ event_has_passed }}`: a boolean indicator of whether this event has already taken place',
    '`{{ event_location }}`: event location name (e.g. San Francisco HQ)',
    '`{{ event_description }}`: description for this event (formatted as HTML)',
    '`{{ event_starts_at }}`: start time of the first timeslot of the event starts at (unformatted)',
    '`{{ date_time_information }}`: start time of the first timeslot for this event (formatted)',
    '`{{ timeslots }}`: timeslots for this event, each with the properties defined below:',
    '`{{ timeslot.starts_at }}`: start datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_starts_at }}`: start datetime for this timeslot (unformatted)',
    '`{{ timeslot.ends_at }}`: end datetime for this timeslot (formatted)',
    '`{{ timeslot.raw_ends_at }}`: end datetime for this timeslot (unformatted)',
    '`{{ timeslot.date_range }}`: start-end range for this timeslot (formatted)',
    '`{{ timeslot.timezone }}`: timezone for this timeslot',
    '`{{ timeslot.duration }}`: duration for this timeslot',
    '`{{ timeslot.link }}`: live-stream link for this timeslot',
    '`{{ timeslot.rooms_info }}`: room info for this timeslot',
    '`{{ timeslot.extra_info }}`: extra info for this timeslot',
    '`{{ resources }}`: resources for this event, each with the properties defined below:',
    '`{{ resource.link }}`: the link for this resource',
    '`{{ resource.description }}`: the description for this resource',
    '`{{ presenters }}`: presenters for this event, each with the properties defined below:',
    '`{{ presenter.name }}`: the name of the presenter',
    '`{{ presenter.email }}`: the email of the presenter',
    '`{{ organizer_name }}`: the name of the organizer of this event',
    '`{{ organizer_email }}`: the email of the organizer of this event',
    '`{{ co_organizers }}`: co-organizers for this event, each with the properties defined below:',
    '`{{ co_organizer.name }}`: the name of the co-organizer',
    '`{{ co_organizer.email }}`: the email of the co-organizer',
    "`{{ extra_blurb }}`: the first email notification blurb for this event's event type",
    "`{{ extra_blurb_2 }}`: the second email notification blurb for this event's event type",
    '`{{ event_is_available_online }}`: a boolean indicator of whether this event allows online attendance',
    '`{{ external_link }}`: URL link to secondary registration in an external system',
    '`{{ external_link_description }}`: description of the required secondary external registration',
    '`{{ external_survey_link }}`: URL link to an external survey attached to this event',
    '`{{ enrollment_capacity }}`: total enrollment capacity for this event',
    '`{{ low_utilization_threshold }}`: the configured low-utilization threshold percentage for this event',
    '**DEPRECATED*`{{ timeslot_info }}`: timeslots info for this event: date, time, room (formatted)',
    '**DEPRECATED*`{{ resource_info }}`: resources associated with this event (formatted)',
    '**DEPRECATED*`{{ co_organizer_name }}`: the name of the first co-organizer of this event',
    '**DEPRECATED**` {{ co_organizer_email }}`: the email of the first co-organizer of this event',
    "**DEPRECATED*`{{ live_streamed_link }}`: live-stream links for each of this event's timeslots (formatted)",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
    "`{{ profile_settings_link }}`: URL link to the recipient's profile settings",
  ]),
  session_decline_gc_notification: joinTags([
    '`{{ program_title }}`: the name of the program that contains this session',
    '`{{ program_link }}`: URL link to the program that contains this session',
    '`{{ session_time_info }}`: date and time information for this session',
    "`{{ my_sessions_link }}`: URL link to the user's My Sessions page",
    "`{{ host_name }}`: the name of this session's host",
    "`{{ host_first_name }}`: the first name of this session's host",
    "`{{ host_profile_url }}`: URL link to the host's profile.",
    "`{{ host_mentorship_preferences }}`: list of host's mentorship preferences.",
    '`{{ label_sessions_creator_title_single }}`: the label defined for a person who hosts sessions',
    '`{{ label_sessions_creator_title_plural }}`: the label defined for multiple people who host sessions',
    '`{{ label_sessions_attendee_title_single }}`: the label defined for a person who attends sessions',
    '`{{ label_sessions_attendee_title_plural }}`: the label defined for multiple people who attend sessions',
  ]),
  testimonial_created_notification: joinTags([
    '`{{ testimonial_message }}`: the message left on the testimonial',
    '`{{ receiver_name }}`: the name of the user who received the testimonial',
    '`{{ receiver_email }}`: the email of the user who received the testimonial',
    '`{{ sender_name }}`: the name of the user who left the testimonial',
    '`{{ sender_email }}`: the email of the user who left the testimonial',
    "`{{ profile_url }}`: URL link to the user's profile page",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  testimonial_updated_notification: joinTags([
    '`{{ testimonial_message }}`: the message left on the testimonial',
    '`{{ receiver_name }}`: the name of the user who received the testimonial',
    '`{{ receiver_email }}`: the email of the user who received the testimonial',
    '`{{ sender_name }}`: the name of the user who left the testimonial',
    '`{{ sender_email }}`: the email of the user who left the testimonial',
    "`{{ profile_url }}`: URL link to the user's profile page",
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  csv_exported_notification: joinTags([
    '`{{ user_name }}`: The name of the user who exported the file',
    '`{{ model_name }}`: The name of the exported model',
    '`{{ download_url }}`: The link to download the exported file',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  survey_individual_responses_csv_exported_notification: joinTags([
    '`{{ user_name }}`: The name of the user who exported the file',
    '`{{ relationship_type }}`: The list of relationship objects',
    '`{{ relationship_verbose }}`: Readable representation of the relationship object',
    '`{{ surveys_exports }}`: The list of relationship objects',
    '`{{ surveys_exports.0 }}`: The relationship wrapper object',
    "`{{ surveys_exports.0.download_url }}`: The link to this relationship's responses CSV",
    '`{{ surveys_exports.0.relationship }}`: The relationship object',
    '`{{ surveys_exports.0.relationship.survey }}`: The relationship survey object',
    '`{{ surveys_exports.0.survey.title }}`: The relationship survey title',
    '`{{ surveys_exports.0.survey.description }}`: The relationship survey description',
    '`{{ surveys_exports.0.survey.description }}`: The relationship survey description',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ is_single_file }}`: boolean that checks if the chosen option for responses export was single instead of multiple',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  survey_responses_csv_exported_notification: joinTags([
    '`{{ user_name }}`: The name of the user who exported the file',
    '`{{ relationship_type }}`: The list of relationship objects',
    '`{{ relationship_verbose }}`: Readable representation of the relationship object',
    '`{{ surveys_exports }}`: The list of relationship objects',
    '`{{ surveys_exports.0 }}`: The relationship wrapper object',
    "`{{ surveys_exports.0.download_url }}`: The link to this relationship's responses CSV",
    '`{{ surveys_exports.0.relationship }}`: The relationship object',
    '`{{ surveys_exports.0.relationship.survey }}`: The relationship survey object',
    '`{{ surveys_exports.0.survey.title }}`: The relationship survey title',
    '`{{ surveys_exports.0.survey.description }}`: The relationship survey description',
    '`{{ surveys_exports.0.survey.description }}`: The relationship survey description',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ is_single_file }}`: boolean that checks if the chosen option for responses export was single instead of multiple',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  automatic_rules_simulation_report_notification: joinTags([
    '`{{ user_name }}`: The name of the user who exported the file',
    '`{{ download_url }}`: The link to download the exported file',
    '`{{ site_name }}`: name of this app internally - usually your organization name',
    '`{{ home_link }}`: URL link to the website home',
  ]),
  scorm_import_success_notification: joinTags([
    '`{{ first_name }}`: The first name of the user who exported the file',
    '`{{ draft_url }}`: URL to the course draft edit page',
    '`{{ course_name }}`: Name of the uploaded course',
  ]),
  scorm_import_failure_notification: joinTags([
    '`{{ first_name }}`: The first name of the user who exported the file',
    '`{{ error_message }}`: Error message',
  ]),
  automated_rule_finished_notification: joinTags([
    '`{{ first_name }}`: The first name of the user who ran the rule',
    '`{{ rule_name }}`: The name of the rule',
    '`{{ status }}`: Run status',
    '`{{ started_at }}`: When the rule started',
    '`{{ stopped_at }}`: When the rule finished',
    "`{{ matched_users }}`: The number of people matched by the rule's segment",
    '`{{ affected_users }}`: The number of people the rule actually applied',
  ]),
};

export default tagsCheatsheet;

import React, { useState, forwardRef, useEffect, ElementType } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import colors from '~/services/colors';
import Icon from '~/app/shared/components/Icon';
import { isNil, omit, pick } from 'lodash-es';
import { Button as MuiButton, ButtonProps as MUIButtonProps, SvgIcon } from '@mui/material';

const SubText = styled.div`
  color: ${colors.neutral400};
  font-size: 14px;
  letter-spacing: -0.34px;
  font-weight: normal;
`;

const SIZE_MAPPING = {
  large: `
    height: 40px;
    padding: 0 20px;
    font-size: 18px;
    svg {
      width: 18px;
    }
  `,
  medium: `
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
    svg {
      width: 14px;
    }
  `,
  small: `
    height: 24px;
    padding: 0 8px;
    font-size: 12px;
    svg {
      width: 12px;
    }
  `,
};

export type ButtonWrapperProps =
  | React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  | ({ url: string } & Pick<
      React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
      'className' | 'target' | 'aria-label'
    >)
  | ({ route: string } & Pick<
      React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
      'className' | 'target' | 'aria-label'
    >);

export const ButtonWrapper = (props: ButtonWrapperProps) => {
  const { url, route, disabled } = props as any;

  if (!url && !route) {
    const buttonProps = omit(
      props as React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      ['fullWidth', 'noBorder', 'multiColumn']
    );
    // eslint-disable-next-line react/button-has-type
    return <button {...buttonProps} />;
  }
  const anchorProps = pick(
    props as React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    ['children', 'className', 'alt', 'target', 'aria-label', 'data-cy']
  );

  if (url) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={disabled ? undefined : url} {...anchorProps} />;
  }

  if (route) {
    // The ideal would be to create a better way to manage this button as a whole or
    // perhaps replace it with a simplified version or just start using the standard
    // version of the MUI button with custom themes.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Link {...(!disabled && { to: route })} {...anchorProps} />;
  }

  return null;
};

const getJustifyContentValue = ({
  multiColumn,
  alignLeft,
}: {
  multiColumn?: boolean;
  alignLeft?: boolean;
}) => {
  if (alignLeft) {
    return 'flex-start';
  }
  if (multiColumn) {
    return 'space-between';
  }
  return 'center';
};

export interface BaseButtonProps {
  size: keyof typeof SIZE_MAPPING;
  noBorder?: boolean;
  width?: string;
  fullWidth?: boolean;
  multiColumn?: boolean;
  alignLeft?: boolean;
}

export const baseButtonStyle = css<BaseButtonProps>`
  display: ${({ noBorder }) => (noBorder ? 'inline-flex' : 'flex')};
  align-items: center;
  background-color: transparent;
  line-height: 1;
  font-weight: bold;
  border-radius: 4px;
  justify-content: ${(props) => getJustifyContentValue(props)};
  ${({ size }) => SIZE_MAPPING[size]};
  ${({ width }) => width && `width: ${width}`};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};
  cursor: pointer;
`;

const ButtonText = styled.div<{ size: 'large' | 'normal' }>`
  display: flex;
  align-items: center;
  gap: ${({ size }) => (size === 'large' ? '8px' : '4px')};
`;

export interface ButtonContentProps {
  size: 'large' | 'normal';
  icon: string;
  iconPlacement: 'left' | 'right';
  subText: any;
}

export const ButtonContent = ({
  children,
  size,
  icon,
  iconPlacement,
  subText,
}: React.PropsWithChildren<ButtonContentProps>) => {
  return (
    <>
      <ButtonText size={size}>
        {iconPlacement === 'left' && icon && <Icon name={icon} noTransition />}
        {children}
        {iconPlacement === 'right' && icon && <Icon name={icon} noTransition />}
      </ButtonText>
      {subText && <SubText>{subText}</SubText>}
    </>
  );
};

export interface ButtonProps extends MUIButtonProps {
  route?: string;
  component?: ElementType<any>;
  target?: string;
  to?: string;
}

const defaultLoadingTimeInMilliseconds = 500;

const Button = forwardRef<any, ButtonProps>(function Button(props, ref) {
  const {
    route,
    disabled,
    startIcon,
    endIcon,
    onClick,
    type,
    component = 'button',
    ...restButtonProps
  } = props;

  const [loading, setLoading] = useState(false);
  const loadingTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };
  }, []);

  const handleClick = (event) => {
    setLoading(true);

    loadingTimeoutRef.current = setTimeout(() => {
      setLoading(false);
    }, defaultLoadingTimeInMilliseconds);

    onClick?.(event);
  };

  // TODO: We can remove this once we've migrated everything to use material UI
  // This wrapping will be done inside the Icon component (or we can use MUI own icons instead)
  const iconProps: Record<string, React.ReactNode> = {};
  if (startIcon) {
    iconProps.startIcon = <SvgIcon>{startIcon}</SvgIcon>;
  }
  if (endIcon) {
    iconProps.endIcon = <SvgIcon>{endIcon}</SvgIcon>;
  }

  const routingProps: Record<string, any> = {};
  if (route && !disabled) {
    routingProps.to = route;
    routingProps.component = Link;
    // Ensure that MUI doesn't create the anchor with role="button"
    routingProps.role = 'link';
  }

  const displayDisabled = disabled || loading;

  return (
    <MuiButton
      ref={ref}
      disabled={displayDisabled}
      component={component}
      type={isNil(type) ? 'button' : type}
      onClick={isNil(onClick) ? null : handleClick}
      {...restButtonProps}
      {...routingProps}
      {...iconProps}
    />
  );
});

export default Button;

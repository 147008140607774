import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { queries } from '~/queries';
import { useCreateArticle } from '~/features/articles/api/mutations';

import { useParentSettings } from '~/app/settings/hooks';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useCreateContentFromChannelParams } from '~/features/channels/hooks/useCreateContentFromChannelParams';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import {
  useOriginRoute,
  useQueryParams,
  useSetDetailedObject,
  useLabels,
  useCurrentUser,
} from '~/app/shared/hooks';
import { get, isEmpty, toLower } from 'lodash-es';

import ArticleFormPage from './ArticleFormPage';

const ArticleCreate = () => {
  const currentUser = useCurrentUser();

  const parentSettingsFlat = useParentSettings('article/form', 'flat');
  const { label_article: labelArticle, label_article_plural: labelArticlePlural } = useLabels();

  const { cloned: duplicatedArticleId } = useQueryParams();
  const isDuplicating = Boolean(duplicatedArticleId);

  const {
    isFromChannel,
    isLoading: isLoadingChannelData,
    channelId,
  } = useCreateContentFromChannelParams();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.articles
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);
  const onSubmit = useCreateArticle().mutateAsync;

  const { data: clonedArticle, isLoading } = useQuery({
    ...queries.articles.detail_by_public_id(duplicatedArticleId || '', 'detail'),
    retry: 0,
    enabled: isDuplicating,
  });

  useSetDetailedObject({ id: null }, 'articles', []);

  if ((isDuplicating && isLoading) || (isFromChannel && isLoadingChannelData)) {
    return <Loading />;
  }

  const initialValuesParams = {};

  if (isDuplicating) {
    initialValuesParams.clonedArticle = clonedArticle;
  }

  if (!isDuplicating && !isEmpty(parentSettingsFlat)) {
    initialValuesParams.defaultSettings = parentSettingsFlat;
  }

  if (isFromChannel) {
    initialValuesParams.defaultChannelId = channelId;
  } else {
    initialValuesParams.defaultChannelId = get(currentUser, 'default_channel_id');
  }

  return (
    <ArticleFormPage
      pageTitle={`${isDuplicating ? 'Duplicate' : 'Create'} ${labelArticle}`}
      topBarActionName={`${isDuplicating ? 'Duplicating' : 'Creating'} ${labelArticle}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelArticlePlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelArticle)}`,
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default ArticleCreate;

import { useContentItemCardData } from '~/common/hooks/useContentItemCardData';
import { Event } from '~/app/event/interfaces';
import { ContentItemCardRoot } from '~/common/components/ContentItemCard/ContentItemCardRoot';
import { ContentItemCardCover } from '~/common/components/ContentItemCard/ContentItemCardCover';
import { DefaultContentItemLearningType } from '~/features/contentitems/components/DefaultContentItemCard/DefaultContentItemLearningType';
import { ContentItemCardBody } from '~/common/components/ContentItemCard/ContentItemCardBody';
import { ContentItemCardHeader } from '~/common/components/ContentItemCard/ContentItemCardHeader';
import { useChannelToggle, useCurrentUser } from '~/app/shared/hooks';
import { ContentItemCardOwners } from '~/common/components/ContentItemCard/ContentItemCardOwners';
import { ContentItemCardDescription } from '~/common/components/ContentItemCard/ContentItemCardDescription';
import { Box, Chip, Divider, Tooltip, Typography } from '@mui/material';
import { get } from 'lodash-es';
import Icon from '~/app/shared/components/Icon';
import { ENROLLMENT_STATUS_UNANSWERED } from '~/app/event-shared/constants';
import { getUserEnrollmentStatus } from '~/app/event-shared/services';
import colors from '~/services/colors';
import { displayDate } from '~/services/datetime';
import { ContentItemCardTags } from '~/common/components/ContentItemCard/ContentItemCardTags';
import { ContentItemCardFooterArea } from '~/common/components/ContentItemCard/ContentItemCardFooterArea';
import { ContentItemCardRating } from '~/common/components/ContentItemCard/ContentItemCardRating';

type EventLocationIconProps = {
  enrollmentStatus: string;
};

function EventLocationIcon(props: EventLocationIconProps) {
  const { enrollmentStatus } = props;

  const {
    userIsEnrolledLocal,
    userIsOnLocalWaitlist,
    userIsEnrolledOnline,
    userIsOnOnlineWaitlist,
  } = getUserEnrollmentStatus(enrollmentStatus);

  const maybeGoingLocal = userIsEnrolledLocal || userIsOnLocalWaitlist;
  const maybeGoingOnline = userIsEnrolledOnline || userIsOnOnlineWaitlist;
  const maybeGoing = maybeGoingLocal || maybeGoingOnline;

  if (!maybeGoing) {
    return <Icon name="globe" height={14} width={14} />;
  }

  if (maybeGoingLocal) {
    return <Icon name="location" height={14} width={14} />;
  }

  return <Icon name="online" height={14} width={14} />;
}

type EventDetailItemProps = {
  icon: React.ReactNode;
  label: string;
  rightIcon?: React.ReactNode;
};

function EventDetailItem(props: EventDetailItemProps) {
  const { icon, label, rightIcon } = props;

  return (
    <Box display="flex" alignItems="center" gap="6px">
      <Box display="flex" flexShrink={0}>
        {icon}
      </Box>
      <Typography variant="body2" component="span" noWrap>
        {label}
      </Typography>
      {rightIcon}
    </Box>
  );
}

function EventOutsideLearningHoursBadge() {
  return (
    <Tooltip title="Outside learning hours">
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor: colors.emphasis200,
          borderRadius: '16px',
          verticalAlign: 'middle',
        }}
      >
        <Icon name="moon" height={24} width={24} color={colors.emphasis700} />
      </Box>
    </Tooltip>
  );
}

type EventContentItemCardDetailsListProps = {
  event: Event;
};

function EventContentItemCardDetailsList(props: EventContentItemCardDetailsListProps) {
  const { event } = props;

  const currentUser = useCurrentUser();

  const locationTimezone = event.timezone;
  const userTimezone = currentUser.timezone;
  const eventIsOnline = event.is_online;

  const enrollmentStatus = get(event, 'enrollment.status', ENROLLMENT_STATUS_UNANSWERED);

  const firstTimeslot = get(event, 'timeslots[0]');

  const eventDate = displayDate(
    firstTimeslot?.starts_at_tz_aware,
    locationTimezone,
    userTimezone,
    eventIsOnline,
    {
      includeWeekDay: true,
      includeTimezone: true,
    }
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="2px"
      sx={{
        color: colors.neutral600,
      }}
    >
      <EventDetailItem
        icon={<EventLocationIcon enrollmentStatus={enrollmentStatus} />}
        label={event.location_name ?? ''}
      />
      <EventDetailItem
        icon={<Icon name="calendar-clock" color={colors.neutral600} height={14} width={14} />}
        label={eventDate}
        rightIcon={
          firstTimeslot?.is_inside_learning_hours ? null : <EventOutsideLearningHoursBadge />
        }
      />
    </Box>
  );
}

function parseEventRemainingSpots(event: Event) {
  const inPersonSpots = event.remaining_capacity?.going;
  const isInPersonUnlimited = event.is_local && event.enrollment_limit === 0;

  const onlineSpots = event.remaining_capacity?.going_online;
  const isOnlineUnlimited = event.is_online && event.online_enrollment_limit === 0;

  const hasUnlimitedCapacity = isInPersonUnlimited || isOnlineUnlimited;
  const totalRemainingSpots = (inPersonSpots ?? 0) + (onlineSpots ?? 0);

  return {
    inPerson: inPersonSpots,
    online: onlineSpots,
    total: totalRemainingSpots,
    isUnlimited: hasUnlimitedCapacity,
  };
}

type EventRemainingSpotsBadgeProps = {
  spots?: number;
  isUnlimited?: boolean;
};

function EventRemainingSpotsBadge(props: EventRemainingSpotsBadgeProps) {
  const { spots = 0, isUnlimited = false } = props;

  const spotsLabel = isUnlimited
    ? 'Unlimited spots'
    : spots > 1
      ? `${spots} spots left`
      : '1 spot left';

  return (
    <Chip
      label={spotsLabel}
      size="small"
      sx={{
        backgroundColor: colors.success200,
        color: colors.success800,
      }}
    />
  );
}

type EventContentItemCardProps = {
  event: Event;
};

export function EventContentItemCard(props: EventContentItemCardProps) {
  const { event } = props;

  const { learningType, detailsRouteUrl } = useContentItemCardData(event);
  const toggleChannels = useChannelToggle();

  const assignmentsCount = event.total_assignments ?? event.assignments_count ?? 0;

  const rating =
    event.avg_feedback_rating ?? event.average_feedback_rating ?? event.feedback_rating;
  const formattedRating = rating ? Math.round(rating * 10) / 10 : null;

  const eventSpots = parseEventRemainingSpots(event);
  const canDisplaySpots = eventSpots.total > 0 || eventSpots.isUnlimited;

  return (
    <ContentItemCardRoot>
      <ContentItemCardCover contentItem={event} route={detailsRouteUrl} />
      <DefaultContentItemLearningType learningType={learningType} />

      <ContentItemCardBody>
        <ContentItemCardHeader
          title={event.name}
          route={detailsRouteUrl}
          channel={toggleChannels ? event.channel : undefined}
        />
        <ContentItemCardOwners
          facilitators={event.facilitators ?? []}
          learningType={learningType}
        />
        <EventContentItemCardDetailsList event={event} />
        <ContentItemCardDescription description={event.content_body} />
        <ContentItemCardTags contentItem={event} />
      </ContentItemCardBody>

      <Divider />
      <ContentItemCardFooterArea>
        <Box display="flex" alignItems="center" gap="4px">
          {canDisplaySpots && (
            <EventRemainingSpotsBadge
              spots={eventSpots.total}
              isUnlimited={eventSpots.isUnlimited}
            />
          )}

          <Typography variant="body2" component="span">
            {assignmentsCount} engaged
          </Typography>
        </Box>

        {formattedRating && <ContentItemCardRating rating={formattedRating} />}
      </ContentItemCardFooterArea>
    </ContentItemCardRoot>
  );
}

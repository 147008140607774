/* eslint-disable sonarjs/no-duplicate-string */
import moment from 'moment';
import { delay, takeEvery } from 'redux-saga/effects';

import * as schema from '~/app/entities/schema';
import {
  debounceByKey,
  storeEntitiesSaga,
  updateEntitySaga,
  getRetrieveSaga,
  getUpdateSaga,
  getPutSaga,
  getUpdateAndRetrieveSaga,
  getFilterSaga,
  getNestedFilterSaga,
  getCreateSaga,
  getDeleteSaga,
  getLoadMoreSaga,
  getUpdateSagaForEventEnrollment,
  getUpdateSagaForUserEnrollment,
  getExportSaga,
  getUpdateSagaForCustomerSettings,
  getUpdateSideEffectSaga,
  putSaga,
  patchSaga,
} from '~/app/entities/utils';
import { API_DATE_FORMAT } from '~/app/shared/constants';
import { uniq, map, concat, isNil, clone, omit, get } from 'lodash-es';

import actions from './actions';

// Current User
const currentUserFilters = getRetrieveSaga('api_users:filter-status');

// User sagas
const userRetrieve = getRetrieveSaga('api_users:user', schema.userProfileSchema);
const userUpdate = getUpdateSaga('api_users:user', schema.userSchema);
const userCreate = getCreateSaga('api_users:users', [schema.userSchema]);
const userFilter = getFilterSaga('api_users:users', schema.userSchema);
const userListRQL = getFilterSaga('api_users:users_rql', schema.userSchema);
const userRetrieveFilters = getRetrieveSaga('api_users:users_filters');
const userRetrieveListLoadMore = getLoadMoreSaga(schema.userSchema);
const userRemove = getDeleteSaga('api_users:delete');
const userRetrieveImpactedList = getFilterSaga('api_users:impacted_users', schema.userSchema);
const userRetrieveFacilitatorFeedback = getFilterSaga('api_users:impacted_users_feedback');

const consumedContentRetrieve = getFilterSaga('api_users:consumed_content');
const overallImpactRetrieve = getFilterSaga('api_users:overall_impact');
const userRecentActivityList = getFilterSaga('api_activities:user_recent_activity');

// Events sagas
const eventRetrieve = getRetrieveSaga('api_events:detail', schema.eventSchema, 'public_id');
const eventList = getFilterSaga('api_events:filter', schema.eventSchema);
const eventListRQL = getFilterSaga('api_events:events_rql', schema.eventSchema);
const eventRetrieveFilters = getRetrieveSaga('api_events:events_rql_filters');
const eventListLoadMore = getLoadMoreSaga(schema.eventSchema);
const eventCreate = getCreateSaga('api_events:create', schema.eventSchema);
const eventUpdate = getUpdateAndRetrieveSaga(
  'api_events:edit',
  'api_events:detail',
  schema.eventSchema,
  'public_id'
);
const eventBulkCopy = getCreateSaga('api_events:bulk_copy');
const eventRemove = getDeleteSaga('api_events:delete', schema.eventSchema, 'public_id');
const eventFeedbackList = getRetrieveSaga('api_events:feedbacks', null, 'public_id');

const eventEnrollmentUpdate = getUpdateSagaForEventEnrollment(
  'api_events:user_enrollment_retrieve',
  schema.enrollmentSchema
);
const eventAttendeeEmailList = getRetrieveSaga(
  'api_events:attendees_emails_list',
  null,
  'public_id'
);

function* updateEnrollment(action) {
  const updateRequest = getUpdateSaga(
    'api_events:enrollment_retrieve',
    schema.enrollmentSchema,
    'public_id'
  );
  const response = yield updateRequest(action);

  const { id } = action.payload;
  yield updateEntitySaga(id, schema.eventSchema, { enrollment: response.data.id });
}

// Enrollments sagas
const enrollmentDeprecatedList = getFilterSaga('api_enrollments:filter', schema.enrollmentSchema);
const enrollmentList = getFilterSaga('enrollments_api:list_create', schema.enrollmentSchema);
const enrollmentRetrieveFilters = getRetrieveSaga('api_enrollments:enrollments_rql_filters');
const enrollmentListLoadMore = getLoadMoreSaga(schema.enrollmentSchema);
const enrollmentCreate = getUpdateSideEffectSaga(
  'enrollments_api:list_create',
  schema.enrollmentSchema,
  ['enrollment', 'user_enrollment']
);
// enrollment retrieve update
const enrollmentRetrieve = getRetrieveSaga(
  'enrollments_api:retrieve_update_destroy',
  schema.enrollmentSchema
);
const enrollmentUpdateOrder = getUpdateSaga('enrollments_api:order', null);
const enrollmentCheckin = getUpdateSaga('enrollments_api:checkin', null);
const enrollmentUndoCheckin = getDeleteSaga('enrollments_api:checkin', null);
const enrollmentPromote = getUpdateSaga('enrollments_api:promotion', null);
const enrollmentDemote = getUpdateSaga('enrollments_api:waitlisting', null);
const enrollmentDrop = getUpdateSaga('enrollments_api:drop', null);
// bulk enrollment
const enrollmentBulkCreate = getCreateSaga('enrollments_api:bulk_create', null, undefined, true);
const enrollmentBulkPromote = getCreateSaga(
  'enrollments_api:bulk_promotion',
  null,
  undefined,
  true,
  patchSaga
);
const enrollmentBulkDemote = getCreateSaga(
  'enrollments_api:bulk_waitlisting',
  null,
  undefined,
  true,
  patchSaga
);
const enrollmentBulkCheckin = getCreateSaga(
  'enrollments_api:bulk_checkin',
  null,
  undefined,
  true,
  patchSaga
);
const enrollmentBulkUndoCheckin = getCreateSaga(
  'enrollments_api:bulk_checkin',
  null,
  undefined,
  true,
  putSaga
);
const enrollmentBulkDrop = getCreateSaga(
  'enrollments_api:bulk_drop',
  null,
  undefined,
  true,
  patchSaga
);
const enrollmentListEmails = getFilterSaga('enrollments_api:list_emails', null, 'id', false);

// Event Type sagas
const eventTypeList = getFilterSaga('api_eventtypes:event_types', schema.eventTypeSchema);
const eventTypeListRQL = getFilterSaga('api_eventtypes:event_types_rql', schema.eventTypeSchema);
const eventTypeRetrieveFilters = getRetrieveSaga('api_eventtypes:event_types_rql_filters');
const eventTypeListLoadMore = getLoadMoreSaga(schema.eventTypeSchema);
const eventTypeRetrieve = getRetrieveSaga(
  'api_eventtypes:event_type',
  schema.eventTypeSchema,
  'public_id'
);
const eventTypeCreate = getCreateSaga('api_eventtypes:event_types', [schema.eventTypeSchema]);
const eventTypeUpdate = getUpdateSaga(
  'api_eventtypes:event_type',
  schema.eventTypeSchema,
  'public_id'
);
const eventTypeDelete = getDeleteSaga(
  'api_eventtypes:event_type',
  schema.eventTypeSchema,
  'public_id'
);
const eventTypeForceDelete = getDeleteSaga(
  'api_eventtypes:force_delete',
  schema.eventTypeSchema,
  'public_id'
);
const eventTypeRequestEvent = getCreateSaga('api_eventtypes:event_type_requests');
const eventTypeFilterRequestedEvents = getFilterSaga(
  'api_eventtypes:event_type_requests',
  schema.eventTypeRequestSchema
);
const eventTypeFilterRequestedEventsLoadMore = getLoadMoreSaga(schema.eventTypeRequestSchema);

// ContentItem sagas
const contentList = getFilterSaga('api_content_items:filter', schema.contentSchema);
const contentListRQL = getFilterSaga('api_content_items:list_rql', schema.contentSchema);
const contentListLoadMore = getLoadMoreSaga(schema.contentSchema);
const contentRetrieveFilters = getRetrieveSaga('api_content_items:rql_filters');
const contentFeedbackList = getFilterSaga('api_content_items:feedback_filter');

const contentItemBulkAssignmentUpdate = getCreateSaga('api_content_items:bulk_assignment');
const contentAssignmentRetrieveList = getFilterSaga(
  'api_content_items:assignment_filter',
  schema.assignmentSchema
);
const contentAssignmentRetrieveListLoadMore = getLoadMoreSaga(schema.assignmentSchema);
const contentAssignmentsUsersForEmails = getFilterSaga(
  'api_content_items:assignment_filter',
  schema.assignmentEmailsSchema
);
const contentAssignmentRQLFilters = getRetrieveSaga('api_content_items:assignments_rql_filters');
const contentAssignmentRQL = getFilterSaga(
  'api_content_items:assignments_rql',
  schema.assignmentSchema
);
const teamContentAssignmentRQLFilters = getRetrieveSaga(
  'api_content_items:team_assignments_rql_filters'
);
const teamContentAssignmentRQL = getFilterSaga(
  'api_content_items:team_assignments_rql',
  schema.assignmentSchema
);

function* contentAssignmentUpdate(action) {
  const { id } = action.payload;
  const { contentSchema } = action.payload.body;
  action.payload.body = omit(action.payload.body, ['contentSchema']);

  const updateRequest = getUpdateSaga(
    'api_content_items:assignment_retrieve',
    schema.assignmentSchema
  );
  const response = yield updateRequest(action);

  if (!response.data.error) {
    yield updateEntitySaga(id, contentSchema, { assignment: response.data?.id ?? null });
  }
}

const contentUserAssignmentUpdate = getUpdateSagaForUserEnrollment(
  'api_content_items:user_assignment_retrieve',
  schema.assignmentSchema
);

// ContentItem 2.0 sagas
const contentRetrieve = getRetrieveSaga(
  'content_items_api:retrieve',
  schema.contentSchema,
  'public_id'
);

// assignments 2.0 sagas
const assignmentList = getFilterSaga('assignments_api:list_create', schema.assignmentSchema);
const assignmentListLoadMore = getLoadMoreSaga(schema.assignmentSchema);
const assignmentListEmails = getFilterSaga('assignments_api:list_emails', null, 'id', false);
const assignmentCreate = getUpdateSideEffectSaga(
  'assignments_api:list_create',
  schema.assignmentSchema,
  ['assignment', 'user_assignment']
);
const assignmentRetrieve = getRetrieveSaga(
  'assignments_api:retrieve_update_destroy',
  schema.assignmentSchema
);
const assignmentUpdate = getUpdateSaga(
  'assignments_api:retrieve_update_destroy',
  schema.assignmentSchema
);
const assignmentRemove = getDeleteSaga('assignments_api:retrieve_update_destroy', null);
const assignmentProgressRetrieve = getRetrieveSaga(
  'assignments_api:progress',
  schema.assignmentSchema
);
const assignmentProgressSubmit = getPutSaga('assignments_api:progress', schema.assignmentSchema);
const assignmentProgressRemove = getDeleteSaga('assignments_api:progress', null);
const assignmentCompletionRetrieve = getRetrieveSaga(
  'assignments_api:completion',
  schema.assignmentSchema
);
const assignmentCompletionSubmit = getPutSaga(
  'assignments_api:completion',
  schema.assignmentSchema
);
const assignmentCompletionRemove = getDeleteSaga('assignments_api:completion', null);
const assignmentDropRetrieve = getRetrieveSaga('assignments_api:drop', schema.assignmentSchema);
const assignmentDropSubmit = getUpdateSaga('assignments_api:drop', schema.assignmentSchema);
const assignmentExemptionRetrieve = getRetrieveSaga('assignments_api:exemption');
const assignmentExemptionSubmit = getPutSaga(
  'assignments_api:exemption',
  schema.assignmentExemptionSchema
);
const assignmentExemptionRemove = getDeleteSaga('assignments_api:exemption', null);
const assignmentRatingRetrieve = getRetrieveSaga('assignments_api:rating', schema.assignmentSchema);
const assignmentRatingSubmit = getPutSaga('assignments_api:rating', schema.assignmentSchema);
const assignmentCompletionAcknowledgementSubmit = getPutSaga(
  'assignments_api:completion_acknowledgement',
  schema.assignmentSchema
);
const assignmentBulk = getCreateSaga('assignments_api:bulk', null, undefined, true);
const assignmentBulkUpdate = getCreateSaga('assignments_api:bulk', null, undefined, true, putSaga);
const assignmentBulkCompletion = getCreateSaga(
  'assignments_api:bulk_completion',
  null,
  undefined,
  true,
  patchSaga
);
const assignmentBulkUndoCompletion = getCreateSaga(
  'assignments_api:bulk_completion',
  null,
  undefined,
  true,
  putSaga
);
const assignmentBulkExemption = getCreateSaga(
  'assignments_api:bulk_exemption',
  null,
  undefined,
  true,
  patchSaga
);
const assignmentBulkUndoExemption = getCreateSaga(
  'assignments_api:bulk_exemption',
  null,
  undefined,
  true,
  putSaga
);
const assignmentBulkDrop = getCreateSaga(
  'assignments_api:bulk_drop',
  null,
  undefined,
  true,
  patchSaga
);
const assignmentBulkExpire = getCreateSaga(
  'assignments_api:bulk_expire',
  null,
  undefined,
  true,
  patchSaga
);

const trackRetrieveDetails = getRetrieveSaga(
  'tracks_api:retrieve_update_destroy',
  schema.trackSchema
);
const trackCreate = getCreateSaga('tracks_api:list_create', schema.trackSchema);
const trackUpdate = getUpdateSaga('tracks_api:retrieve_update_destroy', schema.trackSchema);
const oldTrackList = getFilterSaga('api_tracks:filter', schema.trackSchema);
const trackRetrieveBreadcrumbs = getRetrieveSaga('api_tracks:breadcrumbs', null, 'public_id');
const trackListLoadMore = getLoadMoreSaga(schema.trackSchema);
const trackArchive = getCreateSaga('tracks_api:archival', schema.trackSchema);
const trackRestore = getDeleteSaga('tracks_api:archival', schema.trackSchema);
const trackRemove = getDeleteSaga('tracks_api:retrieve_update_destroy', null);
const trackForceRemove = getDeleteSaga('tracks_api:force_delete', null);

const descendantTrackItems = getFilterSaga(
  'api_tracks:descendant_track_items',
  schema.trackItemSchema,
  'public_id'
);

// Scheduled Tracks sagas
const scheduledTrackRetrieveDetails = getRetrieveSaga(
  'scheduled_tracks_api:retrieve_update_destroy',
  schema.scheduledTrackSchema
);
const scheduledTrackCreate = getCreateSaga(
  'scheduled_tracks_api:list_create',
  schema.scheduledTrackSchema
);
const scheduledTrackUpdate = getUpdateSaga(
  'scheduled_tracks_api:retrieve_update_destroy',
  schema.scheduledTrackSchema
);
const oldScheduledTrackRemove = getDeleteSaga('api_scheduled_tracks:delete', null, 'public_id');
const oldScheduledTrackList = getFilterSaga(
  'api_scheduled_tracks:filter',
  schema.scheduledTrackSchema
);
const scheduledTrackListLoadMore = getLoadMoreSaga(schema.scheduledTrackSchema);
const oldScheduledTrackRetrieveFilters = getRetrieveSaga('api_scheduled_tracks:rql_filters');
const oldScheduledTrackEventsList = getFilterSaga(
  'api_scheduled_tracks:events',
  schema.eventSchema,
  'public_id'
);
const scheduledTrackArchive = getCreateSaga(
  'scheduled_tracks_api:archival',
  schema.scheduledTrackSchema
);
const scheduledTrackRestore = getDeleteSaga(
  'scheduled_tracks_api:archival',
  schema.scheduledTrackSchema
);

// Track Item sagas
const trackItemList = getFilterSaga('api_tracks:track_items', schema.trackItemSchema);
const trackItemListLoadMore = getLoadMoreSaga(schema.trackItemSchema);
const trackItemArchive = getCreateSaga('tracks_api:track_item_archival');
const trackItemUnarchive = getDeleteSaga('tracks_api:track_item_archival');

// Assessments sagas

const assessmentRetrieveDetails = getRetrieveSaga(
  'assessments_api:retrieve_update_destroy',
  schema.assessmentSchema
);
const assessmentCreate = getCreateSaga('assessments_api:list_create', schema.assessmentSchema);
const assessmentUpdate = getUpdateSaga(
  'assessments_api:retrieve_update_destroy',
  schema.assessmentSchema
);
const assessmentArchive = getCreateSaga('assessments_api:archival', schema.assessmentSchema);
const assessmentRestore = getDeleteSaga('assessments_api:archival', schema.assessmentSchema);
const assessmentRemove = getDeleteSaga('assessments_api:retrieve_update_destroy', null);
const assessmentForceRemove = getDeleteSaga('assessments_api:force_delete', null);
const assessmentList = getFilterSaga('assessments_api:list_create', schema.assessmentSchema);
const assessmentListLoadMore = getLoadMoreSaga(schema.assessmentSchema);

// Question sagas

const questionRetrieveDetails = getRetrieveSaga(
  'questions_api:retrieve_update_destroy',
  schema.assessmentSchema
);
const questionCreate = getCreateSaga('questions_api:list_create', schema.assessmentSchema);
const questionUpdate = getUpdateSaga(
  'questions_api:retrieve_update_destroy',
  schema.assessmentSchema
);
const questionRemove = getDeleteSaga('questions_api:retrieve_update_destroy', null);
const questionList = getFilterSaga('questions_api:list_create', schema.assessmentSchema);
const questionListLoadMore = getLoadMoreSaga(schema.assessmentSchema);

const multipleChoiceQuestionResponsesCompletion = getUpdateSaga(
  'questions_api:responses_completion',
  schema.assignmentSchema
);

const textQuestionResponsesCompletion = getUpdateSaga(
  'questions_api:responses_completion',
  schema.assignmentSchema
);

const multipleChoiceQuestionOptionsList = getFilterSaga(
  'multiple_choice_questions_api:options_list',
  schema.multipleChoiceOptionSchema
);

// Link sagas

const linkedContentRetrieveDetails = getRetrieveSaga(
  'linkedcontents_api:retrieve_update_destroy',
  schema.linkedContentSchema
);
const linkedContentCreate = getCreateSaga(
  'linkedcontents_api:list_create',
  schema.linkedContentSchema
);
const linkedContentUpdate = getUpdateSaga(
  'linkedcontents_api:retrieve_update_destroy',
  schema.linkedContentSchema
);
const linkedContentRemove = getDeleteSaga('linkedcontents_api:retrieve_update_destroy', null);
const linkedContentForceRemove = getDeleteSaga('linkedcontents_api:force_delete', null);
const linkedContentList = getFilterSaga(
  'linkedcontents_api:list_create',
  schema.linkedContentSchema
);
const linkedContentListLoadMore = getLoadMoreSaga(schema.linkedContentSchema);
const linkedContentArchive = getCreateSaga(
  'linkedcontents_api:archival',
  schema.linkedContentSchema
);
const linkedContentRestore = getDeleteSaga(
  'linkedcontents_api:archival',
  schema.linkedContentSchema
);

// Articles sagas
const articleRemove = getDeleteSaga('api_articles:delete', schema.articleSchema, 'public_id');
const articleForceRemove = getDeleteSaga(
  'api_articles:force_delete',
  schema.articleSchema,
  'public_id'
);

// Tasks sagas
const taskList = getFilterSaga('api_tasks:task-list', schema.taskSchema);
const taskCreate = getCreateSaga('api_tasks:task-list', schema.taskSchema);
const taskRetrieveDetails = getRetrieveSaga(
  'api_tasks:task-detail',
  schema.taskSchema,
  'public_id'
);
const taskUpdate = getUpdateSaga('api_tasks:task-detail', schema.taskSchema, 'public_id');
const taskRemove = getDeleteSaga('api_tasks:task-delete', schema.taskSchema, 'public_id');
const taskForceRemove = getDeleteSaga('api_tasks:force_delete', schema.taskSchema, 'public_id');
const taskConvert = getCreateSaga('api_tasks:task-convert', null, 'public_id', true);

// Location sagas

const locationRetrieveList = getFilterSaga('api_events:locations', schema.locationSchema);

// Survey sagas

const surveyCreate = getCreateSaga('api_surveys:surveys', schema.surveySchema);
const surveyUpdate = getUpdateSaga('api_surveys:survey_details', schema.surveySchema);
const surveyRetrieveList = getFilterSaga('api_surveys:surveys', schema.surveySchema);
const surveyRetrieveDetails = getRetrieveSaga('api_surveys:survey_details', schema.surveySchema);
const surveyRelationshipUpdate = getUpdateSaga(
  'api_surveys:survey_relationship',
  schema.surveySchema
);
const surveyRelationshipRemove = getDeleteSaga('api_surveys:survey_relationship');
const surveyRelationshipRetrieveList = getFilterSaga(
  'api_surveys:survey_relationships',
  schema.surveyRelationshipSchema
);
const surveyRelationshipRetrieveDetails = getRetrieveSaga(
  'api_surveys:survey_relationship',
  schema.surveyRelationshipSchema
);
const surveyRelationshipCreate = getCreateSaga('api_surveys:survey_relationships');
const surveyRelationshipExportMultiple = getExportSaga(
  'api_surveys:export_responses_csv_by_relationship'
);
const surveyRelationshipInsightsRetrieveDetails = getRetrieveSaga(
  'api_surveys:survey_relationship_insights',
  schema.surveyRelationshipSchema
);

const surveyPreview = getRetrieveSaga('api_surveys:survey_preview', schema.surveyPreviewSchema);
const userSurveyCreate = getCreateSaga('api_surveys:survey_relationship_answer');

const userSurveyRetrieveList = getFilterSaga('api_surveys:user_surveys', schema.userSurveySchema);

// Coaching sagas
const programRetrieveList = getFilterSaga('api_coaching:programs_filter', schema.programSchema);
const programRetrieveListLoadMore = getLoadMoreSaga(schema.programSchema);
const programRetrieveDetails = getRetrieveSaga(
  'api_coaching:program_details',
  schema.programSchema,
  'public_id'
);
const programCreate = getCreateSaga('api_coaching:program_create', schema.programSchema);
const programUpdate = getUpdateSaga(
  'api_coaching:program_details',
  schema.programSchema,
  'public_id'
);
const programRemove = getDeleteSaga(
  'api_coaching:program_details',
  schema.programSchema,
  'public_id'
);
const mentorshipProgramRetrieveList = getFilterSaga(
  'api_coaching:mentorship_programs_filter',
  schema.mentorshipProgramSchema
);
const mentorshipProgramRetrieveListLoadMore = getLoadMoreSaga(schema.programSchema);
const mentorshipProgramRetrieveDetails = getRetrieveSaga(
  'api_coaching:mentorship_program_details',
  schema.mentorshipProgramSchema,
  'public_id'
);
const mentorshipProgramCreate = getCreateSaga(
  'api_coaching:mentorship_program_create',
  schema.mentorshipProgramSchema
);
const mentorshipProgramUpdate = getUpdateSaga(
  'api_coaching:mentorship_program_details',
  schema.mentorshipProgramSchema,
  'public_id'
);
const mentorshipProgramRemove = getDeleteSaga(
  'api_coaching:program_details',
  schema.mentorshipProgramSchema,
  'public_id'
);

// Videos sagas
const videoList = getFilterSaga('api_videos:filter', schema.videoSchema);
const videoListLoadMore = getLoadMoreSaga(schema.videoSchema);
const videoRetrieveDetails = getRetrieveSaga('api_videos:detail', schema.videoSchema, 'public_id');
const videoCreate = getCreateSaga('api_videos:create', schema.videoSchema);
const videoUpdate = getUpdateSaga('api_videos:edit', schema.videoSchema, 'public_id');
const videoRemove = getDeleteSaga('api_videos:delete', schema.videoSchema, 'public_id');
const videoForceRemove = getDeleteSaga('api_videos:force_delete', schema.videoSchema, 'public_id');

// Customer sagas
const customerUpdate = getUpdateSagaForCustomerSettings('api_customers:retrieve_edit', null);

// Announcements sagas
const announcementsFilter = getFilterSaga('api_announcements:filter', schema.announcementsSchema);

const csvImportCreate = getCreateSaga('api_csv_imports:import', null, 'id', true);
const csvImportRetrieve = getRetrieveSaga('api_csv_imports:detail', schema.csvImportSchema, 'id');
const csvImportUpdate = getUpdateSaga('api_csv_imports:detail', schema.csvImportSchema, 'id', true);

function mapToDateFormat(items) {
  return map(items, (s) => moment(s.starts_at_tz_aware).format(API_DATE_FORMAT));
}

function* sessionRetrieveColumns(action) {
  const { key, numberOfColumns } = action.payload;

  const filterSaga = getFilterSaga('api_coaching:sessions_filter_create', schema.sessionSchema);
  const loadMoreSaga = getLoadMoreSaga(schema.sessionSchema);

  let response = yield filterSaga(action);

  let { results, next: nextPage } = response.data;

  let dates = uniq(map(results, (s) => moment(s.starts_at_tz_aware).format(API_DATE_FORMAT)));

  while (dates.length < numberOfColumns + 1 && !isNil(nextPage)) {
    response = yield loadMoreSaga(actions.loadMore({ key, url: nextPage }));

    ({ results, next: nextPage } = response.data);

    dates = uniq(concat(dates, mapToDateFormat(results)));
  }

  return response;
}

function* sessionProgramRetrieveColumns(action) {
  const { key, numberOfColumns } = action.payload;

  const filterSaga = getFilterSaga(
    'api_coaching:program_sessions_filter_create',
    schema.sessionSchema
  );
  const loadMoreSaga = getLoadMoreSaga(schema.sessionSchema);

  let response = yield filterSaga(action);

  let { results, next: nextPage } = response.data;

  let dates = uniq(map(results, (s) => moment(s.starts_at_tz_aware).format(API_DATE_FORMAT)));

  while (dates.length < numberOfColumns + 1 && !isNil(nextPage)) {
    response = yield loadMoreSaga(actions.loadMore({ key, url: nextPage }));

    ({ results, next: nextPage } = response.data);

    dates = uniq(concat(dates, mapToDateFormat(results)));
  }

  return response;
}

function* sessionRetrieveColumnsLoadMore(action) {
  const { key, url, currentDates, numberOfColumns } = action.payload;

  const loadMoreSaga = getLoadMoreSaga(schema.sessionSchema);

  let response;
  let dates = clone(currentDates);
  let nextPage = url;

  while (dates.length < numberOfColumns + 1 && !isNil(nextPage)) {
    response = yield loadMoreSaga(actions.loadMore({ key, url: nextPage }));

    const { results } = response.data;
    ({ next: nextPage } = response.data);

    dates = uniq(concat(dates, mapToDateFormat(results)));
  }

  return response;
}

// Still used in my sessions
const sessionRetrieveList = getFilterSaga(
  'api_coaching:sessions_filter_create',
  schema.sessionSchema
);

const sessionRetrieveListLoadMore = getLoadMoreSaga(schema.sessionSchema);

const sessionFeedbackList = getFilterSaga('api_coaching:sessions_feedback_list');

const sessionEnrollmentUpdate = getUpdateSaga(
  'api_coaching:session_enrollment',
  schema.sessionSchema
);

const sessionUserEnrollmentUpdate = getUpdateSagaForUserEnrollment(
  'api_coaching:user_session_enrollment',
  schema.sessionSchema
);

const sessionRequest = getCreateSaga('api_coaching:session_request');
const mentorshipRequestCreate = getCreateSaga('api_coaching:mentorship_request');
const mentorshipRequestRetrieveList = getFilterSaga(
  'api_coaching:mentorship_request',
  schema.mentorshipRequestSchema
);
const mentorshipRequestUpdate = getUpdateSaga(
  'api_coaching:mentorship_request_details',
  schema.mentorshipRequestSchema
);

const sessionBulkCopy = getCreateSaga('api_coaching:bulk_copy');

const mentorshipRequestRetrieveListLoadMore = getLoadMoreSaga(schema.mentorshipRequestSchema);

const sessionFeedbackUpdate = getUpdateSaga('api_coaching:session_feedback', schema.sessionSchema);

const sessionMentorshipRetrieveList = getFilterSaga(
  'api_coaching:mentorship_sessions_filter_create',
  schema.sessionSchema
);
const sessionMentorshipRetrieveListLoadMore = getLoadMoreSaga(schema.sessionSchema);
const sessionMentorshipRetrieveDetails = getRetrieveSaga(
  'api_coaching:mentorship_session_details',
  schema.sessionSchema,
  'public_id'
);
const sessionMentorshipCreate = getCreateSaga(
  'api_coaching:mentorship_sessions_filter_create',
  schema.sessionSchema
);
const sessionMentorshipUpdate = getUpdateSaga(
  'api_coaching:mentorship_session_details',
  schema.sessionSchema,
  'public_id'
);
const sessionMentorshipRemove = getDeleteSaga(
  'api_coaching:mentorship_session_details',
  schema.sessionSchema,
  'public_id'
);

const sessionProgramRetrieveList = getFilterSaga(
  'api_coaching:program_sessions_filter_create',
  schema.sessionSchema
);

const sessionEnrollmentRetrieveListLoadMore = getLoadMoreSaga(schema.sessionEnrollmentSchema);

const sessionProgramRetrieveDetails = getRetrieveSaga(
  'api_coaching:program_session_details',
  schema.sessionSchema,
  'public_id'
);
const sessionProgramCreate = getCreateSaga(
  'api_coaching:program_sessions_filter_create',
  schema.sessionSchema
);
const sessionProgramUpdate = getUpdateSaga(
  'api_coaching:program_session_details',
  schema.sessionSchema,
  'public_id'
);
const sessionProgramRemove = getDeleteSaga(
  'api_coaching:program_session_details',
  schema.sessionSchema,
  'public_id'
);

const mentorRetrieveList = getFilterSaga('api_coaching:mentors_filter', schema.userSchema);
const mentorRetrieveListLoadMore = getLoadMoreSaga(schema.userSchema);

// Session enrollment sagas
const sessionEnrollmentList = getFilterSaga(
  'api_coaching:enrollment',
  schema.sessionEnrollmentSchema
);
const sessionEnrollmentCreate = getCreateSaga('api_coaching:enrollment', schema.sessionSchema);

const testimonialRetrieveList = getFilterSaga(
  'api_testimonials:testimonials_filter',
  schema.testimonialSchema
);
const testimonialListLoadMore = getLoadMoreSaga(schema.testimonialSchema);
const testimonialCreate = getCreateSaga(
  'api_testimonials:testimonials_filter',
  schema.testimonialSchema
);
const testimonialUpdate = getUpdateSaga(
  'api_testimonials:testimonial_details',
  schema.testimonialSchema
);
const testimonialRemove = getDeleteSaga('api_testimonials:testimonial_details');

const testimonialUpdateHiddenStatus = getUpdateSaga(
  'api_testimonials:testimonial_hidden_status',
  schema.testimonialSchema
);

// Groups sagas
const groupsFilter = getFilterSaga('api_internalgroups:list_create', schema.groupSchema);
const groupFilterListLoadMore = getLoadMoreSaga(schema.groupSchema);
const groupRetrieve = getRetrieveSaga(
  'api_internalgroups:retrieve_update_delete',
  schema.groupSchema
);
const groupCreate = getCreateSaga('api_internalgroups:list_create', schema.groupSchema);
const groupUpdate = getUpdateSaga('api_internalgroups:retrieve_update_delete', schema.groupSchema);
const groupRemove = getDeleteSaga('api_internalgroups:retrieve_update_delete');

// Opengraph sagas
const metadataFetch = getCreateSaga('opengraph_api:metadata', null);

// Topics sagas
const retrieveTopics = getFilterSaga('api_topics:tags', schema.topicSchema);
const retrieveRelatedTags = getFilterSaga('api_events:related_topics', schema.topicSchema);

// Codelabs sagas
const codelabList = getFilterSaga('api_codelabs:filter', schema.codelabSchema);
const codelabListLoadMore = getLoadMoreSaga(schema.codelabSchema);
const codelabRetrieveDetails = getRetrieveSaga(
  'api_codelabs:detail',
  schema.codelabSchema,
  'public_id'
);
const codelabUpload = getCreateSaga('api_codelabs:upload', null);
const codelabUpdate = getUpdateSaga('api_codelabs:edit', schema.codelabSchema, 'public_id');
const codelabRemove = getDeleteSaga('api_codelabs:delete', schema.codelabSchema, 'public_id');
const codelabForceRemove = getDeleteSaga(
  'api_codelabs:force_delete',
  schema.codelabSchema,
  'public_id'
);
const codelabArchive = getCreateSaga('codelabs_api:archival', schema.codelabSchema);
const codelabRestore = getDeleteSaga('codelabs_api:archival', schema.codelabSchema);

// Course sagas
const courseRetrieveList = getFilterSaga('api_courses:filter', schema.courseSchema);
const courseRetrieveListLoadMore = getLoadMoreSaga(schema.courseSchema);
const courseRetrieve = getRetrieveSaga('api_courses:detail', schema.courseSchema, 'public_id');
const courseConfigurationRetrieve = getRetrieveSaga('api_courses:configuration', null, 'public_id');
const courseConfigurationUpdate = getUpdateSaga('api_courses:configuration', null, 'public_id');
const courseUpload = getCreateSaga('api_courses:upload', null);
const courseEdit = getUpdateSaga('api_courses:edit', schema.courseSchema, 'public_id');
const courseDelete = getDeleteSaga('api_courses:delete', schema.courseSchema, 'public_id');
const courseForceDelete = getDeleteSaga(
  'api_courses:force_delete',
  schema.courseSchema,
  'public_id'
);

const courseBulkRegister = getCreateSaga('api_courses:bulk_register', null, 'public_id', true);
const courseRegister = getCreateSaga('api_courses:register', schema.registrationSchema);
const courseRegistrationRetrieveList = getFilterSaga(
  'api_courses:registered_filter',
  schema.registrationSchema
);
const courseRegistrationRetrieveListLoadMore = getLoadMoreSaga(schema.registrationSchema);
const courseFeedbackRetrieveList = getFilterSaga('api_courses:registered_filter');
function* courseUserRegistrationUpdate(action) {
  const updateRequest = getUpdateSaga(
    'api_courses:registration_retrieve',
    schema.registrationSchema
  );
  const response = yield updateRequest(action);

  yield updateEntitySaga(response.data.course, schema.courseSchema, {
    registration: response.data?.id ?? null,
  });
}
const courseRegistrationAssigneeRosterUpdate = getUpdateSaga(
  'api_courses:registration_retrieve',
  schema.registrationSchema
);
const courseRegistrationUsersForEmails = getFilterSaga(
  'api_courses:registered_filter',
  schema.registrationEmailsSchema
);

const courseRegistrationDelete = getDeleteSaga('api_courses:registration_retrieve');

// Catalog sagas
const catalogDiscoverRetrieveList = getFilterSaga('api_catalog:discover', schema.contentSchema);
const catalogDiscoverRetrieveListLoadMore = getLoadMoreSaga(schema.contentSchema);

// Shared sagas
const getAWSData = getRetrieveSaga('api_integrations:aws_data');
const generateS3SignedUrl = getCreateSaga('api_integrations:generate-s3-signed-url');

// Integrations
const gcFreeBusyCheck = getRetrieveSaga('api_integrations:gc-free-busy');
function* retrieveCalendarRooms(action) {
  const retrieveRequest = getRetrieveSaga('api_integrations:rooms');
  const response = yield retrieveRequest(action);

  const redirectUrl = get(response, 'redirect');
  if (redirectUrl) {
    window.location = redirectUrl;
  }
}
function* checkRoomAvailability(action) {
  yield delay(800);

  const retrieveRequest = getRetrieveSaga('api_integrations:room_availability');
  yield retrieveRequest(action);
}
const resyncResourceToCalendar = getCreateSaga('api_integrations:calendar_resource_resync');

const slackTurnOff = getRetrieveSaga('api_integrations:slack-turnoff');

// Channels
const retrieveChannels = getFilterSaga('channels_api:list_create', schema.topicSchema);

// Segments sagas

const segmentRetrieve = getRetrieveSaga('api_segments:detail', schema.segmentSchema, 'public_id');
const segmentRetrieveDashboard = getRetrieveSaga('api_segments:dashboard');
const segmentList = getFilterSaga('api_segments:filter', schema.segmentSchema);
const segmentListLoadMore = getLoadMoreSaga(schema.segmentSchema);
const segmentCreate = getCreateSaga('api_segments:create', schema.segmentSchema);
const segmentUpdate = getUpdateSaga('api_segments:edit', schema.segmentSchema, 'public_id');
const segmentRemove = getDeleteSaga('api_segments:delete', schema.segmentSchema, 'public_id');

// GraphQL sagas
const graphQLTokenRefresh = getCreateSaga('graphql-refresh');

// AutomatedRules sagas
const automatedRuleRetrieve = getRetrieveSaga(
  'api_integrations:people-integration-rule-detail',
  schema.automatedRuleSchema,
  'public_id'
);
const automatedRuleList = getFilterSaga(
  'api_integrations:people-integration-rule-filter',
  schema.automatedRuleSchema
);
const automatedRuleListLoadMore = getLoadMoreSaga(schema.automatedRuleSchema);

const automatedRuleListLogs = getFilterSaga(
  'api_integrations:people-integration-rule-logs',
  schema.automatedRuleLogSchema,
  'public_id'
);

const automatedRuleListLogtrail = getNestedFilterSaga(
  'api_integrations:people-integration-rule-logtrail',
  schema.automatedRuleLogtrailSchema
);

const automatedRuleCreate = getCreateSaga(
  'api_integrations:people-integration-rule-create',
  schema.automatedRuleSchema
);
const automatedRuleUpdate = getUpdateSaga(
  'api_integrations:people-integration-rule-detail',
  schema.automatedRuleSchema,
  'public_id'
);
const automatedRuleRemove = getDeleteSaga(
  'api_integrations:people-integration-rule-detail',
  schema.automatedRuleSchema,
  'public_id'
);
const automatedRuleRun = getCreateSaga('api_integrations:people-integration-rule-run', null);

const analyticsForEventsFilter = getFilterSaga('api_analytics:events');
const analyticsForEnrollmentsFilter = getFilterSaga('api_analytics:enrollments');
const analyticsForAttendancesFilter = getFilterSaga('api_analytics:attendances');
const analyticsForEnrollmentsByUserFilter = getFilterSaga('api_analytics:enrollments-by-user');
const analyticsForPresentersFilter = getFilterSaga('api_analytics:presenters');
const analyticsForSessionsFilter = getFilterSaga('api_analytics:sessions');
const analyticsForMentorsFilter = getFilterSaga('api_analytics:mentors');
const analyticsForAssignmentsFilter = getFilterSaga('api_analytics:assignments');
const analyticsForChartSessionsFilter = getFilterSaga('api_analytics:report-sessions');
const analyticsForChartEventsFilter = getFilterSaga('api_analytics:report-events');
const analyticsForChartEngagementFilter = getFilterSaga('api_analytics:report-engagement');

const analyticsForChartMentorshipFilter = getFilterSaga('api_charts:mentoring');
const analyticsForChartMentorshipFeedbackFilter = getFilterSaga('api_charts:mentoring-feedback');
const analyticsForChartMentorshipEnrollmentCountHoursByMentorTitleFilter = getFilterSaga(
  'api_charts:mentoring-enrollment-count-hours-by-mentor-title'
);
const analyticsForChartMentorshipEnrollmentCountHoursByMentorDepartmentFilter = getFilterSaga(
  'api_charts:mentoring-enrollment-count-hours-by-mentor-department'
);
const analyticsForChartMentorshipEnrollmentCountHoursByMenteeTitleFilter = getFilterSaga(
  'api_charts:mentoring-enrollment-count-hours-by-mentee-title'
);
const analyticsForChartMentorshipEnrollmentCountHoursByMenteeDepartmentFilter = getFilterSaga(
  'api_charts:mentoring-enrollment-count-hours-by-mentee-department'
);
const analyticsForChartEventFilter = getFilterSaga('api_charts:events');
const analyticsForChartEnrollmentCountHoursFilter = getFilterSaga(
  'api_charts:event-enrollment-count-hours'
);
const analyticsForChartEnrollmentRatingsFilter = getFilterSaga(
  'api_charts:event-enrollment-ratings'
);
const analyticsForChartEnrollmentStatusFilter = getFilterSaga('api_charts:event-enrollment-status');
const analyticsForChartEventEnrollmentCountHoursByPresenterDepartmentFilter = getFilterSaga(
  'api_charts:event-enrollment-count-hours-by-presenter-department'
);
const analyticsForChartEventEnrollmentCountHoursByPresenterTitleFilter = getFilterSaga(
  'api_charts:event-enrollment-count-hours-by-presenter-title'
);
const analyticsForChartEventEnrollmentCountHoursByEnrolleeDepartmentFilter = getFilterSaga(
  'api_charts:event-enrollment-count-hours-by-enrollee-department'
);
const analyticsForChartEventEnrollmentCountHoursByEnrolleeTitleFilter = getFilterSaga(
  'api_charts:event-enrollment-count-hours-by-enrollee-title'
);
const analyticsForChartEventsByAttributeFilter = getFilterSaga('api_charts:events-by-attribute');
const analyticsForChartProgramSessionEnrollmentCountHoursFilter = getFilterSaga(
  'api_charts:program-session-enrollment-count-hours'
);
const analyticsForChartProgramSessionAttendeeRatingsFilter = getFilterSaga(
  'api_charts:program-session-attendee-rating'
);
const analyticsForChartProgramSessionEnrollmentCountHoursByHostTitleFilter = getFilterSaga(
  'api_charts:program-session-enrollment-count-hours-by-host-title'
);
const analyticsForChartProgramSessionEnrollmentCountHoursByHostDepartmentFilter = getFilterSaga(
  'api_charts:program-session-enrollment-count-hours-by-host-department'
);
const analyticsForChartProgramSessionEnrollmentCountHoursByAttendeeTitleFilter = getFilterSaga(
  'api_charts:program-session-enrollment-count-hours-by-host-title'
);
const analyticsForChartProgramSessionEnrollmentCountHoursByAttendeeDepartmentFilter = getFilterSaga(
  'api_charts:program-session-enrollment-count-hours-by-host-department'
);
const analyticsForChartUserEngagementFilter = getFilterSaga('api_charts:engagement');

export default function* sagas() {
  yield takeEvery(actions.storeEntities.toString(), storeEntitiesSaga);

  yield debounceByKey(actions.currentUser.filters.toString(), currentUserFilters);

  yield debounceByKey(actions.event.retrieveDetails.toString(), eventRetrieve);
  yield debounceByKey(actions.event.retrieveList.toString(), eventList);
  yield debounceByKey(actions.event.retrieveListRql.toString(), eventListRQL);
  yield debounceByKey(actions.event.retrieveFilters.toString(), eventRetrieveFilters);
  yield debounceByKey(actions.event.retrieveListLoadMore.toString(), eventListLoadMore);
  yield debounceByKey(actions.event.createSubmit.toString(), eventCreate);
  yield debounceByKey(actions.event.updateSubmit.toString(), eventUpdate);
  yield debounceByKey(actions.event.bulkCopySubmit.toString(), eventBulkCopy);
  yield debounceByKey(actions.event.archive.toString(), eventUpdate);
  yield debounceByKey(actions.event.remove.toString(), eventRemove);
  yield debounceByKey(actions.event.feedbackList.toString(), eventFeedbackList);
  yield debounceByKey(actions.event.retrieveDetailsEmail.toString(), eventAttendeeEmailList);

  yield debounceByKey(actions.eventEnrollment.updateEnrolledUser.toString(), eventEnrollmentUpdate);
  yield debounceByKey(actions.eventEnrollment.updateSubmit.toString(), updateEnrollment);

  yield debounceByKey(
    actions.enrollment.deprecatedRetrieveList.toString(),
    enrollmentDeprecatedList
  );
  yield debounceByKey(actions.enrollment.retrieveList.toString(), enrollmentList);
  yield debounceByKey(actions.enrollment.retrieveFilters.toString(), enrollmentRetrieveFilters);
  yield debounceByKey(actions.enrollment.retrieveListLoadMore.toString(), enrollmentListLoadMore);
  yield debounceByKey(actions.enrollment.createSubmit.toString(), enrollmentCreate);
  // enrollment retrieve update
  yield debounceByKey(actions.enrollment.retrieve.toString(), enrollmentRetrieve);
  yield debounceByKey(actions.enrollment.updateOrder.toString(), enrollmentUpdateOrder);
  yield debounceByKey(actions.enrollment.checkin.toString(), enrollmentCheckin);
  yield debounceByKey(actions.enrollment.undoCheckin.toString(), enrollmentUndoCheckin);
  yield debounceByKey(actions.enrollment.promote.toString(), enrollmentPromote);
  yield debounceByKey(actions.enrollment.demote.toString(), enrollmentDemote);
  yield debounceByKey(actions.enrollment.drop.toString(), enrollmentDrop);
  // bulk enrollment
  yield debounceByKey(actions.enrollment.bulk.toString(), enrollmentBulkCreate);
  yield debounceByKey(actions.enrollment.bulkPromote.toString(), enrollmentBulkPromote);
  yield debounceByKey(actions.enrollment.bulkDemote.toString(), enrollmentBulkDemote);
  yield debounceByKey(actions.enrollment.bulkCheckin.toString(), enrollmentBulkCheckin);
  yield debounceByKey(actions.enrollment.bulkUndoCheckin.toString(), enrollmentBulkUndoCheckin);
  yield debounceByKey(actions.enrollment.bulkDrop.toString(), enrollmentBulkDrop);

  yield debounceByKey(actions.userData.retrieveDetails.toString(), userRetrieve);
  yield debounceByKey(actions.userData.retrieveListLoadMore.toString(), userRetrieveListLoadMore);
  yield debounceByKey(actions.userData.updateSubmit.toString(), userUpdate);
  yield debounceByKey(actions.userData.createSubmit.toString(), userCreate);
  yield debounceByKey(actions.userData.retrieveList.toString(), userFilter);
  yield debounceByKey(actions.userData.retrieveListRql.toString(), userListRQL);
  yield debounceByKey(actions.userData.retrieveFilters.toString(), userRetrieveFilters);
  yield debounceByKey(actions.userData.remove.toString(), userRemove);
  yield debounceByKey(actions.userData.retrieveImpactedList.toString(), userRetrieveImpactedList);
  yield debounceByKey(
    actions.userData.retrieveFacilitatorFeedback.toString(),
    userRetrieveFacilitatorFeedback
  );

  yield debounceByKey(
    actions.userData.retrieveConsumedContentList.toString(),
    consumedContentRetrieve
  );
  yield debounceByKey(actions.userData.retrieveOverallImpactList.toString(), overallImpactRetrieve);
  yield debounceByKey(
    actions.userData.retrieveRecentActivityList.toString(),
    userRecentActivityList
  );

  yield debounceByKey(actions.eventType.retrieveList.toString(), eventTypeList);
  yield debounceByKey(actions.eventType.retrieveListRql.toString(), eventTypeListRQL);
  yield debounceByKey(actions.eventType.retrieveFilters.toString(), eventTypeRetrieveFilters);
  yield debounceByKey(actions.eventType.retrieveListLoadMore.toString(), eventTypeListLoadMore);
  yield debounceByKey(actions.eventType.retrieveDetails.toString(), eventTypeRetrieve);
  yield debounceByKey(actions.eventType.createSubmit.toString(), eventTypeCreate);
  yield debounceByKey(actions.eventType.updateSubmit.toString(), eventTypeUpdate);
  yield debounceByKey(actions.eventType.remove.toString(), eventTypeDelete);
  yield debounceByKey(actions.eventType.forceRemove.toString(), eventTypeForceDelete);
  yield debounceByKey(actions.eventType.requestEvent.toString(), eventTypeRequestEvent);
  yield debounceByKey(
    actions.eventType.retrieveRequestedEvents.toString(),
    eventTypeFilterRequestedEvents
  );
  yield debounceByKey(
    actions.eventType.retrieveRequestedEventsLoadMore,
    eventTypeFilterRequestedEventsLoadMore
  );
  yield debounceByKey(actions.enrollment.listEmails.toString(), enrollmentListEmails);
  yield debounceByKey(actions.eventType.archive.toString(), eventTypeUpdate);

  yield debounceByKey(actions.content.retrieveList.toString(), contentList);
  yield debounceByKey(actions.content.retrieveListRql.toString(), contentListRQL);
  yield debounceByKey(actions.content.retrieveListLoadMore.toString(), contentListLoadMore);
  yield debounceByKey(actions.content.retrieveFilters.toString(), contentRetrieveFilters);
  yield debounceByKey(actions.content.retrieveDetails.toString(), contentRetrieve);

  yield debounceByKey(actions.content.feedbackList.toString(), contentFeedbackList);

  yield debounceByKey(
    actions.contentAssignment.retrieveList.toString(),
    contentAssignmentRetrieveList
  );
  yield debounceByKey(
    actions.contentAssignment.retrieveListLoadMore.toString(),
    contentAssignmentRetrieveListLoadMore
  );
  yield debounceByKey(
    actions.contentAssignment.retrieveUsersForEmails.toString(),
    contentAssignmentsUsersForEmails
  );
  yield debounceByKey(actions.contentAssignment.updateSubmit.toString(), contentAssignmentUpdate);
  yield debounceByKey(actions.contentAssignment.update.toString(), contentUserAssignmentUpdate);
  yield debounceByKey(
    actions.contentAssignment.bulkAssign.toString(),
    contentItemBulkAssignmentUpdate
  );
  yield debounceByKey(
    actions.contentAssignment.retrieveRqlFilters.toString(),
    contentAssignmentRQLFilters
  );
  yield debounceByKey(actions.contentAssignment.retrieveRql.toString(), contentAssignmentRQL);
  yield debounceByKey(
    actions.contentAssignment.teamRetrieveRqlFilters.toString(),
    teamContentAssignmentRQLFilters
  );
  yield debounceByKey(
    actions.contentAssignment.teamRetrieveRql.toString(),
    teamContentAssignmentRQL
  );

  yield debounceByKey(actions.assignment.list.toString(), assignmentList);
  yield debounceByKey(actions.assignment.listLoadMore.toString(), assignmentListLoadMore);
  yield debounceByKey(actions.assignment.listEmails.toString(), assignmentListEmails);
  yield debounceByKey(actions.assignment.createSubmit.toString(), assignmentCreate);
  yield debounceByKey(actions.assignment.retrieve.toString(), assignmentRetrieve);
  yield debounceByKey(actions.assignment.updateSubmit.toString(), assignmentUpdate);
  yield debounceByKey(actions.assignment.remove.toString(), assignmentRemove);
  yield debounceByKey(actions.assignment.progressRetrieve.toString(), assignmentProgressRetrieve);
  yield debounceByKey(actions.assignment.progressSubmit.toString(), assignmentProgressSubmit);
  yield debounceByKey(actions.assignment.progressRemove.toString(), assignmentProgressRemove);
  yield debounceByKey(
    actions.assignment.completionRetrieve.toString(),
    assignmentCompletionRetrieve
  );
  yield debounceByKey(actions.assignment.completionSubmit.toString(), assignmentCompletionSubmit);
  yield debounceByKey(actions.assignment.completionRemove.toString(), assignmentCompletionRemove);
  yield debounceByKey(actions.assignment.dropRetrieve.toString(), assignmentDropRetrieve);
  yield debounceByKey(actions.assignment.dropSubmit.toString(), assignmentDropSubmit);
  yield debounceByKey(actions.assignment.exemptionRetrieve.toString(), assignmentExemptionRetrieve);
  yield debounceByKey(actions.assignment.exemptionSubmit.toString(), assignmentExemptionSubmit);
  yield debounceByKey(actions.assignment.exemptionRemove.toString(), assignmentExemptionRemove);
  yield debounceByKey(actions.assignment.ratingRetrieve.toString(), assignmentRatingRetrieve);
  yield debounceByKey(actions.assignment.ratingSubmit.toString(), assignmentRatingSubmit);
  yield debounceByKey(
    actions.assignment.completionAcknowledgementSubmit.toString(),
    assignmentCompletionAcknowledgementSubmit
  );
  yield debounceByKey(actions.assignment.bulk.toString(), assignmentBulk);
  yield debounceByKey(actions.assignment.bulkUpdate.toString(), assignmentBulkUpdate);
  yield debounceByKey(actions.assignment.bulkCompletion.toString(), assignmentBulkCompletion);
  yield debounceByKey(
    actions.assignment.bulkUndoCompletion.toString(),
    assignmentBulkUndoCompletion
  );
  yield debounceByKey(actions.assignment.bulkExemption.toString(), assignmentBulkExemption);
  yield debounceByKey(actions.assignment.bulkUndoExemption.toString(), assignmentBulkUndoExemption);
  yield debounceByKey(actions.assignment.bulkDrop.toString(), assignmentBulkDrop);
  yield debounceByKey(actions.assignment.bulkExpire.toString(), assignmentBulkExpire);

  yield debounceByKey(actions.track.retrieveBreadcrumbs.toString(), trackRetrieveBreadcrumbs);
  yield debounceByKey(actions.track.retrieveDescendantTrackItems.toString(), descendantTrackItems);
  yield debounceByKey(actions.track.retrieveListLoadMore.toString(), trackListLoadMore);
  yield debounceByKey(actions.track.retrieveDetails.toString(), trackRetrieveDetails);
  yield debounceByKey(actions.track.createSubmit.toString(), trackCreate);
  yield debounceByKey(actions.track.updateSubmit.toString(), trackUpdate);
  yield debounceByKey(actions.track.archive.toString(), trackArchive);
  yield debounceByKey(actions.track.restore.toString(), trackRestore);
  yield debounceByKey(actions.track.retrieveList.toString(), oldTrackList);
  yield debounceByKey(actions.track.remove.toString(), trackRemove);
  yield debounceByKey(actions.track.forceRemove.toString(), trackForceRemove);

  yield debounceByKey(actions.trackItem.retrieveList.toString(), trackItemList);
  yield debounceByKey(actions.trackItem.retrieveListLoadMore.toString(), trackItemListLoadMore);
  yield debounceByKey(actions.trackItem.archive.toString(), trackItemArchive);
  yield debounceByKey(actions.trackItem.unarchive.toString(), trackItemUnarchive);

  yield debounceByKey(
    actions.scheduledTrack.retrieveDetails.toString(),
    scheduledTrackRetrieveDetails
  );
  yield debounceByKey(actions.scheduledTrack.createSubmit.toString(), scheduledTrackCreate);
  yield debounceByKey(actions.scheduledTrack.updateSubmit.toString(), scheduledTrackUpdate);
  yield debounceByKey(actions.scheduledTrack.archive.toString(), scheduledTrackArchive);
  yield debounceByKey(actions.scheduledTrack.restore.toString(), scheduledTrackRestore);
  yield debounceByKey(actions.scheduledTrack.retrieveList.toString(), oldScheduledTrackList);
  yield debounceByKey(
    actions.scheduledTrack.retrieveFilters.toString(),
    oldScheduledTrackRetrieveFilters
  );
  yield debounceByKey(actions.scheduledTrack.remove.toString(), oldScheduledTrackRemove);
  yield debounceByKey(
    actions.scheduledTrack.retrieveListLoadMore.toString(),
    scheduledTrackListLoadMore
  );
  yield debounceByKey(
    actions.scheduledTrack.retrieveTrackEventList.toString(),
    oldScheduledTrackEventsList
  );

  yield debounceByKey(actions.assessment.retrieveDetails.toString(), assessmentRetrieveDetails);
  yield debounceByKey(actions.assessment.createSubmit.toString(), assessmentCreate);
  yield debounceByKey(actions.assessment.updateSubmit.toString(), assessmentUpdate);
  yield debounceByKey(actions.assessment.archive.toString(), assessmentArchive);
  yield debounceByKey(actions.assessment.restore.toString(), assessmentRestore);
  yield debounceByKey(actions.assessment.retrieveList.toString(), assessmentList);
  yield debounceByKey(actions.assessment.remove.toString(), assessmentRemove);
  yield debounceByKey(actions.assessment.forceRemove.toString(), assessmentForceRemove);
  yield debounceByKey(actions.assessment.retrieveListLoadMore.toString(), assessmentListLoadMore);

  yield debounceByKey(actions.question.retrieveDetails.toString(), questionRetrieveDetails);
  yield debounceByKey(actions.question.createSubmit.toString(), questionCreate);
  yield debounceByKey(actions.question.updateSubmit.toString(), questionUpdate);
  yield debounceByKey(actions.question.retrieveList.toString(), questionList);
  yield debounceByKey(actions.question.remove.toString(), questionRemove);
  yield debounceByKey(actions.question.retrieveListLoadMore.toString(), questionListLoadMore);

  yield debounceByKey(
    actions.multipleChoiceQuestion.optionsList.toString(),
    multipleChoiceQuestionOptionsList
  );
  yield debounceByKey(
    actions.multipleChoiceQuestionResponse.completion.toString(),
    multipleChoiceQuestionResponsesCompletion
  );
  yield debounceByKey(
    actions.textQuestionResponse.completion.toString(),
    textQuestionResponsesCompletion
  );

  yield debounceByKey(
    actions.linkedContent.retrieveDetails.toString(),
    linkedContentRetrieveDetails
  );
  yield debounceByKey(actions.linkedContent.createSubmit.toString(), linkedContentCreate);
  yield debounceByKey(actions.linkedContent.updateSubmit.toString(), linkedContentUpdate);
  yield debounceByKey(actions.linkedContent.retrieveList.toString(), linkedContentList);
  yield debounceByKey(actions.linkedContent.remove.toString(), linkedContentRemove);
  yield debounceByKey(actions.linkedContent.forceRemove.toString(), linkedContentForceRemove);
  yield debounceByKey(
    actions.linkedContent.retrieveListLoadMore.toString(),
    linkedContentListLoadMore
  );
  yield debounceByKey(actions.linkedContent.archive.toString(), linkedContentArchive);
  yield debounceByKey(actions.linkedContent.restore.toString(), linkedContentRestore);

  yield debounceByKey(actions.article.remove.toString(), articleRemove);
  yield debounceByKey(actions.article.forceRemove.toString(), articleForceRemove);

  yield debounceByKey(actions.task.retrieveList.toString(), taskList);
  yield debounceByKey(actions.task.createSubmit.toString(), taskCreate);
  yield debounceByKey(actions.task.retrieveDetails.toString(), taskRetrieveDetails);
  yield debounceByKey(actions.task.updateSubmit.toString(), taskUpdate);
  yield debounceByKey(actions.task.remove.toString(), taskRemove);
  yield debounceByKey(actions.task.forceRemove.toString(), taskForceRemove);
  yield debounceByKey(actions.task.convert.toString(), taskConvert);

  yield debounceByKey(actions.location.retrieveList.toString(), locationRetrieveList);

  yield debounceByKey(actions.survey.create.toString(), surveyCreate);
  yield debounceByKey(actions.survey.update.toString(), surveyUpdate);
  yield debounceByKey(actions.survey.retrieveList.toString(), surveyRetrieveList);
  yield debounceByKey(actions.survey.retrieveDetails.toString(), surveyRetrieveDetails);
  yield debounceByKey(actions.survey.preview.toString(), surveyPreview);
  yield debounceByKey(
    actions.surveyRelationship.retrieveList.toString(),
    surveyRelationshipRetrieveList
  );
  yield debounceByKey(
    actions.surveyRelationship.retrieveDetails.toString(),
    surveyRelationshipRetrieveDetails
  );
  yield debounceByKey(actions.surveyRelationship.createSubmit.toString(), surveyRelationshipCreate);
  yield debounceByKey(
    actions.surveyRelationship.exportMultiple.toString(),
    surveyRelationshipExportMultiple
  );
  yield debounceByKey(actions.surveyRelationship.remove.toString(), surveyRelationshipRemove);
  yield debounceByKey(actions.surveyRelationship.updateSubmit.toString(), surveyRelationshipUpdate);

  yield debounceByKey(
    actions.surveyRelationship.insights.toString(),
    surveyRelationshipInsightsRetrieveDetails
  );

  yield debounceByKey(actions.userSurvey.createSubmit.toString(), userSurveyCreate);
  yield debounceByKey(actions.userSurvey.retrieveList.toString(), userSurveyRetrieveList);

  yield debounceByKey(actions.program.retrieveList.toString(), programRetrieveList);
  yield debounceByKey(actions.program.retrieveListLoadMore.toString(), programRetrieveListLoadMore);
  yield debounceByKey(actions.program.retrieveDetails.toString(), programRetrieveDetails);
  yield debounceByKey(actions.program.createSubmit.toString(), programCreate);
  yield debounceByKey(actions.program.updateSubmit.toString(), programUpdate);
  yield debounceByKey(actions.program.archive.toString(), programUpdate);
  yield debounceByKey(actions.program.remove.toString(), programRemove);

  yield debounceByKey(
    actions.mentorshipProgram.retrieveList.toString(),
    mentorshipProgramRetrieveList
  );
  yield debounceByKey(
    actions.mentorshipProgram.retrieveListLoadMore.toString(),
    mentorshipProgramRetrieveListLoadMore
  );
  yield debounceByKey(
    actions.mentorshipProgram.retrieveDetails.toString(),
    mentorshipProgramRetrieveDetails
  );
  yield debounceByKey(actions.mentorshipProgram.createSubmit.toString(), mentorshipProgramCreate);
  yield debounceByKey(actions.mentorshipProgram.updateSubmit.toString(), mentorshipProgramUpdate);
  yield debounceByKey(actions.mentorshipProgram.archive.toString(), mentorshipProgramUpdate);
  yield debounceByKey(actions.mentorshipProgram.remove.toString(), mentorshipProgramRemove);

  yield debounceByKey(actions.session.retrieveColumns.toString(), sessionRetrieveColumns);
  yield debounceByKey(
    actions.session.retrieveColumnsLoadMore.toString(),
    sessionRetrieveColumnsLoadMore
  );
  yield debounceByKey(actions.session.retrieveList.toString(), sessionRetrieveList);
  yield debounceByKey(actions.session.retrieveListLoadMore.toString(), sessionRetrieveListLoadMore);
  yield debounceByKey(actions.session.enrollmentUpdate.toString(), sessionUserEnrollmentUpdate);
  yield debounceByKey(actions.session.enrollmentUpdateSubmit.toString(), sessionEnrollmentUpdate);
  yield debounceByKey(actions.session.feedbackList.toString(), sessionFeedbackList);
  yield debounceByKey(actions.session.requestSession.toString(), sessionRequest);
  yield debounceByKey(actions.session.bulkCopySubmit.toString(), sessionBulkCopy);
  yield debounceByKey(actions.mentorshipRequest.create.toString(), mentorshipRequestCreate);
  yield debounceByKey(
    actions.mentorshipRequest.retrieveList.toString(),
    mentorshipRequestRetrieveList
  );
  yield debounceByKey(
    actions.mentorshipRequest.retrieveListLoadMore.toString(),
    mentorshipRequestRetrieveListLoadMore
  );
  yield debounceByKey(actions.mentorshipRequest.updateSubmit.toString(), mentorshipRequestUpdate);

  yield debounceByKey(
    actions.sessionMentorship.retrieveList.toString(),
    sessionMentorshipRetrieveList
  );
  yield debounceByKey(
    actions.sessionMentorship.retrieveListLoadMore.toString(),
    sessionMentorshipRetrieveListLoadMore
  );
  yield debounceByKey(
    actions.sessionMentorship.retrieveDetails.toString(),
    sessionMentorshipRetrieveDetails
  );
  yield debounceByKey(actions.sessionMentorship.createSubmit.toString(), sessionMentorshipCreate);
  yield debounceByKey(actions.sessionMentorship.updateSubmit.toString(), sessionMentorshipUpdate);
  yield debounceByKey(actions.sessionMentorship.remove.toString(), sessionMentorshipRemove);

  yield debounceByKey(actions.sessionProgram.retrieveList.toString(), sessionProgramRetrieveList);

  yield debounceByKey(actions.sessionEnrollment.retrieveList.toString(), sessionEnrollmentList);
  yield debounceByKey(actions.sessionEnrollment.create.toString(), sessionEnrollmentCreate);
  yield debounceByKey(actions.sessionEnrollment.createSubmit.toString(), sessionEnrollmentCreate);

  yield debounceByKey(
    actions.sessionEnrollment.retrieveListLoadMore.toString(),
    sessionEnrollmentRetrieveListLoadMore
  );
  yield debounceByKey(
    actions.sessionProgram.retrieveDetails.toString(),
    sessionProgramRetrieveDetails
  );
  yield debounceByKey(actions.sessionProgram.createSubmit.toString(), sessionProgramCreate);
  yield debounceByKey(actions.sessionProgram.updateSubmit.toString(), sessionProgramUpdate);
  yield debounceByKey(actions.sessionProgram.remove.toString(), sessionProgramRemove);
  yield debounceByKey(
    actions.sessionProgram.retrieveColumns.toString(),
    sessionProgramRetrieveColumns
  );

  yield debounceByKey(
    actions.sessionEnrollment.feedbackUpdateSubmit.toString(),
    sessionFeedbackUpdate
  );

  yield debounceByKey(actions.mentor.retrieveList.toString(), mentorRetrieveList);
  yield debounceByKey(actions.mentor.retrieveListLoadMore.toString(), mentorRetrieveListLoadMore);

  yield debounceByKey(actions.testimonial.retrieveList.toString(), testimonialRetrieveList);
  yield debounceByKey(actions.testimonial.retrieveListLoadMore.toString(), testimonialListLoadMore);
  yield debounceByKey(actions.testimonial.createSubmit.toString(), testimonialCreate);
  yield debounceByKey(actions.testimonial.updateSubmit.toString(), testimonialUpdate);
  yield debounceByKey(
    actions.testimonial.updateHiddenStatus.toString(),
    testimonialUpdateHiddenStatus
  );
  yield debounceByKey(actions.testimonial.remove.toString(), testimonialRemove);

  yield debounceByKey(actions.codelab.retrieveList.toString(), codelabList);
  yield debounceByKey(actions.codelab.retrieveListLoadMore.toString(), codelabListLoadMore);
  yield debounceByKey(actions.codelab.retrieveDetails.toString(), codelabRetrieveDetails);
  yield debounceByKey(actions.codelab.upload.toString(), codelabUpload);
  yield debounceByKey(actions.codelab.updateSubmit.toString(), codelabUpdate);
  yield debounceByKey(actions.codelab.remove.toString(), codelabRemove);
  yield debounceByKey(actions.codelab.forceRemove.toString(), codelabForceRemove);
  yield debounceByKey(actions.codelab.archive.toString(), codelabArchive);
  yield debounceByKey(actions.codelab.restore.toString(), codelabRestore);

  yield debounceByKey(actions.course.retrieveList.toString(), courseRetrieveList);
  yield debounceByKey(actions.course.retrieveListLoadMore.toString(), courseRetrieveListLoadMore);
  yield debounceByKey(actions.course.retrieveDetails.toString(), courseRetrieve);
  yield debounceByKey(actions.course.retrieveConfiguration.toString(), courseConfigurationRetrieve);
  yield debounceByKey(actions.course.updateConfiguration.toString(), courseConfigurationUpdate);
  yield debounceByKey(actions.course.upload.toString(), courseUpload);
  yield debounceByKey(actions.course.updateSubmit.toString(), courseEdit);
  yield debounceByKey(actions.course.remove.toString(), courseDelete);
  yield debounceByKey(actions.course.forceRemove.toString(), courseForceDelete);
  yield debounceByKey(actions.course.archive.toString(), courseEdit);

  yield debounceByKey(actions.courseRegistration.bulkRegister.toString(), courseBulkRegister);
  yield debounceByKey(actions.courseRegistration.register.toString(), courseRegister);
  yield debounceByKey(
    actions.courseRegistration.retrieveList.toString(),
    courseRegistrationRetrieveList
  );
  yield debounceByKey(
    actions.courseRegistration.feedbackList.toString(),
    courseFeedbackRetrieveList
  );
  yield debounceByKey(
    actions.courseRegistration.retrieveListLoadMore.toString(),
    courseRegistrationRetrieveListLoadMore
  );
  yield debounceByKey(
    actions.courseRegistration.updateSubmit.toString(),
    courseUserRegistrationUpdate
  );
  yield debounceByKey(
    actions.courseRegistration.update.toString(),
    courseRegistrationAssigneeRosterUpdate
  );
  yield debounceByKey(
    actions.courseRegistration.retrieveUsersForEmails.toString(),
    courseRegistrationUsersForEmails
  );
  yield debounceByKey(actions.courseRegistration.remove.toString(), courseRegistrationDelete);

  yield debounceByKey(actions.segment.retrieveDetails.toString(), segmentRetrieve);
  yield debounceByKey(actions.segment.retrieveDashboard.toString(), segmentRetrieveDashboard);
  yield debounceByKey(actions.segment.retrieveList.toString(), segmentList);
  yield debounceByKey(actions.segment.retrieveListLoadMore.toString(), segmentListLoadMore);
  yield debounceByKey(actions.segment.createSubmit.toString(), segmentCreate);
  yield debounceByKey(actions.segment.updateSubmit.toString(), segmentUpdate);
  yield debounceByKey(actions.segment.remove.toString(), segmentRemove);

  yield debounceByKey(actions.graphql.refreshToken.toString(), graphQLTokenRefresh);

  yield debounceByKey(actions.automatedRule.retrieveDetails.toString(), automatedRuleRetrieve);
  yield debounceByKey(actions.automatedRule.retrieveList.toString(), automatedRuleList);
  yield debounceByKey(
    actions.automatedRule.retrieveListLoadMore.toString(),
    automatedRuleListLoadMore
  );
  yield debounceByKey(actions.automatedRule.createSubmit.toString(), automatedRuleCreate);
  yield debounceByKey(actions.automatedRule.updateSubmit.toString(), automatedRuleUpdate);
  yield debounceByKey(actions.automatedRule.remove.toString(), automatedRuleRemove);
  yield debounceByKey(actions.automatedRule.run.toString(), automatedRuleRun);
  yield debounceByKey(actions.automatedRule.retrieveRuleLogs.toString(), automatedRuleListLogs);
  yield debounceByKey(
    actions.automatedRule.retrieveRuleLogtrail.toString(),
    automatedRuleListLogtrail
  );

  yield debounceByKey(actions.topic.retrieveList.toString(), retrieveTopics);
  yield debounceByKey(actions.relatedTag.retrieveList.toString(), retrieveRelatedTags);

  yield debounceByKey(actions.fetchAwsData.toString(), getAWSData);
  yield debounceByKey(actions.generateS3SignedUrl.toString(), generateS3SignedUrl);

  yield debounceByKey(actions.googleCalendar.freeBusyCheck.toString(), gcFreeBusyCheck);
  yield debounceByKey(actions.googleCalendar.retrieveRooms.toString(), retrieveCalendarRooms);
  yield debounceByKey(
    actions.googleCalendar.checkRoomAvailability.toString(),
    checkRoomAvailability
  );
  yield debounceByKey(
    actions.calendar.resyncResourceToCalendar.toString(),
    resyncResourceToCalendar
  );
  yield debounceByKey(actions.slack.turnOff.toString(), slackTurnOff);

  yield debounceByKey(actions.catalogDiscover.retrieveList.toString(), catalogDiscoverRetrieveList);
  yield debounceByKey(
    actions.catalogDiscover.retrieveListLoadMore.toString(),
    catalogDiscoverRetrieveListLoadMore
  );

  yield debounceByKey(actions.analyticsForEvents.retrieveList.toString(), analyticsForEventsFilter);
  yield debounceByKey(
    actions.analyticsForEnrollments.retrieveList.toString(),
    analyticsForEnrollmentsFilter
  );
  yield debounceByKey(
    actions.analyticsForAttendances.retrieveList.toString(),
    analyticsForAttendancesFilter
  );
  yield debounceByKey(
    actions.analyticsForEnrollmentsByUser.retrieveList.toString(),
    analyticsForEnrollmentsByUserFilter
  );
  yield debounceByKey(
    actions.analyticsForPresenters.retrieveList.toString(),
    analyticsForPresentersFilter
  );
  yield debounceByKey(
    actions.analyticsForSessions.retrieveList.toString(),
    analyticsForSessionsFilter
  );
  yield debounceByKey(
    actions.analyticsForMentors.retrieveList.toString(),
    analyticsForMentorsFilter
  );
  yield debounceByKey(actions.groups.retrieveList.toString(), groupsFilter);
  yield debounceByKey(actions.groups.retrieveListLoadMore.toString(), groupFilterListLoadMore);
  yield debounceByKey(actions.groups.retrieveDetails.toString(), groupRetrieve);
  yield debounceByKey(actions.groups.createSubmit.toString(), groupCreate);
  yield debounceByKey(actions.groups.updateSubmit.toString(), groupUpdate);
  yield debounceByKey(actions.groups.remove.toString(), groupRemove);

  yield debounceByKey(actions.video.retrieveList.toString(), videoList);
  yield debounceByKey(actions.video.retrieveListLoadMore.toString(), videoListLoadMore);
  yield debounceByKey(actions.video.retrieveDetails.toString(), videoRetrieveDetails);
  yield debounceByKey(actions.video.createSubmit.toString(), videoCreate);
  yield debounceByKey(actions.video.updateSubmit.toString(), videoUpdate);
  yield debounceByKey(actions.video.remove.toString(), videoRemove);
  yield debounceByKey(actions.video.forceRemove.toString(), videoForceRemove);
  yield debounceByKey(actions.video.archive.toString(), videoUpdate);

  yield debounceByKey(actions.opengraph.retrieveMetadata.toString(), metadataFetch);

  yield debounceByKey(
    actions.analyticsForAssignments.retrieveList.toString(),
    analyticsForAssignmentsFilter
  );
  yield debounceByKey(
    actions.analyticsForChartSessions.retrieveList.toString(),
    analyticsForChartSessionsFilter
  );
  yield debounceByKey(
    actions.analyticsForChartEvents.retrieveList.toString(),
    analyticsForChartEventsFilter
  );
  yield debounceByKey(
    actions.analyticsForChartEngagement.retrieveList.toString(),
    analyticsForChartEngagementFilter
  );

  yield debounceByKey(
    actions.analyticsForChartMentorship.retrieveList.toString(),
    analyticsForChartMentorshipFilter
  );

  yield debounceByKey(
    actions.analyticsForChartMentorshipFeedback.retrieveList.toString(),
    analyticsForChartMentorshipFeedbackFilter
  );

  yield debounceByKey(
    actions.analyticsForChartEvent.retrieveList.toString(),
    analyticsForChartEventFilter
  );

  yield debounceByKey(
    actions.chartsContentItem.typeCountByTimeInterval,
    getFilterSaga('api_charts:content-item-type-by-time-interval')
  );

  yield debounceByKey(
    actions.chartsContentItem.assignmentCountByTimeInterval,
    getFilterSaga('api_charts:content-item-assignment-count-by-time-interval')
  );

  yield debounceByKey(
    actions.chartsContentItem.countByTimeInterval,
    getFilterSaga('api_charts:content-item-count-by-time-interval')
  );

  yield debounceByKey(
    actions.chartsContentItem.countByFacilitator,
    getFilterSaga('api_charts:content-item-count-by-facilitator')
  );

  yield debounceByKey(
    actions.chartsContentItem.countByTag,
    getFilterSaga('api_charts:content-item-count-by-tag')
  );

  yield debounceByKey(
    actions.chartsContentItem.countByCategory,
    getFilterSaga('api_charts:content-item-count-by-category')
  );

  yield debounceByKey(
    actions.chartsContentItem.countByFlexibleFilter,
    getFilterSaga('api_charts:content-item-count-by-flexible-filter')
  );

  yield debounceByKey(
    actions.chartsContentItem.assignmentStateCountByTimeInterval,
    getFilterSaga('api_charts:content-item-assignment-state-count-by-time-interval')
  );

  yield debounceByKey(
    actions.chartsContentItem.assignmentRatingByTimeInterval,
    getFilterSaga('api_charts:content-item-assignment-rating-by-time-interval')
  );

  yield debounceByKey(
    actions.chartsContentItem.assignmentRatingCountByTimeInterval,
    getFilterSaga('api_charts:content-item-assignment-rating-count-by-time-interval')
  );

  yield debounceByKey(
    actions.chartsContentItem.assignmentCountByFacilitator,
    getFilterSaga('api_charts:content-item-assignment-count-by-facilitator')
  );

  yield debounceByKey(
    actions.chartsContentItem.assignmentCountByTag,
    getFilterSaga('api_charts:content-item-assignment-count-by-tag')
  );

  yield debounceByKey(
    actions.analyticsForChartEnrollmentCountHours.retrieveList.toString(),
    analyticsForChartEnrollmentCountHoursFilter
  );

  yield debounceByKey(
    actions.analyticsForChartEnrollmentRatings.retrieveList.toString(),
    analyticsForChartEnrollmentRatingsFilter
  );

  yield debounceByKey(
    actions.analyticsForChartEnrollmentStatus.retrieveList.toString(),
    analyticsForChartEnrollmentStatusFilter
  );

  yield debounceByKey(
    actions.analyticsForChartProgramSessionEnrollmentCountHours.retrieveList.toString(),
    analyticsForChartProgramSessionEnrollmentCountHoursFilter
  );

  yield debounceByKey(
    actions.analyticsForChartProgramSessionAttendeeRatings.retrieveList.toString(),
    analyticsForChartProgramSessionAttendeeRatingsFilter
  );

  yield debounceByKey(
    actions.analyticsForChartUserEngagement.retrieveList.toString(),
    analyticsForChartUserEngagementFilter
  );

  yield debounceByKey(
    actions.analyticsForChartMentorshipEnrollmentCountHoursByMentorTitle.retrieveList.toString(),
    analyticsForChartMentorshipEnrollmentCountHoursByMentorTitleFilter
  );

  yield debounceByKey(
    actions.analyticsForChartMentorshipEnrollmentCountHoursByMentorDepartment.retrieveList.toString(),
    analyticsForChartMentorshipEnrollmentCountHoursByMentorDepartmentFilter
  );

  yield debounceByKey(
    actions.analyticsForChartMentorshipEnrollmentCountHoursByMenteeTitle.retrieveList.toString(),
    analyticsForChartMentorshipEnrollmentCountHoursByMenteeTitleFilter
  );

  yield debounceByKey(
    actions.analyticsForChartMentorshipEnrollmentCountHoursByMenteeDepartment.retrieveList.toString(),
    analyticsForChartMentorshipEnrollmentCountHoursByMenteeDepartmentFilter
  );

  yield debounceByKey(
    actions.analyticsForChartProgramSessionEnrollmentCountHoursByHostTitle.retrieveList.toString(),
    analyticsForChartProgramSessionEnrollmentCountHoursByHostTitleFilter
  );

  yield debounceByKey(
    actions.analyticsForChartProgramSessionEnrollmentCountHoursByHostDepartment.retrieveList.toString(),
    analyticsForChartProgramSessionEnrollmentCountHoursByHostDepartmentFilter
  );

  yield debounceByKey(
    actions.analyticsForChartProgramSessionEnrollmentCountHoursByAttendeeTitle.retrieveList.toString(),
    analyticsForChartProgramSessionEnrollmentCountHoursByAttendeeTitleFilter
  );

  yield debounceByKey(
    actions.analyticsForChartProgramSessionEnrollmentCountHoursByAttendeeDepartment.retrieveList.toString(),
    analyticsForChartProgramSessionEnrollmentCountHoursByAttendeeDepartmentFilter
  );

  yield debounceByKey(
    actions.analyticsForChartEventEnrollmentCountHoursByPresenterDepartment.retrieveList.toString(),
    analyticsForChartEventEnrollmentCountHoursByPresenterDepartmentFilter
  );

  yield debounceByKey(
    actions.analyticsForChartEventEnrollmentCountHoursByPresenterTitle.retrieveList.toString(),
    analyticsForChartEventEnrollmentCountHoursByPresenterTitleFilter
  );

  yield debounceByKey(
    actions.analyticsForChartEventEnrollmentCountHoursByEnrolleeDepartment.retrieveList.toString(),
    analyticsForChartEventEnrollmentCountHoursByEnrolleeDepartmentFilter
  );

  yield debounceByKey(
    actions.analyticsForChartEventEnrollmentCountHoursByEnrolleeTitle.retrieveList.toString(),
    analyticsForChartEventEnrollmentCountHoursByEnrolleeTitleFilter
  );

  yield debounceByKey(
    actions.analyticsForChartEventsByAttribute.retrieveList.toString(),
    analyticsForChartEventsByAttributeFilter
  );

  yield debounceByKey(actions.announcements.retrieveList.toString(), announcementsFilter);

  yield debounceByKey(actions.csvImport.createSubmit.toString(), csvImportCreate);
  yield debounceByKey(actions.csvImport.retrieveDetails.toString(), csvImportRetrieve);
  yield debounceByKey(actions.csvImport.updateSubmit.toString(), csvImportUpdate);

  yield debounceByKey(actions.customer.updateSubmit.toString(), customerUpdate);
  yield debounceByKey(actions.channel.retrieveList.toString(), retrieveChannels);
}

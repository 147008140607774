import React from 'react';
import * as Sentry from '@sentry/react';

const defaultErrorMessage =
  'Our team has been notified, but feel free to contact us at support@plusplus.co';

type ErrorFallbackProps = {
  message?: string;
};

function ErrorFallback(props: ErrorFallbackProps) {
  const { message = defaultErrorMessage } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>We are sorry — something has gone wrong.</h2>
      <p>{message}</p>
    </div>
  );
}

type ErrorBoundaryProps = {
  errorMessage?: string;
  sentryExtras?: Record<string, unknown>;
  children: React.ReactNode;
};

export default function ErrorBoundary(props: ErrorBoundaryProps) {
  const { errorMessage, sentryExtras, children } = props;

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorFallback message={errorMessage} />}
      beforeCapture={(scope, _error, componentStack) => {
        scope.setExtra('errorInfo', componentStack);

        if (sentryExtras) {
          scope.setExtras(sentryExtras);
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

import * as Sentry from '@sentry/react';
import { get } from 'lodash-es';

export function setupSentry(history: History) {
  const sentryDsn = window.SENTRY_REACT_DSN;

  if (!sentryDsn || sentryDsn === '') {
    return;
  }

  Sentry.init({
    dsn: sentryDsn,
    release: window.SENTRY_RELEASE ? `frontend@${window.SENTRY_RELEASE}` : undefined,
    environment: window.ENVIRONMENT_NAME,
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    tracesSampleRate: 0.5,
  });
}

export function setupUser(user: any) {
  Sentry.setUser({
    id: get(user, 'id'),
    username: get(user, 'display_name'),
    email: get(user, 'email'),
  });

  const tenant = get(user, 'customer.name');
  Sentry.setTag('tenant', tenant);
}

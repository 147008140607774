import moment from 'moment-timezone';
import { Suspense, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { API_DATE_FORMAT } from '~/app/shared/constants';
import ViewAllContent from '~/app/catalog/components/ViewAllContent';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import CardListWrapper from '~/app/content-items/components/CardListWrapper';
import { useFetchDataListPaginated } from '~/app/data-fetching/hooks';
import EventViewDensity from '~/app/events/components/EventViewDensity';
import {
  parseEventToCalendarEvents,
  parseSessionToCalendarEvent,
} from '~/features/calendar/helpers';
import {
  useMyUpcomingFiltersDefinition,
  useMyUpcomingPillsDefinition,
} from '~/features/my_upcoming/hooks';
import { useDeleteSessionFromCache } from '~/features/sessions/helpers';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import { useRQLFilters } from '~/app/filters/hooks';
import { excludeFieldFromRQLExpression } from '~/app/filters/utils';
import FilterPills from '~/app/navigation/components/FilterPills';
import SessionModalManager, {
  useSessionModalManagerState,
} from '~/app/program/components/SessionModalManager';
import { useSessionEnrollmentManager } from '~/app/program/services';
import { queries } from '~/queries';
import { safeMapRoute } from '~/services/requests';
import { lazyWithRetry } from '~/services/utils';
import CardList from '~/app/shared-cards/components/CardList';
import { OptimalContentItemCard } from '~/app/shared/components/Card';
import Loading from '~/app/shared/components/Loading';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { useCurrentUser } from '~/app/shared/hooks';
import { get, map, flatMap, isEmpty } from 'lodash-es';
import { grey } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { styled } from '@mui/material';
import { ErrorBoundary } from '~/app/shared';

const LazyCalendar = lazyWithRetry(
  () => import(/* webpackChunkName: "LazyCalendar" */ '~/features/calendar/components/Calendar')
);

const NoFoundMessage = styled('div')`
  margin: 20px 0;
  text-align: center;
  font-size: 25px;
`;

const CalendarContainer = styled('div')`
  padding: 20px;
  background-color: white;
  height: 750px;
`;

const MyUpcoming = () => {
  const currentUser = useCurrentUser();
  const userTimezone = get(currentUser, 'timezone');
  const history = useHistory();
  const [session, setSession] = useState(null);

  const { modalManagerState, modalManagerSetters, openDetailsModal, openRosterModal } =
    useSessionModalManagerState();
  const { deleteSession } = useDeleteSessionFromCache();
  const { dropSession } = useSessionEnrollmentManager(session || undefined, {
    onDelete: () => deleteSession(get(session, 'id', -1)),
    onUnenroll: () => deleteSession(get(session, 'id', -1)),
  });

  // Filters
  const { filters, updateFilter, ordering, setOrdering, resetFilters, removeValue, rqlExpression } =
    useRQLFilters({
      initialFiltersState: { view_density: { $eq: 'cozy' } },
      initialOrdering: 'start_time',
    });

  // Page configuration
  const viewDensity = get(filters, 'view_density.$eq', 'cozy') as string;

  // Data fetching
  const searchRQLExpression = excludeFieldFromRQLExpression(rqlExpression, 'view_density');
  const {
    data: items,
    status: fetchStatus,
    count,
    isLoading,
  } = useFetchDataListPaginated({
    ...queries.my_upcoming.list(searchRQLExpression),
    enabled: !isEmpty(searchRQLExpression),
  });

  // Filters definition
  const { filters: barFilters, moreFilters } = useMyUpcomingFiltersDefinition({
    filters,
    ordering,
    updateFilter,
    setOrdering,
  });

  // Pills definition
  const { pills } = useMyUpcomingPillsDefinition({ filters });

  return (
    <>
      <PageTitle title="My Upcoming" />
      <CardListWrapper>
        <Typography variant="h4" sx={{ color: grey['900'] }} fontWeight={500}>
          My Upcoming
        </Typography>
        <RQLFilterBar filters={barFilters} moreFilters={moreFilters} onClearAll={resetFilters}>
          <EventViewDensity
            key="view-density"
            value={viewDensity}
            onChange={(newViewDensity) => updateFilter({ view_density: { $eq: newViewDensity } })}
          />
        </RQLFilterBar>
        <FilterPills
          pills={pills}
          onRemove={(item) => removeValue(get(item, 'filterName', ''), get(item, 'value', ''))}
        />
        {isLoading && viewDensity === 'cozy' && <Loading />}

        {!isLoading && count === 0 && viewDensity === 'cozy' && (
          <NoFoundMessage>No content found for the selected filters.</NoFoundMessage>
        )}
        {viewDensity === 'cozy' && (
          <ViewAllContent fetchStatus={fetchStatus} totalResults={count}>
            <CardList
              items={items}
              renderItem={(item) => (
                <OptimalContentItemCard
                  key={`${item.content_type}_${item.id}`}
                  contentItem={item}
                />
              )}
            />
          </ViewAllContent>
        )}
        {viewDensity === 'calendar' && (
          <CalendarContainer>
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <LazyCalendar
                  onSelect={(item: any) => item.onSelect?.()}
                  onChange={(startDate: moment.Moment, endDate: moment.Moment) =>
                    updateFilter({
                      start_time: {
                        $range: {
                          min: startDate.format(API_DATE_FORMAT),
                          max: endDate.format(API_DATE_FORMAT),
                        },
                      },
                    })
                  }
                  items={flatMap(
                    map(items, (item) =>
                      item.content_type === CONTENT_TYPES.event
                        ? map(
                            parseEventToCalendarEvents({
                              event: item,
                              userTimezone,
                            }),
                            (calendarEvent) => ({
                              ...calendarEvent,
                              onSelect: () => {
                                history.push(
                                  safeMapRoute('eventDetails', {
                                    public_id_and_slug: item.public_id_and_slug,
                                  })
                                );
                              },
                            })
                          )
                        : {
                            ...parseSessionToCalendarEvent({ session: item, userTimezone }),
                            onSelect: () => {
                              setSession(item);
                              const openModal =
                                get(item, 'attendance_limit', 0) === 1
                                  ? openDetailsModal
                                  : openRosterModal;
                              openModal();
                            },
                          }
                    )
                  )}
                />
              </Suspense>
            </ErrorBoundary>
          </CalendarContainer>
        )}
      </CardListWrapper>
      {session && (
        <SessionModalManager
          session={session}
          state={modalManagerState}
          setters={modalManagerSetters}
          handleDropSession={dropSession}
        />
      )}
    </>
  );
};

export default MyUpcoming;

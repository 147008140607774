import PropTypes from 'prop-types';
import React from 'react';

import ButtonLink from '~/app/shared/components/ButtonLink';
import SplitButton from '~/app/shared/components/SplitButton';

import FormTopBar from './FormTopBar';

const CreateFormTopBar = ({
  action,
  title,
  backRoute,
  onSaveAndDuplicateHandler,
  onSaveAndBulkDuplicateHandler,
  loading,
  disableSave,
}) => (
  <FormTopBar action={action} title={title} loading={loading}>
    <ButtonLink variant="error" size="medium" route={backRoute}>
      Discard
    </ButtonLink>
    <SplitButton
      label="Publish"
      type="submit"
      disabled={disableSave}
      secondaryActionOptionList={[
        {
          label: 'Publish & Duplicate',
          onClick: onSaveAndDuplicateHandler,
          hidden: !onSaveAndDuplicateHandler,
        },
        {
          label: 'Publish & Bulk Duplicate',
          onClick: onSaveAndBulkDuplicateHandler,
          hidden: !onSaveAndBulkDuplicateHandler,
        },
      ]}
      data-cy="publish"
    />
  </FormTopBar>
);

CreateFormTopBar.propTypes = {
  action: PropTypes.string,
  title: PropTypes.string,
  backRoute: PropTypes.string,
  disableSave: PropTypes.bool,
  onSaveAndDuplicateHandler: PropTypes.func,
  onSaveAndBulkDuplicateHandler: PropTypes.func,
  loading: PropTypes.bool,
};

export default CreateFormTopBar;

import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import PropTypes from 'prop-types';
import React from 'react';

const StaticDateRangeInput = (props) => {
  return <StaticDateRangePicker {...props} slots={{ field: null }} />;
};

StaticDateRangeInput.defaultProps = {
  showToolbar: false,
};

StaticDateRangeInput.propTypes = {
  showToolbar: PropTypes.bool,
};

export default StaticDateRangeInput;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import TextField from '~/app/inputs/components/TextField';
import Button from '~/app/shared/components/Button';
import { required, url } from '~/app/shared/formValidations';
import { isEmpty, parseInt, get } from 'lodash-es';

const fieldRequired = required();

const FieldGroupWrapper = styled.div`
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const TextFieldWrapper = styled.div`
  width: 100%;
`;

const LinkFooter = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
`;

// This validate will only set link or name as required if the another is not empty.
// This is for ensure we validate the link resource only when the user is adding information.
const validateEmptyState = (value, allValues, props, name) => {
  if (isEmpty(allValues)) return;
  if (isEmpty(allValues.resources)) return;
  const index = parseInt(name.slice(name.indexOf('[') + 1, name.indexOf(']')));
  const resource = get(allValues.resources, index);
  if (resource && (resource.link || resource.name)) {
    return fieldRequired(value, allValues, props, name);
  }
};

// Any new field need to be added to the function parseResources in app/content-resources/services.js
const ResourceLinkBoxContent = ({ resource, handleOnRemove, index }) => {
  return (
    <FieldGroupWrapper>
      <InputWrapper>
        <TextFieldWrapper>
          <Field
            label="Label"
            required
            name={`${resource}.name`}
            component={TextField}
            validate={[validateEmptyState]}
            maxLength="255"
            data-cy={`linkLabel_${index}`}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <Field
            label="URL"
            required
            name={`${resource}.link`}
            component={TextField}
            validate={[validateEmptyState, url]}
            maxLength="2048"
            data-cy={`linkURL_${index}`}
          />
        </TextFieldWrapper>
      </InputWrapper>
      <LinkFooter>
        <Button size="medium" type="button" onClick={handleOnRemove} variant="error">
          Remove Link
        </Button>
      </LinkFooter>
    </FieldGroupWrapper>
  );
};

ResourceLinkBoxContent.propTypes = {
  resource: PropTypes.string,
  handleOnRemove: PropTypes.func,
  index: PropTypes.number,
};

export default ResourceLinkBoxContent;

import { useState } from 'react';

import {
  CONTENT_TYPES,
  LEARNING_TYPE_ICONS_MAPPING,
  LEARNING_TYPES,
} from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import { useLabels } from '~/app/shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { ConvertToTaskModal } from '~/app/task/components/TaskConverterModal';
import { includes, get } from 'lodash-es';
import { useArchiveArticle, useUnarchiveArticle } from '../../api/mutations';

const ArticleContextMenu = ({ content, contextMenuExtraProps }: ContentItemContextMenu) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showConvertToTaskModal, setShowConvertToTaskModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const { label_task: labelTask } = useLabels();

  const { isTrackItem } = contextMenuExtraProps;
  const { public_id: publicId, public_id_and_slug: publicIdAndSlug, permissions } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));

  const archiveArticle = useArchiveArticle(content);
  const unarchiveArticle = useUnarchiveArticle(content);

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage) {
    return null;
  }

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        <>
          <DropDownMenu.Item
            route={contentTypeRoutes[CONTENT_TYPES.article].edit({
              public_id: publicId,
            })}
            title="Edit"
            icon="pencil"
            data-cy="edit"
          />
          {isTrackItem && (
            <DropDownMenu.Item
              onClick={() => setShowConvertToTaskModal(true)}
              title={`Convert to ${labelTask}`}
              icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.tasks]}
            />
          )}
          {canAssign && (
            <DropDownMenu.Item
              route={mapRoute('contentItemManageAssignments', {
                public_id_and_slug: publicIdAndSlug,
              })}
              title="View Roster"
              icon="group"
            />
          )}
          <DropDownMenu.Item
            onClick={() => setShowSurveyModal(true)}
            title="Manage Surveys"
            icon="survey"
          />
          {!isArchived && (
            <DropDownMenu.Item
              onClick={archiveArticle.mutateAsync}
              title="Archive"
              icon="archive"
              data-cy="archive"
            />
          )}
          {isArchived && (
            <>
              <DropDownMenu.Item
                onClick={unarchiveArticle.mutateAsync}
                title="Unarchive"
                icon="unarchive"
                data-cy="unarchive"
              />
              <DropDownMenu.Item
                onClick={() => setShowDeleteModal(true)}
                title="Delete"
                icon="delete"
                color={colors.error600}
                textColor={colors.error600}
                data-cy="delete"
              />
            </>
          )}
          {canAssign && (
            <DropDownMenu.Item onClick={() => setShowLogModal(true)} title="View Logs" icon="log" />
          )}
        </>
      </DropDownMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showConvertToTaskModal && (
        <ConvertToTaskModal
          content={content}
          handleClose={() => setShowConvertToTaskModal(false)}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default ArticleContextMenu;

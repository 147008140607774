import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers-pro';

import OldFieldError from '~/app/inputs/components/OldFieldError';
import TimeInput, { INVALID_TIME_MESSAGE } from '~/app/inputs/components/TimeInput';
import { TIME_FORMAT, DATE_DISPLAY_FORMAT } from '~/app/shared/constants';
import { isEmpty, isNil } from 'lodash-es';

const WrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ isNewForm }) => isNewForm && 'margin: 0 -8px;'}
  > * {
    ${({ isNewForm }) => isNewForm && 'padding: 0 8px;'}
  }
`;

const LeftFieldContainer = styled.div`
  flex: ${({ leftWidth }) => (leftWidth ? `0 0 ${leftWidth}` : '0 0 50%')};
`;

const RightFieldContainer = styled.div`
  flex: ${({ rightWidth }) => (rightWidth ? `0 0 ${rightWidth}` : '0 0 50%')};
`;

const DateTimeField = (props) => {
  const { id, input, meta, rightWidth, leftWidth, isNewForm, freeSolo } = props;
  const { error, touched } = meta;

  const [timeTouched, setTimeTouched] = useState(false);
  const [timeError, setTimeError] = useState();

  const valueDate = moment(input.value).utcOffset(0);
  const date = valueDate.clone();
  const time = valueDate.clone();

  const handleChangeDateTime = (date, time) => {
    const newDateTime = valueDate
      .clone()
      .year(date.year())
      .month(date.month())
      .date(date.date())
      .hour(time.hour())
      .minute(time.minute());

    input.onChange(newDateTime.toISOString());
    input.onBlur();
  };

  const handleDateChange = (value) => {
    if (!value.isValid()) {
      input.onBlur();
      input.onChange(null);
      return;
    }

    handleChangeDateTime(value, time);
  };

  const handleTimeChange = (value) => {
    if (isEmpty(value)) {
      setTimeError('This field is required.');
      return;
    }

    setTimeTouched(true);

    const momentTime = moment(value, TIME_FORMAT, true);
    const isValid = momentTime.isValid();

    if (!isValid) {
      setTimeError(INVALID_TIME_MESSAGE);
      return;
    }

    setTimeError(null);
    handleChangeDateTime(date, momentTime);
  };

  return (
    <WrapperContainer isNewForm={isNewForm}>
      <LeftFieldContainer leftWidth={leftWidth}>
        <DatePicker
          slotProps={{
            textField: {
              id,
              name: input.name,
              placeholder: DATE_DISPLAY_FORMAT,
            },
          }}
          label={isNewForm ? 'Date' : 'Date*'}
          value={date}
          onChange={handleDateChange}
          error={isNil(date) && touched && error}
          showCalendarIcon
        />
        {touched && error && <OldFieldError>{error}</OldFieldError>}
      </LeftFieldContainer>

      <RightFieldContainer rightWidth={rightWidth}>
        <TimeInput
          inputId="time"
          label={isNewForm ? 'Time' : 'Time*'}
          currentValue={time}
          searchPlaceholder="Select"
          handleTimeChange={handleTimeChange}
          error={Boolean(timeTouched && timeError)}
          freeSolo={freeSolo}
          creatable={freeSolo}
        />
        {timeTouched && timeError && <OldFieldError>{timeError}</OldFieldError>}
      </RightFieldContainer>
    </WrapperContainer>
  );
};

DateTimeField.propTypes = {
  id: PropTypes.any,
  input: PropTypes.object,
  meta: PropTypes.object,
  rightWidth: PropTypes.string,
  leftWidth: PropTypes.string,
  isNewForm: PropTypes.bool,
  freeSolo: PropTypes.bool,
};

DateTimeField.defaultProps = {
  freeSolo: false,
};

export default DateTimeField;

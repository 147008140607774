import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { userProfileSchema } from '~/app/entities/schema';
import { IdParam } from '~/common/types';
import actions from '~/app/entities/actions';
import { getDataFromState, useEntities } from '~/app/entities/utils';
import Datetime from '~/app/shared/components/Datetime';
import Loading from '~/app/shared/components/Loading';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { useDataGridPaginationAndSorting } from '~/app/shared/hooks';
import { includes, map } from 'lodash-es';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';
import { ActivityRecord } from './ActivityRecord';

export const RecentActivityWidget = () => {
  const { id: userId } = useParams<IdParam>();

  const { pageNumber, rowsPerPage, rowsPerPageOptions, handleChangePage, handleChangeRowsPerPage } =
    useDataGridPaginationAndSorting();

  const [fetch, { data, status: requestStatus }] = useEntities(
    actions.userData.retrieveRecentActivityList,
    null
  );

  const { status: userStatus, data: user } = useSelector(
    (state) => getDataFromState(`userProfile${userId}`, state, userProfileSchema), // eslint-disable-line prettier/prettier
  );

  const recentEventsList = map(data?.results || [], (entry) => ({
    id: `${entry.content_item.public_id_and_slug}-${entry.action}`,
    ...entry,
  }));

  useEffect(() => {
    if (userStatus === STATUS_DONE) {
      fetch(user.id);
    }
  }, [userId, userStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = includes([STATUS_LOADING], requestStatus);
  const hasRecentActivity =
    requestStatus === STATUS_DONE && recentEventsList && recentEventsList.length > 0;

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Recent activity
      </Typography>
      {isLoading && <Loading />}
      {!isLoading && !hasRecentActivity && (
        <Typography variant="body1"> No recent activity for {user.name}</Typography>
      )}
      {!isLoading && hasRecentActivity && (
        <DataGrid
          autoHeight
          sx={{ border: 'none' }}
          pagination
          paginationModel={{
            page: pageNumber,
            pageSize: rowsPerPage,
          }}
          onPaginationModelChange={(model) => {
            handleChangePage(model.page);
            handleChangeRowsPerPage(model.pageSize);
          }}
          pageSizeOptions={rowsPerPageOptions}
          rows={recentEventsList}
          columns={[
            {
              field: 'timestamp',
              headerName: 'Date/Time (GMT-3)',
              flex: 1,
              filterable: false,
              sortable: true,
              editable: false,
              renderCell: ({ row }) => {
                const date = row.timestamp;

                return <Datetime datetime={date} />;
              },
            },
            {
              field: 'activity',
              headerName: 'Activity',
              flex: 4,
              filterable: false,
              sortable: false,
              editable: false,
              renderCell: ({ row }) => {
                return <ActivityRecord {...row} />;
              },
            },
          ]}
        />
      )}
    </Box>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useEnrollmentsActions } from '~/app/enrollments/hooks';
import { STATUS_DONE } from '~/app/shared/constants';
import Dashboard from '~/app/backoffice/components/Dashboard';
import {
  ATTENDANCE_METHODS,
  ATTENDANCE_STATUSES,
  ENROLLMENT_VIEW_MODES,
} from '~/app/enrollments/constants';
import actions from '~/app/entities/actions';
import { enrollmentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { displayDate } from '~/services/datetime';
import { noop, map, get } from 'lodash-es';
import {
  DataGridPro as DataGrid,
  GridColDef,
  GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import rql from '~/vendor/rql';

import EnrollmentsActions from './EnrollmentsActions';

interface EnrolmmentsQueueProps {
  eventId: number;
  maxOrder: number;
  attendanceMethod: ATTENDANCE_METHODS;
  attendanceStatuses: ATTENDANCE_STATUSES[];
}

const PAGE_SIZE = 10;

const EnrolmmentsQueue = ({
  eventId,
  maxOrder,
  attendanceMethod,
  attendanceStatuses,
}: EnrolmmentsQueueProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [
    fetchEnrollments,
    { data: enrollments, count: enrollmentsCount, status: enrollmentsStatus },
  ] = useEntities(actions.enrollment.retrieveList, noop, { schema: [enrollmentSchema] });

  const loadEnrollments = useCallback(() => {
    fetchEnrollments(
      rql({
        event: eventId,
        attendance_method: attendanceMethod,
        status: { $in: attendanceStatuses },
        $ordering: 'order',
        view_mode: ENROLLMENT_VIEW_MODES.roster,
        page: currentPage + 1,
        page_size: currentPageSize,
      })
    );
  }, [
    attendanceMethod,
    attendanceStatuses,
    currentPage,
    currentPageSize,
    eventId,
    fetchEnrollments,
  ]);

  const { updateOrder, isLoading } = useEnrollmentsActions({ postActionCallback: loadEnrollments });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'virtual_order',
        headerName: 'Queue Position',
        align: 'right',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 0.7,
      },
      {
        field: 'user_name',
        headerName: 'Person',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1.5,
        renderCell: ({ row: enrollment }) => (
          <Dashboard.UserCell data={enrollment.user} showAvatar highlighted />
        ),
      },
      {
        field: 'user_email',
        headerName: 'Email',
        filterable: false,
        sortable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row: enrollment }) => get(enrollment, 'user.email', ''),
      },
      {
        field: 'user_title',
        headerName: 'Title',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1.5,
        renderCell: ({ row: enrollment }) => get(enrollment, 'user.title', ''),
      },
      {
        field: 'user_department',
        headerName: 'Department',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => get(enrollment, 'user.department', ''),
      },
      {
        field: 'user_working_since',
        headerName: 'Hire date',
        filterable: false,
        sortable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row: enrollment }) => {
          const workingSince = get(enrollment, 'user.working_since');
          return workingSince ? displayDate(workingSince) : '';
        },
      },
      {
        field: 'user_location',
        headerName: 'Location',
        filterable: false,
        sortable: false,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: ({ row: enrollment }) => get(enrollment, 'user.location_text', ''),
      },
      {
        field: 'created',
        headerName: 'Created',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => {
          const { created_at: createdAt } = enrollment;
          return createdAt ? displayDate(createdAt) : '';
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 20,
        sortable: false,
        editable: false,
        getActions: (props) =>
          EnrollmentsActions({
            ...props,
            showOrderActions: true,
            maxOrder,
            viewMode: ENROLLMENT_VIEW_MODES.roster,
            postActionCallback: loadEnrollments,
          }),
      },
    ],
    [loadEnrollments, maxOrder]
  );

  useEffect(() => {
    loadEnrollments();
  }, [loadEnrollments]);

  const queueTable = useMemo(() => {
    const onRowOrderChange = (params: GridRowOrderChangeParams) => {
      const { row: enrollment, oldIndex, targetIndex } = params;
      if (oldIndex !== targetIndex) {
        updateOrder({ overrideId: enrollment.id, order: enrollments[targetIndex].order });
      }
    };

    return (
      <DataGrid
        sx={{
          // These css's are specifically for hiding the column separator
          // that is added to the last column item automatically (and also the
          // second-to-last one, as we count the actions as a column)
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader:nth-last-of-type(2) .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          // One of the project scss files add a margin bottom to <p> elements, so we
          // must manually remove this margin by customizing the class in the pagination
          '& p.MuiTablePagination-displayedRows': {
            margin: 0,
            fontWeight: 500,
          },
        }}
        slotProps={{
          panel: {
            sx: {
              // This update the padding in the filter popup
              '& .MuiDataGrid-filterForm': {
                padding: 2,
              },
            },
          },
        }}
        columns={columns}
        rows={map(enrollments, (item, index) => ({
          ...item,
          virtual_order: PAGE_SIZE * currentPage + index + 1,
          __reorder__: get(item, 'user.name', item.id), // show the name instead of the id when reordering a row
        }))}
        rowCount={enrollmentsCount}
        rowReordering
        onRowOrderChange={onRowOrderChange}
        pagination
        paginationMode="server"
        paginationModel={{
          page: currentPage,
          pageSize: currentPageSize,
        }}
        onPaginationModelChange={(model) => {
          setCurrentPage(model.page);
          setCurrentPageSize(model.pageSize);
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        loading={enrollmentsStatus !== STATUS_DONE || isLoading}
        disableRowSelectionOnClick
      />
    );
  }, [
    columns,
    currentPage,
    currentPageSize,
    enrollments,
    enrollmentsCount,
    enrollmentsStatus,
    updateOrder,
    setCurrentPage,
    setCurrentPageSize,
    isLoading,
  ]);
  return queueTable;
};

export default EnrolmmentsQueue;

import { ContentItemCardLearningType } from '~/common/components/ContentItemCard/ContentItemCardLearningType';
import Icon from '~/app/shared/components/Icon';
import { Typography } from '@mui/material';
import { useLearningTypeCardVariant } from '~/common/hooks/useLearningTypeCardVariant';

type DefaultContentItemLearningTypeProps = {
  learningType: string;
};

export function DefaultContentItemLearningType(props: DefaultContentItemLearningTypeProps) {
  const { learningType } = props;

  const {
    colors: learningTypeColors,
    icon: learningTypeIcon,
    label: learningTypeLabel,
  } = useLearningTypeCardVariant(learningType);

  return (
    <ContentItemCardLearningType
      icon={
        <Icon name={learningTypeIcon} width={14} height={14} color={learningTypeColors.color700} />
      }
      sx={{
        backgroundColor: learningTypeColors.color200,
      }}
    >
      <Typography variant="body2" component="span" noWrap color={learningTypeColors.color700}>
        {learningTypeLabel}
      </Typography>
    </ContentItemCardLearningType>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { ActionFunction } from 'redux-actions';

import { useEntities } from '~/app/entities/utils';
import DynamicTable from '~/app/shared/components/DynamicTable';
import Loading from '~/app/shared/components/Loading';
import {
  DynamicFilter,
  DynamicFilterBackend,
  FetchFilterOptions,
  RosterAction,
} from '~/app/shared/components/types';
import { STATUS_DONE } from '~/app/shared/constants';
import {
  GridCellParams,
  GridColumnVisibilityModel,
  GridColDef,
  GridPinnedColumnFields,
} from '@mui/x-data-grid-pro';
import { TableSelectionMode } from '../constants';

interface ItemPickerProps {
  fetchAction: ActionFunction<any>;
  fetchActionOptions?: string;
  schema: object;
  filtersAction: ActionFunction<any>;
  fetchFiltersOptions?: FetchFilterOptions;
  defaultOrdering: string;
  columns: GridColDef[];
  pageSize?: number;
  rowsPerPageOptions?: number[];
  actions?: RosterAction[];
  topBarActions?: (RosterAction | RosterAction[])[];
  backend?: DynamicFilterBackend;
  filterBarInputWidth?: string;
  disabledItemsIds?: string[] | number[];
  initialFiltersConfig?: Record<string, any>[];
  transformFilters?: (dynamicFilters: DynamicFilter[]) => DynamicFilter[]; // In some use cases, it is necessary to customize the filters.
  isCellEditable?: (params: GridCellParams) => boolean;
  processRowUpdate?: (
    newRow: Record<string, any>,
    oldRow: Record<string, any>
  ) => Promise<Record<string, any>> | Record<string, any>; // Using any here, because it is the ro
  onProcessRowUpdateError?: (error: any) => void; // This is the same type of the DataGridProps
  disableColumnMenu?: boolean;
  enableSelectAll?: boolean;
  selectionMode?: TableSelectionMode;
  cacheKey?: string;
  pinnedColumns?: GridPinnedColumnFields;
  columnVisibilityModel?: GridColumnVisibilityModel;
  HCURL?: string;
}

const ItemPicker = ({
  fetchAction,
  fetchActionOptions = '',
  schema,
  filtersAction,
  fetchFiltersOptions = {},
  defaultOrdering,
  columns,
  pageSize = 5,
  rowsPerPageOptions = [5, 15, 25, 50],
  actions = [],
  topBarActions = [],
  filterBarInputWidth = '192px',
  disabledItemsIds,
  initialFiltersConfig,
  transformFilters = (dynamicFilters: DynamicFilter[]): DynamicFilter[] => dynamicFilters,
  isCellEditable,
  processRowUpdate,
  onProcessRowUpdateError,
  disableColumnMenu = false,
  enableSelectAll = false,
  selectionMode = TableSelectionMode.single,
  cacheKey = '',
  backend = 'state',
  pinnedColumns,
  columnVisibilityModel,
  HCURL,
}: ItemPickerProps): React.ReactElement => {
  const [fetchFilters, { data: dynamicFilters, status: fetchFiltersStatus }] = useEntities(
    filtersAction,
    null
  );

  const [hasUpdatedInitialFilters, setHasUpdatedInitialFilters] = useState(false);

  const loadFilters = useCallback(() => {
    if (!hasUpdatedInitialFilters) {
      fetchFilters(fetchFiltersOptions);
      setHasUpdatedInitialFilters(true);
    }
  }, [fetchFilters, fetchFiltersOptions, hasUpdatedInitialFilters]);

  useEffect(() => {
    loadFilters();
  }, [loadFilters]);

  if (fetchFiltersStatus !== STATUS_DONE) return <Loading />;

  return (
    <DynamicTable
      initialFiltersConfig={initialFiltersConfig}
      fetchAction={fetchAction}
      fetchActionOptions={fetchActionOptions}
      schema={schema}
      dynamicFilters={transformFilters(dynamicFilters)}
      defaultOrdering={defaultOrdering}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      actions={actions}
      topBarActions={topBarActions}
      backend={backend}
      filterBarInputWidth={filterBarInputWidth}
      disabledItemsIds={disabledItemsIds}
      isCellEditable={isCellEditable}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={onProcessRowUpdateError}
      disableColumnMenu={disableColumnMenu}
      enableSelectAll={enableSelectAll}
      selectionMode={selectionMode}
      cacheKey={cacheKey}
      pinnedColumns={pinnedColumns}
      columnVisibilityModel={columnVisibilityModel}
      HCURL={HCURL}
    />
  );
};

export default ItemPicker;

import { useMemo } from 'react';

import colors from '~/services/colors';
import Modal, { ModalBody, ModalFooterButton } from '~/app/shared/components/Modal';
import { ActionCallbackProps, User } from '~/app/shared/components/types';
import { get, size, map, head, isEmpty, toUpper, slice, filter, isNil } from 'lodash-es';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { Avatar } from '~/common/components/Avatar';
import { Typography } from '~/common/components/Typography';
import { AvatarGroup, Box, Divider, styled } from '@mui/material';
import { LoadingButton } from '~/vendor/mui-lab';

interface BulkErrorMessage {
  message: string;
  user_name: string;
}

interface BulkParticipantFormModalProps {
  title: string;
  actionLabel: string;
  hideActions?: boolean;
  selectedData: ActionCallbackProps | null;
  handleAction: () => void;
  isLoading: boolean;
  errors?: BulkErrorMessage[];
  handleClose: () => void;
  handleBack: () => void;
}

const Footer = styled('footer')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 8px;
  padding: 8px;
  height: 60px;
`;

const getUsersNames = (users: User[], total: number): string => {
  const names = filter(
    map(users, (user) => get(user, 'name')),
    (name) => !isEmpty(name)
  );
  if (size(names) === 0) return '';
  if (size(names) === 1) return names[0];
  if (size(names) === 2) return `${names[0]} and ${names[1]}`;
  if (size(names) === 3) return `${names[0]}, ${names[1]}, and ${names[2]}`;
  return `${names[0]}, ${names[1]}, ${names[2]} and ${total - 3} others`;
};

const ERROR_PAGE_SIZE = 5;

const columns = [
  { field: 'user_name', headerName: 'User', flex: 2 },
  { field: 'message', headerName: 'Reason', flex: 1 },
];

const BulkParticipantFormModal = ({
  title,
  actionLabel,
  hideActions = false,
  selectedData,
  handleAction,
  isLoading,
  errors,
  handleClose,
  handleBack,
}: BulkParticipantFormModalProps) => {
  const selectedItems = get(selectedData, 'selectedItems');
  const selectAll = get(selectedData, 'selectAll', false);
  // In the create context, the item will be the user itself, in the other contexts (update, drop, etc) the item will be the assignment.
  const [users, total] = useMemo(() => {
    return size(selectedItems) > 0 && !selectAll
      ? [map(selectedItems, (item) => get(item, 'user', item)), size(selectedItems)]
      : [
          map(get(selectedData, 'rows'), (item) => get(item, 'user', item)),
          get(selectedData, 'rowCount', 0),
        ];
  }, [selectedItems, selectAll, selectedData]);

  if (isNil(selectedData)) return null;

  return (
    <Modal
      title={title}
      width={640}
      minBodyHeight={240}
      handleClose={handleClose}
      handleBack={handleBack}
    >
      <ModalBody>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            minHeight: '140px',
            rowGap: 5,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <AvatarGroup>
              {map(slice(users, 0, 3), (user) => (
                <Avatar
                  key={`${get(user, 'id')}`}
                  src={get(user, 'profile_image')}
                  alt={get(user, 'name')}
                  sx={{ width: 24, height: 24 }}
                >
                  {toUpper(head(get(user, 'name', '')))}
                </Avatar>
              ))}
            </AvatarGroup>
            <Typography variant="caption">{getUsersNames(users ?? [], total ?? 0)}</Typography>
          </Box>
          {errors && size(errors) > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 1,
                minHeight: '200px',
                width: '100%',
              }}
            >
              <Typography>Oops. We ran into some issues performing this action:</Typography>
              <DataGrid
                columns={columns}
                rows={errors}
                density="compact"
                pagination
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: ERROR_PAGE_SIZE,
                      page: 0,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
                autoHeight
                disableRowSelectionOnClick
              />
            </Box>
          )}
        </Box>
      </ModalBody>
      <Divider />
      <Footer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <Typography variant="subtitle2">
            {isLoading && 'You can close this dialog now.'}
          </Typography>
          {!hideActions && (
            <Box>
              <ModalFooterButton
                variant="text"
                sx={{ color: colors.neutral600 }}
                disabled={isLoading}
                onClick={handleBack}
              >
                Cancel
              </ModalFooterButton>
              <LoadingButton
                variant="outlined"
                size="small"
                loading={isLoading}
                loadingPosition="end"
                disabled={isLoading || size(errors) > 0}
                onClick={handleAction}
                sx={{ pr: isLoading ? 4 : 1 }}
              >
                {isLoading ? 'Running' : actionLabel}
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Footer>
    </Modal>
  );
};

export default BulkParticipantFormModal;

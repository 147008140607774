import { useCallback, useMemo, useState } from 'react';

import AssignmentStateChip from '~/app/assignments/components/AssignmntStateChip';
import {
  ASSIGNMENT_ACTIONS,
  ASSIGNMENT_ACTIONS_ICONS,
  ASSIGNMENT_ACTIONS_LABELS,
  ASSIGNMENT_REASONS,
  ASSIGNMENT_ROSTER_ACTIONS_REASONS,
  ASSIGNMENT_STATES,
} from '~/app/assignments/constants';
import { useAssignmentsBulkActions } from '~/app/assignments/hooks';
import { ListAssignmentComponentProps } from '~/app/assignments/interfaces';
import Dashboard from '~/app/backoffice/components/Dashboard';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useGetContentTypeLabel } from '~/app/catalog/hooks';
import { CONTENT_ITEM_VIEW_MODES } from '~/app/content-items/constants';
import CourseRegistrationResultChip from '~/app/course/components/CourseRegistrationResultChip';
import { CourseRegistration } from '~/app/course/interfaces';
import GridCellExpand from '~/app/datagrid/GridCellExpand';
import actions from '~/app/entities/actions';
import { assignmentSchema, contentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import AssignmentProgressBar from '~/app/rosters/components/AssignmentProgressBar';
import AssignmentsActions from '~/app/rosters/components/AssignmentsRoster/AssignmentsActions';
import BulkAssignmentFormModal from '~/app/rosters/components/AssignmentsRoster/BulkAssignmentFormModal';
import { ASSIGNMENT_FILTERS } from '~/app/rosters/components/AssignmentsRoster/constants';
import SelectAssigneesModal from '~/app/rosters/components/AssignmentsRoster/SelectAssigneesModal';
import { Exporter } from '~/app/rosters/components/AssignmentsRoster/types';
import { useRQLFiltersContext } from '~/app/rosters/RQLFiltersContext';
import {
  createPostActionCallback,
  useCopyEmailsToClipboard,
  useExportCSVActionCallback,
  getNormalizedSelectedData,
} from '~/app/rosters/utils';
import { displayDate, formatSecondsToTime } from '~/services/datetime';
import { getOrdinalNumber } from '~/services/utils';
import { Modal } from '~/app/shared';
import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import { TableSelectionMode } from '~/app/shared/components/constants';
import ItemPicker from '~/app/shared/components/ItemPicker';
import { ActionCallbackProps, User } from '~/app/shared/components/types';
import ScheduledTrackAssignUsersModal from '~/app/tracks/components/ScheduledTrackAssignUsersModal';
import {
  capitalize,
  get,
  includes,
  isNil,
  isNull,
  noop,
  size,
  toInteger,
  toNumber,
} from 'lodash-es';
import { blue, grey, red } from '@mui/material/colors';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';
import { Typography } from '~/common/components/Typography';
import { Box, Button, Chip, Paper, Tooltip, styled } from '@mui/material';
import {
  AddCircleOutlineIcon,
  AddIcon,
  DownloadIcon,
  ErrorIcon,
  MailIcon,
  CancelIcon,
  CheckCircleOutlineIcon,
} from '~/vendor/mui-icons';
import rql from '~/vendor/rql';

import ListTrackItemAssignments from './ListTrackItemAssignments';

interface BulkAction {
  name: ASSIGNMENT_ACTIONS;
  selectedData: ActionCallbackProps;
  errors: any[];
}

const PAGE_SIZE = 25;
const CACHE_KEY = 'assignments_roster';

const CONTENT_TYPES_WITH_PROGRESS = [
  CONTENT_TYPES.assessment,
  CONTENT_TYPES.codelab,
  CONTENT_TYPES.course,
  CONTENT_TYPES.scheduled_track,
  CONTENT_TYPES.track,
  CONTENT_TYPES.video,
];

const CONTENT_TYPES_WITH_ATTEMPT = [CONTENT_TYPES.assessment];
const CONTENT_TYPES_WITH_SCORE = [CONTENT_TYPES.assessment, CONTENT_TYPES.course];
const CONTENT_TYPES_WITH_PROGRESS_DETAILS = [CONTENT_TYPES.scheduled_track, CONTENT_TYPES.track];

const StyledProgressBar = styled(AssignmentProgressBar)`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TraitChip = ({ text, title }: { text: string; title: string }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '8px', alignItems: 'center' }}>
      <Typography variant="body2">{text}</Typography>
      <Tooltip title={title}>
        <ErrorIcon fontSize="small" sx={{ color: grey[700] }} />
      </Tooltip>
    </Box>
  );
};

interface CustomizedData {
  assignLabel: string;
  assignmentLabel: string;
  hideEdit: boolean;
  exporter: Exporter;
}

const getCustomizedData = (contentItem: ContentItem): CustomizedData => {
  const defaultData: CustomizedData = {
    assignLabel: 'Assign',
    assignmentLabel: 'Assignment',
    hideEdit: false,
    exporter: 'assignment',
  };
  if (contentItem.content_type === CONTENT_TYPES.course) {
    return {
      ...defaultData,
      exporter: 'course_registration',
    };
  }
  if (contentItem.content_type === CONTENT_TYPES.track) {
    return { ...defaultData, exporter: 'track_assignment' };
  }
  if (contentItem.content_type === CONTENT_TYPES.scheduled_track) {
    return {
      assignLabel: 'Enroll',
      assignmentLabel: 'assignment',
      hideEdit: true,
      exporter: 'track_assignment',
    };
  }
  if (contentItem.content_type === CONTENT_TYPES.assessment) {
    return { ...defaultData, exporter: 'assessment_assignment' };
  }
  return defaultData;
};

const ListAssignments = ({ contentItem, HCURL }: ListAssignmentComponentProps) => {
  const defaultOrdering = '-created';
  const getContentTypeLabel = useGetContentTypeLabel();
  const { assignLabel, assignmentLabel, exporter, hideEdit } = getCustomizedData(contentItem);
  const { publishEvent, getCachedExpression } = useRQLFiltersContext();
  const [bulkAction, setBulkAction] = useState<BulkAction | null>(null);
  const [bulkCreateErrors, setBulkCreateErrors] = useState<any[]>();
  const [assignmentMethod, setAssignmentMethod] = useState<ASSIGNMENT_FILTERS | null>(null);
  const [showSelectUsersModal, setShowSelectUsersModal] = useState(false);
  const [showBulkAssignModal, setShowBulkAssignModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [targetUser, setTargetUser] = useState<User | null | undefined>(null);
  const [showTrackItemAssignmentListModal, setShowTrackItemAssignmentListModal] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [selectedUsersData, setSelectedUsersData] = useState<ActionCallbackProps>({
    selectedItems: [],
    expression: '',
    rows: [],
    rowCount: 0,
    selectAll: false,
  });
  const [refreshContent] = useEntities(actions.content.retrieveDetails, null, {
    schema: contentSchema,
  });
  const { total, expression } = getNormalizedSelectedData(get(bulkAction, 'selectedData'));
  const { total: totalUsers, expression: userRql } = getNormalizedSelectedData(
    selectedUsersData,
    'id'
  );
  const defaultFetchItemsOptions = useMemo(
    () => ({
      view_mode: 'roster',
      content_item: contentItem.public_id,
    }),
    [contentItem.public_id]
  );
  const { exportCSVActionCallback } = useExportCSVActionCallback(
    'api_content_items:assignments_export_rql',
    defaultFetchItemsOptions,
    exporter
  );
  const { copyEmailsActionCallback } = useCopyEmailsToClipboard(
    actions.assignment.listEmails,
    defaultFetchItemsOptions
  );

  const postActionCallback = createPostActionCallback({
    label: 'assignment',
    total,
    setErrors: (errors: any[]) => {
      if (isNil(bulkAction)) return;
      setBulkAction({ ...bulkAction, errors });
    },
    onSuccess: () => setShowEditModal(false),
    onFinished: () => {
      publishEvent(CACHE_KEY, 'forceReload');
      refreshContent(contentItem.public_id, {
        view_mode: CONTENT_ITEM_VIEW_MODES.manage_assignments,
      });
    },
  });

  const postCreateCallback = createPostActionCallback({
    label: 'assignment',
    action: 'created',
    total: totalUsers,
    setErrors: (errors: any[]) => setBulkCreateErrors(errors),
    onSuccess: () => {
      setShowBulkAssignModal(false);
      setShowSelectUsersModal(true);
    },
    onFinished: () => {
      publishEvent(CACHE_KEY, 'forceReload');
      refreshContent(contentItem.public_id, {
        view_mode: CONTENT_ITEM_VIEW_MODES.manage_assignments,
      });
    },
  });

  const {
    bulkUpdate,
    bulkComplete,
    bulkUndoComplete,
    bulkExempt,
    bulkUndoExempt,
    bulkDrop,
    bulkExpire,
    isLoading,
  } = useAssignmentsBulkActions({
    postActionCallback,
  });

  const { bulkCreate, isLoading: isCreatingAssignments } = useAssignmentsBulkActions({
    postActionCallback: postCreateCallback,
  });

  const handleBulkAction = ({ dueAt, expiresAt }) => {
    if (isNil(bulkAction)) return;
    setBulkAction({ ...bulkAction, errors: [] });
    const bulkActionFunc = get(
      {
        [ASSIGNMENT_ACTIONS.update]: bulkUpdate,
        [ASSIGNMENT_ACTIONS.complete]: bulkComplete,
        [ASSIGNMENT_ACTIONS.undo_complete]: bulkUndoComplete,
        [ASSIGNMENT_ACTIONS.exempt]: bulkExempt,
        [ASSIGNMENT_ACTIONS.undo_exempt]: bulkUndoExempt,
        [ASSIGNMENT_ACTIONS.drop]: bulkDrop,
        [ASSIGNMENT_ACTIONS.expire]: bulkExpire,
      },
      bulkAction.name,
      noop
    );
    const reason = get(ASSIGNMENT_ROSTER_ACTIONS_REASONS, bulkAction.name);
    const extra =
      bulkAction.name === ASSIGNMENT_ACTIONS.update ? { due_at: dueAt, expires_at: expiresAt } : {};
    bulkActionFunc({
      assignment_rql: `${expression}&${rql({ content_item: contentItem.public_id })}`,
      reason,
      ...extra,
    });
  };

  const handleRowEditClick = useCallback(
    (assignment: Assignment) => {
      setBulkAction({
        name: ASSIGNMENT_ACTIONS.update,
        selectedData: {
          selectedItems: [assignment],
          selectAll: false,
          rows: [],
          rowCount: 0,
          expression: getCachedExpression(CACHE_KEY) || expression,
        },
        errors: [],
      });
      setShowEditModal(true);
    },
    [expression, getCachedExpression]
  );

  const handleRowViewProgressClick = useCallback(
    (assignment: Assignment) => {
      setTargetUser(assignment.user);
      setShowTrackItemAssignmentListModal(true);
    },
    [setTargetUser, setShowTrackItemAssignmentListModal]
  );
  const handleViewProgressClose = () => {
    setTargetUser(null);
    setShowTrackItemAssignmentListModal(false);
  };

  const isCourse = contentItem.content_type === CONTENT_TYPES.course;
  const isVideo = contentItem.content_type === CONTENT_TYPES.video;
  const hasProgressColumn = includes(CONTENT_TYPES_WITH_PROGRESS, contentItem.content_type);
  const hasScoreColumn = includes(CONTENT_TYPES_WITH_SCORE, contentItem.content_type);
  const hasAttemptColumn = includes(CONTENT_TYPES_WITH_ATTEMPT, contentItem.content_type);
  const canViewProgressDetails = includes(
    CONTENT_TYPES_WITH_PROGRESS_DETAILS,
    contentItem.content_type
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'user_name',
        headerName: 'Assignee',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1.5,
        display: 'flex',
        renderCell: ({ row: assignment }) => (
          <Dashboard.UserCell data={assignment.user} showAvatar highlighted />
        ),
      },
      {
        field: 'user_email',
        headerName: 'Email',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: assignment }) => get(assignment, 'user.email', ''),
      },
      {
        field: 'user_title',
        headerName: 'Title',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1.5,
        renderCell: ({ row: assignment }) => get(assignment, 'user.title', ''),
      },
      {
        field: 'user_department',
        headerName: 'Department',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: assignment }) => get(assignment, 'user.department', ''),
      },
      {
        field: 'user_working_since',
        headerName: 'Hire date',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: assignment }) => {
          const workingSince = get(assignment, 'user.working_since');
          return workingSince ? displayDate(workingSince) : '';
        },
      },
      {
        field: 'user_location_text',
        headerName: 'Location',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: assignment }) => get(assignment, 'user.location_text', ''),
      },
      {
        field: 'created',
        headerName: 'Assigned',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: assignment }) => {
          const { created_at: createdAt } = assignment;
          return createdAt ? displayDate(createdAt) : '';
        },
      },
      {
        field: 'due_at',
        type: 'date',
        headerName: 'Due',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        display: 'flex',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (value) => {
          return value && new Date(value);
        },
        renderCell: ({ row: assignment }) => {
          const { due_at: dueAt, is_overdue: isOverdue } = assignment;
          const text = dueAt ? displayDate(dueAt) : '';
          return isOverdue ? <TraitChip text={text} title="Past due" /> : text;
        },
      },
      {
        field: 'expires_at',
        type: 'date',
        headerName: 'Expires',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        display: 'flex',
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (value) => {
          return value && new Date(value);
        },
        renderCell: ({ row: assignment }) => {
          const { expires_at: expiresAt, is_expired: isExpired } = assignment;
          const text = expiresAt ? displayDate(expiresAt) : '';
          return isExpired ? <TraitChip text={text} title="Expired" /> : text;
        },
      },
      ...(hasAttemptColumn
        ? [
            // Assignment attempt
            {
              field: 'attempt',
              headerName: 'Attempt',
              filterable: false,
              sortable: true,
              editable: false,
              flex: 1.5,
              renderCell: ({ row: assignment }) => {
                const { attempt } = assignment;

                if (isNull(attempt)) return;

                return getOrdinalNumber(attempt);
              },
            },
          ]
        : []),
      ...(isCourse
        ? [
            {
              field: 'scorm_total_time',
              headerName: 'Total Time',
              description: 'Displayed in the format H:mm:ss',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 1,
              renderCell: ({ row: assignment }) => {
                const { scorm_total_time: scormTotalTime } = assignment;
                return isNil(scormTotalTime) ? '0:00:00' : formatSecondsToTime(scormTotalTime);
              },
            } as GridColDef,
            // SCORM attempts
            {
              field: 'scorm_attempts',
              headerName: 'Attempts',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 1,
              renderCell: ({ row: assignment }) => get(assignment, 'scorm_attempts', 0),
            },
          ]
        : []),
      ...(hasProgressColumn
        ? [
            {
              field: 'progress',
              headerName: 'Progress',
              description: isCourse
                ? 'Progress support is only available in SCORM 2004 4th edition and cmi5 with the use of a progress extension.'
                : isVideo
                  ? 'Progress support is only available for select video sources.'
                  : null,
              filterable: false,
              sortable: false,
              editable: false,
              flex: 1.5,
              renderCell: ({ row: assignment }) => {
                const stateIsStarted = get(assignment, 'state') !== ASSIGNMENT_STATES.not_started;
                const stateIsComplete = get(assignment, 'state') === ASSIGNMENT_STATES.completed;

                const defaultProgressValue =
                  isCourse && stateIsStarted ? null : isVideo && stateIsComplete ? null : 0;

                return (
                  <Tooltip title="View Progress" disableHoverListener={!canViewProgressDetails}>
                    <Button
                      fullWidth
                      sx={{ p: 0, color: 'inherit', fontWeight: 'inherit' }}
                      onClick={
                        canViewProgressDetails
                          ? () => handleRowViewProgressClick(assignment)
                          : undefined
                      }
                      disabled={!canViewProgressDetails}
                      variant="text"
                    >
                      <StyledProgressBar
                        progress={get(assignment, 'progress')}
                        defaultProgressValue={defaultProgressValue}
                      />
                    </Button>
                  </Tooltip>
                );
              },
            } as GridColDef,
          ]
        : []),
      {
        field: 'latest_activity',
        headerName: 'Last interaction',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1,
        renderCell: ({ row: assignment }) => {
          const lastProgressAt = get(assignment, 'progress.last_progress_at');
          return lastProgressAt ? displayDate(lastProgressAt) : 'N/A';
        },
      },
      {
        field: 'feedback_text',
        headerName: 'Feedback',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: assignment, colDef }) => (
          <GridCellExpand value={get(assignment, 'rating.feedback')} width={colDef.computedWidth} />
        ),
      },
      {
        field: 'feedback_rating',
        headerName: 'Rating',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: assignment }) => toInteger(get(assignment, 'rating.rating', '')) || '',
      },
      {
        field: 'state',
        headerName: 'Status',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1.3,
        renderCell: ({ row: assignment }) => <AssignmentStateChip assignment={assignment} />,
      },
      {
        field: 'Completed',
        headerName: 'Completed On',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1.3,
        renderCell: ({ row: assignment }) => {
          const { completion: completedAt } = assignment;
          return completedAt ? displayDate(completedAt.completed_at) : '';
        },
      },
      ...(isCourse
        ? [
            {
              field: 'scorm_result',
              headerName: 'Scorm Result',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 1.3,
              renderCell: ({ row: assignment }) => (
                <CourseRegistrationResultChip
                  courseRegistration={assignment as CourseRegistration}
                />
              ),
            },
          ]
        : []),
      ...(hasScoreColumn
        ? [
            {
              field: 'score',
              headerName: 'Score',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 1,
              renderCell: ({ row: assignment }) => {
                const { score } = assignment;
                if (isNull(score)) return;
                return `${(toNumber(score) * 100).toFixed(2)}%`;
              },
            },
            {
              field: 'is_success',
              headerName: 'Result',
              filterable: false,
              sortable: false,
              editable: false,
              flex: 1.3,
              renderCell: ({ row: { is_success } }) => {
                if (isNil(is_success)) return;
                return (
                  <Chip
                    label={is_success ? 'Passed' : 'Failed'}
                    size="small"
                    sx={{
                      borderRadius: 1,
                      backgroundColor: is_success ? blue[200] : red[200],
                    }}
                    icon={is_success ? <CheckCircleOutlineIcon /> : <CancelIcon />}
                  />
                );
              },
            },
          ]
        : []),
      {
        field: 'actions',
        type: 'actions',
        width: 20,
        sortable: false,
        editable: false,
        getActions: (props) =>
          AssignmentsActions({
            ...props,
            handleRowEditClick,
            hideEdit,
            handleRowViewProgressClick: canViewProgressDetails
              ? handleRowViewProgressClick
              : undefined,
          }),
      },
    ],
    [
      canViewProgressDetails,
      handleRowEditClick,
      handleRowViewProgressClick,
      hasAttemptColumn,
      hasProgressColumn,
      hasScoreColumn,
      hideEdit,
      isCourse,
      isVideo,
    ]
  );

  const ActionIcon = ({ action }: { action: ASSIGNMENT_ACTIONS }) => {
    const Icon = ASSIGNMENT_ACTIONS_ICONS[action];
    return <Icon />;
  };

  const itemPicker = useMemo(() => {
    return (
      <ItemPicker
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'user_name'],
          right: ['actions'],
        }}
        columnVisibilityModel={{
          user_email: false,
          user_title: !isCourse,
          user_department: !isCourse,
          user_working_since: false,
          user_location_text: false,
          feedback_text: false,
          feedback_rating: false,
          scorm_result: false,
        }}
        initialFiltersConfig={[{ user_name: null }, { due_date: null }, { state: null }]}
        fetchAction={actions.assignment.list}
        fetchActionOptions={rql(defaultFetchItemsOptions)}
        schema={assignmentSchema}
        filtersAction={actions.contentAssignment.retrieveRqlFilters}
        fetchFiltersOptions={{
          initial_filters: ['user_name', 'due_date', 'state'],
          filters_to_exclude: [
            'content_*',
            'user_eligible_for_assignment',
            'user_eligible_for_reassignment',
          ],
        }}
        defaultOrdering={defaultOrdering}
        columns={columns}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[5, 15, 25, 50, 100, 250]}
        topBarActions={[
          [
            {
              label: 'Bulk actions',
              callback: noop,
              onClickExpand: true,
              tooltip: ({ selectedItems, selectAll }) =>
                size(selectedItems) === 0 && !selectAll ? 'Select at least one assignment' : '',
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: 'Export CSV',
              callback: exportCSVActionCallback,
              startIcon: <DownloadIcon />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: 'Copy e-mails',
              callback: copyEmailsActionCallback,
              startIcon: <MailIcon />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            ...(hideEdit
              ? []
              : [
                  {
                    label: `${ASSIGNMENT_ACTIONS_LABELS.update}...`,
                    callback: (props: ActionCallbackProps) => {
                      setBulkAction({
                        name: ASSIGNMENT_ACTIONS.update,
                        selectedData: props,
                        errors: [],
                      });
                      setShowEditModal(true);
                    },
                    startIcon: <ActionIcon action={ASSIGNMENT_ACTIONS.update} />,
                    isDisabled: ({ selectedItems, selectAll }) =>
                      size(selectedItems) === 0 && !selectAll,
                  },
                ]),
            {
              label: `${ASSIGNMENT_ACTIONS_LABELS.complete}...`,
              callback: (props) => {
                setBulkAction({
                  name: ASSIGNMENT_ACTIONS.complete,
                  selectedData: props,
                  errors: [],
                });
                setShowEditModal(true);
              },
              startIcon: <ActionIcon action={ASSIGNMENT_ACTIONS.complete} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: `${ASSIGNMENT_ACTIONS_LABELS.undo_complete}...`,
              callback: (props) => {
                setBulkAction({
                  name: ASSIGNMENT_ACTIONS.undo_complete,
                  selectedData: props,
                  errors: [],
                });
                setShowEditModal(true);
              },
              startIcon: <ActionIcon action={ASSIGNMENT_ACTIONS.undo_complete} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: `${ASSIGNMENT_ACTIONS_LABELS.exempt}...`,
              callback: (props) => {
                setBulkAction({
                  name: ASSIGNMENT_ACTIONS.exempt,
                  selectedData: props,
                  errors: [],
                });
                setShowEditModal(true);
              },
              startIcon: <ActionIcon action={ASSIGNMENT_ACTIONS.exempt} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: `${ASSIGNMENT_ACTIONS_LABELS.undo_exempt}...`,
              callback: (props) => {
                setBulkAction({
                  name: ASSIGNMENT_ACTIONS.undo_exempt,
                  selectedData: props,
                  errors: [],
                });
                setShowEditModal(true);
              },
              startIcon: <ActionIcon action={ASSIGNMENT_ACTIONS.undo_exempt} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: 'Mark as Expired',
              callback: (props) => {
                setBulkAction({
                  name: ASSIGNMENT_ACTIONS.expire,
                  selectedData: props,
                  errors: [],
                });
                setShowEditModal(true);
              },
              startIcon: <ErrorIcon />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: `${ASSIGNMENT_ACTIONS_LABELS.drop}...`,
              callback: (props) => {
                setBulkAction({
                  name: ASSIGNMENT_ACTIONS.drop,
                  selectedData: props,
                  errors: [],
                });
                setShowEditModal(true);
              },
              startIcon: <ActionIcon action={ASSIGNMENT_ACTIONS.drop} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
              sx: { color: red['500'] },
            },
          ],
          [
            {
              label: `${assignLabel}`,
              callback: () => {
                setAssignmentMethod(ASSIGNMENT_FILTERS.eligible_for_assignment);
                setShowSelectUsersModal(true);
              },
              variant: 'contained',
              startIcon: <AddIcon />,
            },
            {
              label: capitalize(`Re/${assignLabel}`),
              callback: () => {
                setAssignmentMethod(ASSIGNMENT_FILTERS.eligible_for_reassignment);
                setShowSelectUsersModal(true);
              },
              startIcon: <AddCircleOutlineIcon />,
            },
          ],
        ]}
        filterBarInputWidth="240px"
        isCellEditable={(params) =>
          includes(
            [
              ASSIGNMENT_STATES.not_started,
              ASSIGNMENT_STATES.in_progress,
              ASSIGNMENT_STATES.exempted,
            ],
            get(params, 'row.state')
          )
        }
        enableSelectAll
        selectionMode={TableSelectionMode.multiple}
        cacheKey={CACHE_KEY}
        backend="url"
        HCURL={HCURL}
      />
    );
  }, [
    assignLabel,
    columns,
    copyEmailsActionCallback,
    defaultFetchItemsOptions,
    exportCSVActionCallback,
    hideEdit,
    isCourse,
  ]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,
        alignSelf: 'center',
        borderRadius: 1,
        width: '80%',
        height: '100%',
        p: 3,
      }}
    >
      <Typography variant="h5">{`${capitalize(assignmentLabel)}s`}</Typography>
      {itemPicker}
      {showSelectUsersModal && assignmentMethod && (
        <SelectAssigneesModal
          content={contentItem}
          assignFilter={assignmentMethod}
          assignLabel={assignLabel}
          handleClose={() => {
            setShowSelectUsersModal(false);
            setUserAdded(false);
          }}
          cancelLabel={userAdded ? 'Close' : 'Cancel'}
          isReAssign={assignmentMethod === ASSIGNMENT_FILTERS.eligible_for_reassignment}
          actionButtonCallback={({ selectedItems, expression, rows, rowCount, selectAll }) => {
            setSelectedUsersData({
              selectedItems,
              expression: `${expression}&${assignmentMethod}=${contentItem.public_id}`,
              rows,
              rowCount,
              selectAll,
            });
            setShowSelectUsersModal(false);
            setBulkCreateErrors([]);
            setShowBulkAssignModal(true);
          }}
        />
      )}
      {showBulkAssignModal && contentItem.content_type !== CONTENT_TYPES.scheduled_track && (
        <BulkAssignmentFormModal
          content={contentItem}
          title={`${assignLabel} - ${get(contentItem, 'name', '')}`}
          actionLabel={assignLabel}
          sections={['dates']}
          selectedData={selectedUsersData}
          handleAction={({ dueAt, expiresAt }) => {
            bulkCreate({
              content_item_public_ids: [contentItem.public_id],
              user_rql: userRql,
              due_at: dueAt,
              expires_at: expiresAt,
              is_reassign: assignmentMethod === ASSIGNMENT_FILTERS.eligible_for_reassignment,
              reason: ASSIGNMENT_REASONS.roster_bulk_assignment,
            });
            setUserAdded(true);
          }}
          isLoading={isCreatingAssignments}
          errors={bulkCreateErrors}
          handleClose={() => {
            setShowBulkAssignModal(false);
            setShowSelectUsersModal(true);
          }}
          handleBack={() => {
            setShowBulkAssignModal(false);
            setShowSelectUsersModal(true);
          }}
        />
      )}
      {showBulkAssignModal && contentItem.content_type === CONTENT_TYPES.scheduled_track && (
        <ScheduledTrackAssignUsersModal
          content={contentItem}
          assignLabel={assignLabel}
          assignmentLabel={assignmentLabel}
          selectedData={selectedUsersData}
          isReAssign={assignmentMethod === ASSIGNMENT_FILTERS.eligible_for_reassignment}
          handleClose={() => {
            setShowBulkAssignModal(false);
            setShowSelectUsersModal(true);
          }}
          handleBack={() => {
            setShowBulkAssignModal(false);
            setShowSelectUsersModal(true);
          }}
        />
      )}
      {showEditModal && bulkAction && (
        <BulkAssignmentFormModal
          content={contentItem}
          title={`${ASSIGNMENT_ACTIONS_LABELS[bulkAction.name]} ${assignmentLabel}s - ${get(
            contentItem,
            'name',
            ''
          )}`}
          actionLabel="Save"
          sections={bulkAction.name === ASSIGNMENT_ACTIONS.update ? ['dates'] : []}
          selectedData={bulkAction.selectedData}
          handleAction={handleBulkAction}
          isLoading={isLoading}
          errors={bulkAction.errors}
          handleClose={() => setShowEditModal(false)}
          handleBack={() => setShowEditModal(false)}
        />
      )}
      {showTrackItemAssignmentListModal && contentItem && targetUser && (
        <Modal
          width={window.innerWidth > 1024 ? 1024 : '80%'}
          title={`${getContentTypeLabel(contentItem.content_type)} progress - ${targetUser.name}`}
          handleClose={handleViewProgressClose}
          handleBack={handleViewProgressClose}
        >
          <Box sx={{ px: 2 }}>
            <ListTrackItemAssignments contentItem={contentItem} user={targetUser} />
          </Box>
        </Modal>
      )}
    </Paper>
  );
};

export default ListAssignments;

import { get } from 'lodash';
import {
  ContentItemCardCover,
  ContentItemCardCoverProps,
} from '~/common/components/ContentItemCard/ContentItemCardCover';
import { Chip, Icon as MUIIcon, Box } from '@mui/material';
import moment from 'moment';
import type { ValueOf } from 'type-fest';
import {
  ASSIGNMENT_STATES,
  ASSIGNMENT_STATES_LABELS,
  ASSIGNMENT_STATES_ICONS,
  ASSIGNMENT_STATES_COLORS,
} from '~/app/assignments/constants';
import colors from '~/services/colors';
import { Assignment } from '~/app/shared-content-item/interfaces';
import NewIcon from '~/app/shared/components/Icon/Icons/new.svg';

type AssignmentStatusVariant = Extract<
  ValueOf<typeof ASSIGNMENT_STATES>,
  'in_progress' | 'completed'
>;

type AssignmentStatusPillProps = {
  state: AssignmentStatusVariant;
};

function AssignmentStatusPill(props: AssignmentStatusPillProps) {
  const { state } = props;

  const bg = ASSIGNMENT_STATES_COLORS[state];
  const Icon = ASSIGNMENT_STATES_ICONS[state] as typeof MUIIcon;
  const label = ASSIGNMENT_STATES_LABELS[state];

  return (
    <Chip
      icon={<Icon sx={{ color: `${colors.neutral0} !important` }} />}
      label={label}
      size="small"
      sx={{
        backgroundColor: bg,
        color: colors.neutral0,
      }}
    />
  );
}

type DefaultContentItemCardCover = ContentItemCardCoverProps & {
  userAssignment?: Assignment;
};

export function DefaultContentItemCardCover(props: DefaultContentItemCardCover) {
  const { contentItem, route, userAssignment, ...restCardActionAreaProps } = props;

  const createdAt = get(contentItem, 'created', get(contentItem, 'created_at'));

  const now = moment();
  const isNew = now.diff(createdAt, 'days') < 30;

  const assignmentState = get(userAssignment, 'state');
  const shouldDisplayAssignmentState =
    assignmentState === ASSIGNMENT_STATES.in_progress ||
    assignmentState === ASSIGNMENT_STATES.completed;

  return (
    <ContentItemCardCover contentItem={contentItem} route={route} {...restCardActionAreaProps}>
      {shouldDisplayAssignmentState && (
        <Box position="absolute" top="12px" left="16px">
          <AssignmentStatusPill state={assignmentState as AssignmentStatusVariant} />
        </Box>
      )}

      {isNew && (
        <Box position="absolute" top="0px" right="0px">
          <NewIcon />
        </Box>
      )}
    </ContentItemCardCover>
  );
}

import { useMemo, useState } from 'react';

import GridCellExpand from '~/app/datagrid/GridCellExpand';
import { EVENT_VIEW_MODES } from '~/app/event/constants';
import { ListAssignmentComponentProps } from '~/app/assignments/interfaces';
import Dashboard from '~/app/backoffice/components/Dashboard';
import EnrollmentAttendanceStatusChip from '~/app/enrollments/components/EnrollmentAttendanceStatusChip';
import {
  ATTENDANCE_METHODS_LABELS,
  ENROLLMENT_ACTIONS,
  ENROLLMENT_ACTIONS_ICONS,
  ENROLLMENT_ACTIONS_LABELS,
  ENROLLMENT_ROSTER_ACTIONS_REASONS,
  ENROLLMENT_VIEW_MODES,
} from '~/app/enrollments/constants';
import { useEnrollmentsBulkActions } from '~/app/enrollments/hooks';
import actions from '~/app/entities/actions';
import { contentSchema, enrollmentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import BulkAssignmentFormModal from '~/app/rosters/components/AssignmentsRoster/BulkAssignmentFormModal';
import { ASSIGNMENT_FILTERS } from '~/app/rosters/components/AssignmentsRoster/constants';
import SelectAssigneesModal from '~/app/rosters/components/AssignmentsRoster/SelectAssigneesModal';
import { useRQLFiltersContext } from '~/app/rosters/RQLFiltersContext';
import {
  createPostActionCallback,
  useCopyEmailsToClipboard,
  useExportCSVActionCallback,
  getNormalizedSelectedData,
} from '~/app/rosters/utils';
import { displayDate } from '~/services/datetime';
import { ApiURLs } from '~/services/requests-base';
import { TableSelectionMode } from '~/app/shared/components/constants';
import ItemPicker from '~/app/shared/components/ItemPicker';
import { ActionCallbackProps } from '~/app/shared/components/types';
import { get, size, noop, isNil, toInteger } from 'lodash-es';
import { red } from '@mui/material/colors';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';
import { Typography } from '~/common/components/Typography';
import { Paper } from '@mui/material';
import {
  AddCircleOutlineIcon,
  AddIcon,
  DownloadIcon,
  MailIcon,
  PrintOutlinedIcon,
  ReduceCapacityOutlinedIcon,
} from '~/vendor/mui-icons';
import rql from '~/vendor/rql';

import EnrollmentsActions from './EnrollmentsActions';
import ManageQueue from './ManageQueue';

interface BulkAction {
  name: ENROLLMENT_ACTIONS;
  selectedData: ActionCallbackProps;
  errors?: any[];
}

const PAGE_SIZE = 25;
const CACHE_KEY = 'enrollments_roster';

const ListEnrollments = ({ contentItem: event, HCURL }: ListAssignmentComponentProps) => {
  const defaultOrdering = '-created';
  const { publishEvent } = useRQLFiltersContext();
  const [bulkAction, setBulkAction] = useState<BulkAction | null>(null);
  const [bulkCreateErrors, setBulkCreateErrors] = useState<any[]>();
  const [enrollmentMethod, setEnrollmentMethod] = useState<ASSIGNMENT_FILTERS | null>(null);
  const [showBulkActionModal, setShowBulkActionModal] = useState(false);
  const [showSelectUsersModal, setShowSelectUsersModal] = useState(false);
  const [showBulkEnrollModal, setShowBulkEnrollModal] = useState(false);
  const [showManageQueueModal, setShowManageQueueModal] = useState(false);
  const [selectedUsersData, setSelectedUsersData] = useState<ActionCallbackProps>({
    selectedItems: [],
    expression: '',
    rows: [],
    rowCount: 0,
    selectAll: false,
  });

  const [refreshEvent] = useEntities(actions.content.retrieveDetails, null, {
    schema: contentSchema,
  });
  const { total, expression } = getNormalizedSelectedData(get(bulkAction, 'selectedData'));
  const { total: totalUsers, expression: userRql } = getNormalizedSelectedData(
    selectedUsersData,
    'id'
  );
  const defaultFetchItemsOptions = useMemo(
    () => ({
      view_mode: 'roster',
      event: event.id,
    }),
    [event.id]
  );
  const { exportCSVActionCallback } = useExportCSVActionCallback(
    'api_enrollments:export_csv_rql',
    defaultFetchItemsOptions,
    'enrollment'
  );
  const { copyEmailsActionCallback } = useCopyEmailsToClipboard(
    actions.enrollment.listEmails,
    defaultFetchItemsOptions
  );

  const postActionCallback = createPostActionCallback({
    label: 'enrollment',
    total,
    setErrors: (errors: any[]) => {
      if (isNil(bulkAction)) return;
      setBulkAction({ ...bulkAction, errors });
    },
    onSuccess: () => setShowBulkActionModal(false),
    onFinished: () => {
      publishEvent(CACHE_KEY, 'forceReload');
      refreshEvent(event.public_id, { view_mode: EVENT_VIEW_MODES.manage_enrollments });
    },
  });

  const postCreateCallback = createPostActionCallback({
    label: 'enrollment',
    action: 'created',
    total: totalUsers,
    setErrors: (errors: any[]) => setBulkCreateErrors(errors),
    onSuccess: () => {
      setShowBulkEnrollModal(false);
      setShowSelectUsersModal(true);
    },
    onFinished: () => {
      publishEvent(CACHE_KEY, 'forceReload');
      refreshEvent(event.public_id, { view_mode: EVENT_VIEW_MODES.manage_enrollments });
    },
  });

  const { bulkPromote, bulkDemote, bulkCheckin, bulkUndoCheckin, bulkDrop, isLoading } =
    useEnrollmentsBulkActions({
      postActionCallback,
    });

  const { bulkCreate, isLoading: isCreatingEnrollments } = useEnrollmentsBulkActions({
    postActionCallback: postCreateCallback,
  });

  const handleBulkAction = () => {
    if (isNil(bulkAction)) return;
    setBulkAction({ ...bulkAction, errors: [] });
    const bulkActionFunc = get(
      {
        [ENROLLMENT_ACTIONS.promote]: bulkPromote,
        [ENROLLMENT_ACTIONS.demote]: bulkDemote,
        [ENROLLMENT_ACTIONS.checkin]: bulkCheckin,
        [ENROLLMENT_ACTIONS.undo_checkin]: bulkUndoCheckin,
        [ENROLLMENT_ACTIONS.drop]: bulkDrop,
      },
      bulkAction.name,
      noop
    );
    const reason = get(ENROLLMENT_ROSTER_ACTIONS_REASONS, bulkAction.name);
    bulkActionFunc({ enrollment_rql: `${expression}&${rql({ event: event.id })}`, reason });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'user_name',
        headerName: 'Person',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1.5,
        renderCell: ({ row: enrollment }) => (
          <Dashboard.UserCell data={enrollment.user} showAvatar highlighted />
        ),
      },
      {
        field: 'user_email',
        headerName: 'Email',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => get(enrollment, 'user.email', ''),
      },
      {
        field: 'user_title',
        headerName: 'Title',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1.5,
        renderCell: ({ row: enrollment }) => get(enrollment, 'user.title', ''),
      },
      {
        field: 'user_department',
        headerName: 'Department',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => get(enrollment, 'user.department', ''),
      },
      {
        field: 'user_working_since',
        headerName: 'Hire date',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => {
          const workingSince = get(enrollment, 'user.working_since');
          return workingSince ? displayDate(workingSince) : '';
        },
      },
      {
        field: 'user_location_text',
        headerName: 'Location',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => get(enrollment, 'user.location_text', ''),
      },
      {
        field: 'created',
        headerName: 'Created',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => {
          const { created_at: createdAt } = enrollment;
          return createdAt ? displayDate(createdAt) : '';
        },
      },
      {
        field: 'attendance_method',
        headerName: 'Attendance Method',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) =>
          get(ATTENDANCE_METHODS_LABELS, enrollment.attendance_method, ''),
      },
      {
        field: 'feedback_text',
        headerName: 'Feedback',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment, colDef }) => (
          <GridCellExpand value={get(enrollment, 'rating.feedback')} width={colDef.computedWidth} />
        ),
      },
      {
        field: 'feedback_rating',
        headerName: 'Rating',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => toInteger(get(enrollment, 'rating.rating', '')) || '',
      },
      {
        field: 'attendance_status',
        headerName: 'Status',
        filterable: false,
        sortable: false,
        editable: false,
        flex: 1,
        renderCell: ({ row: enrollment }) => (
          <EnrollmentAttendanceStatusChip enrollment={enrollment} />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 20,
        sortable: false,
        editable: false,
        getActions: (props) =>
          EnrollmentsActions({ ...props, viewMode: ENROLLMENT_VIEW_MODES.roster }),
      },
    ],
    []
  );

  const ActionIcon = ({ action }: { action: ENROLLMENT_ACTIONS }) => {
    const Icon = ENROLLMENT_ACTIONS_ICONS[action];
    return <Icon />;
  };

  const itemPicker = useMemo(() => {
    return (
      <ItemPicker
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'user_name'],
          right: ['actions'],
        }}
        columnVisibilityModel={{
          user_email: false,
          user_working_since: false,
          user_location_text: false,
          feedback_text: false,
          feedback_rating: false,
        }}
        initialFiltersConfig={[{ user: null }, { status: null }, { attendance_method: null }]}
        fetchAction={actions.enrollment.retrieveList}
        fetchActionOptions={rql(defaultFetchItemsOptions)}
        schema={enrollmentSchema}
        filtersAction={actions.enrollment.retrieveFilters}
        fetchFiltersOptions={{
          initial_filters: ['user', 'status', 'attendance_method'],
          filters_to_exclude: ['event_*'],
        }}
        defaultOrdering={defaultOrdering}
        columns={columns}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[5, 15, 25, 50, 100, 250]}
        topBarActions={[
          {
            label: 'Print roster',
            callback: () => {
              const url = ApiURLs['events:printable_roster']({ public_id: event.public_id });
              // Using "window" here because the print roster page is a Django page
              window.open(url, '_blank');
            },
            startIcon: <PrintOutlinedIcon />,
          },
          {
            label: 'Manage queues',
            callback: () => setShowManageQueueModal(true),
            startIcon: <ReduceCapacityOutlinedIcon />,
          },
          [
            {
              label: 'Bulk actions',
              callback: noop,
              onClickExpand: true,

              tooltip: ({ selectedItems, selectAll }) =>
                size(selectedItems) === 0 && !selectAll ? 'Select at least one enrollment' : '',
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: 'Export CSV',
              callback: exportCSVActionCallback,
              startIcon: <DownloadIcon />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: 'Copy e-mails',
              callback: copyEmailsActionCallback,
              startIcon: <MailIcon />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: ENROLLMENT_ACTIONS_LABELS.checkin,
              callback: (props) => {
                setBulkAction({ name: ENROLLMENT_ACTIONS.checkin, selectedData: props });
                setShowBulkActionModal(true);
              },
              startIcon: <ActionIcon action={ENROLLMENT_ACTIONS.checkin} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: ENROLLMENT_ACTIONS_LABELS.undo_checkin,
              callback: (props) => {
                setBulkAction({ name: ENROLLMENT_ACTIONS.undo_checkin, selectedData: props });
                setShowBulkActionModal(true);
              },
              startIcon: <ActionIcon action={ENROLLMENT_ACTIONS.undo_checkin} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: ENROLLMENT_ACTIONS_LABELS.promote,
              callback: (props) => {
                setBulkAction({ name: ENROLLMENT_ACTIONS.promote, selectedData: props });
                setShowBulkActionModal(true);
              },
              startIcon: <ActionIcon action={ENROLLMENT_ACTIONS.promote} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: ENROLLMENT_ACTIONS_LABELS.demote,
              callback: (props) => {
                setBulkAction({ name: ENROLLMENT_ACTIONS.demote, selectedData: props });
                setShowBulkActionModal(true);
              },
              startIcon: <ActionIcon action={ENROLLMENT_ACTIONS.demote} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: ENROLLMENT_ACTIONS_LABELS.drop,
              callback: (props) => {
                setBulkAction({ name: ENROLLMENT_ACTIONS.drop, selectedData: props });
                setShowBulkActionModal(true);
              },
              startIcon: <ActionIcon action={ENROLLMENT_ACTIONS.drop} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
              sx: { color: red['500'] },
            },
          ],
          [
            {
              label: 'Enroll',
              callback: () => {
                setEnrollmentMethod(ASSIGNMENT_FILTERS.eligible_for_assignment);
                setShowSelectUsersModal(true);
              },
              variant: 'contained',
              startIcon: <AddIcon />,
            },
            {
              label: 'Re/enroll',
              callback: () => {
                setEnrollmentMethod(ASSIGNMENT_FILTERS.eligible_for_reassignment);
                setShowSelectUsersModal(true);
              },
              startIcon: <AddCircleOutlineIcon />,
            },
          ],
        ]}
        filterBarInputWidth="240px"
        selectionMode={TableSelectionMode.multiple}
        cacheKey={CACHE_KEY}
        backend="url"
        enableSelectAll
        HCURL={HCURL}
      />
    );
  }, [
    columns,
    copyEmailsActionCallback,
    defaultFetchItemsOptions,
    defaultOrdering,
    exportCSVActionCallback,
    event.public_id,
  ]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,
        alignSelf: 'center',
        borderRadius: 1,
        width: '80%',
        height: '100%',
        p: 3,
      }}
    >
      <Typography variant="h5">Enrollments</Typography>
      {itemPicker}
      {showBulkActionModal && bulkAction && (
        <BulkAssignmentFormModal
          content={event}
          title={`${ENROLLMENT_ACTIONS_LABELS[bulkAction.name]} Enrollments - ${get(
            event,
            'name',
            ''
          )}`}
          actionLabel={ENROLLMENT_ACTIONS_LABELS[bulkAction.name]}
          selectedData={bulkAction.selectedData}
          handleAction={handleBulkAction}
          isLoading={isLoading}
          errors={bulkAction.errors}
          handleClose={() => setShowBulkActionModal(false)}
          handleBack={() => setShowBulkActionModal(false)}
        />
      )}
      {showSelectUsersModal && enrollmentMethod && (
        <SelectAssigneesModal
          content={event}
          assignFilter={enrollmentMethod}
          assignLabel="Enroll"
          handleClose={() => setShowSelectUsersModal(false)}
          isReAssign={enrollmentMethod === ASSIGNMENT_FILTERS.eligible_for_reassignment}
          actionButtonCallback={({ selectedItems, expression, rows, rowCount, selectAll }) => {
            setSelectedUsersData({
              selectedItems,
              expression: `${expression}&${enrollmentMethod}=${event.public_id}`,
              rows,
              rowCount,
              selectAll,
            });
            setShowSelectUsersModal(false);
            setBulkCreateErrors([]);
            setShowBulkEnrollModal(true);
          }}
        />
      )}
      {showBulkEnrollModal && (
        <BulkAssignmentFormModal
          content={event}
          title={`${ENROLLMENT_ACTIONS_LABELS.create} - ${get(event, 'name', '')}`}
          actionLabel={ENROLLMENT_ACTIONS_LABELS.create}
          sections={['attendance_method', 'enrollment_policy']}
          selectedData={selectedUsersData}
          handleAction={({ attendanceMethod, enrollmentPolicy, forceCheckin }) =>
            bulkCreate({
              event_ids: [event.id],
              user_rql: userRql,
              attendance_method: attendanceMethod,
              enrollment_policy: enrollmentPolicy,
              force_checkin: forceCheckin,
              is_reenroll: enrollmentMethod === ASSIGNMENT_FILTERS.eligible_for_reassignment,
              reason: ENROLLMENT_ROSTER_ACTIONS_REASONS.create,
            })
          }
          isLoading={isCreatingEnrollments}
          errors={bulkCreateErrors}
          handleClose={() => {
            setShowBulkEnrollModal(false);
            setShowSelectUsersModal(true);
          }}
          handleBack={() => {
            setShowBulkEnrollModal(false);
            setShowSelectUsersModal(true);
          }}
        />
      )}
      {showManageQueueModal && (
        <ManageQueue event={event} handleClose={() => setShowManageQueueModal(false)} />
      )}
    </Paper>
  );
};

export default ListEnrollments;

import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router';

import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';

import { queries } from '~/queries';
import { useEditArticleByPublicId } from '~/features/articles/api/mutations';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import { mapRoute } from '~/services/requests';
import Loading from '~/app/shared/components/Loading';
import { useOriginRoute, useLabels } from '~/app/shared/hooks';
import { toLower, includes } from 'lodash-es';

import ArticleFormPage from './ArticleFormPage';

const ArticleEdit = () => {
  const { public_id: publicId } = useParams();
  const { label_article: labelArticle, label_article_plural: labelArticlePlural } = useLabels();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.articles
  }&o=upcoming`;
  const articleDetailRoute = mapRoute('standAloneArticleDetail', { public_id_and_slug: publicId });
  const backRoute = useOriginRoute(articleDetailRoute);

  const {
    data: article,
    isLoading,
    isError,
  } = useQuery({ ...queries.articles.detail_by_public_id(publicId) });

  const onSubmit = useEditArticleByPublicId(publicId).mutateAsync;

  if (isLoading) return <Loading />;

  const userCanSee =
    !isLoading && !isError && includes(article.permissions, CONTENT_ITEM_PERMISSIONS.manage);

  if (!userCanSee) return <Loading />;

  const initialValuesParams = { article };

  return (
    <ArticleFormPage
      pageTitle={`Edit ${labelArticle}`}
      topBarActionName={`Editing ${labelArticle}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelArticlePlural,
          link: catalogRoute,
        },
        {
          label: article.name,
          link: articleDetailRoute,
        },
        {
          label: `Edit ${toLower(labelArticle)}`,
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default ArticleEdit;

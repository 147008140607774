import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { IdParam } from '~/common/types';
import actions from '~/app/entities/actions';
import { topicSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import colors from '~/services/colors';
import { STATUS_LOADING } from '~/app/shared/constants';
import { useDataGridPaginationAndSorting } from '~/app/shared/hooks';
import { toNumber } from 'lodash-es';
import { DataGridPro as DataGrid, GridColDef } from '@mui/x-data-grid-pro';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';

interface TopicQueryParams {
  view_mode: string;
  page_size: number;
  page?: number;
  user_id: number;
  all_tags: boolean;
  o: string;
}

export const ContentCompletionByTagsWidget = () => {
  const { id: userId } = useParams<IdParam>();

  const {
    pageNumber,
    rowsPerPage,
    rowsPerPageOptions,
    sort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSort,
  } = useDataGridPaginationAndSorting({ defaultSort: '-total_completion_count' });

  const [fetchTopics, { data: topics, status: topicsStatus, count: topicsCount }] = useEntities(
    actions.topic.retrieveList,
    null,
    {
      schema: [topicSchema],
    }
  );

  useEffect(() => {
    const params: TopicQueryParams = {
      view_mode: 'user_engagement',
      page_size: rowsPerPage,
      user_id: toNumber(userId),
      all_tags: true,
      o: sort,
    };
    if (pageNumber !== 0) {
      params.page = pageNumber + 1;
    }

    fetchTopics(params);
  }, [userId, pageNumber, rowsPerPage, sort]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Tag',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 3,
    },
    {
      field: 'ninety_days_completion_count',
      headerName: 'Past 90 Days',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: true,
      editable: false,
      width: 160,
    },
    {
      field: 'total_completion_count',
      headerName: 'Total Completion',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: true,
      editable: false,
      width: 160,
    },
  ];

  const isLoading = topicsStatus === STATUS_LOADING;
  return (
    <Box p={2}>
      <Typography gutterBottom variant="h6" sx={{ color: colors.neutral900 }}>
        Content Completion by Tags
      </Typography>
      <DataGrid
        autoHeight
        sx={{
          border: 'none',
        }}
        loading={isLoading}
        columns={columns}
        rows={topics}
        rowCount={topicsCount}
        pagination
        paginationModel={{
          page: pageNumber,
          pageSize: rowsPerPage,
        }}
        onPaginationModelChange={(model) => {
          handleChangePage(model.page);
          handleChangeRowsPerPage(model.pageSize);
        }}
        pageSizeOptions={rowsPerPageOptions}
        onSortModelChange={handleChangeSort}
        paginationMode="server"
        sortingMode="server"
        disableRowSelectionOnClick
      />
    </Box>
  );
};

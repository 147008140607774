import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers-pro';

import colors from '~/services/colors';
import { displayDate } from '~/services/datetime';
import Modal, { ModalBody, ModalFooter } from '~/app/shared/components/Modal';

const DueDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.neutral100};
  border-radius: 8px;
  padding: 12px;
  margin: 16px;

  > * + * {
    margin-left: 8px;
  }
`;

const TrackItemChangeAssignmentDueDateModal = ({ item, index, handleUpdate, handleClose }) => {
  const [assignmentDueDate, setAssignmentDueDate] = useState(moment(item.assignment_due_date));

  const handleDueDateChange = (value) => setAssignmentDueDate(value);

  const handleSubmit = () => {
    item.assignment_due_date = assignmentDueDate?.format('YYYY-MM-DDT23:59:59');
    item.humanizedAssignmentDueDate = item.assignment_due_date
      ? `Due ${displayDate(item.assignment_due_date)}`
      : null;

    handleUpdate(item, index);
    handleClose();
  };

  return (
    <Modal
      title={`Change due date of "${item.content_item.name}"`}
      width={600}
      overflow="hidden"
      handleClose={handleClose}
    >
      <ModalBody>
        <DueDateContainer>
          <DatePicker
            label="Assignment Due Date"
            value={assignmentDueDate}
            onChange={handleDueDateChange}
            name="assignment_due_date"
            slotProps={{
              field: {
                clearable: true,
              },
            }}
          />
        </DueDateContainer>
      </ModalBody>
      <ModalFooter variant="buttons">
        <Modal.FooterButton color="error" onClick={handleClose}>
          Cancel
        </Modal.FooterButton>
        <Modal.FooterButton onClick={handleSubmit}>Continue</Modal.FooterButton>
      </ModalFooter>
    </Modal>
  );
};

TrackItemChangeAssignmentDueDateModal.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  handleUpdate: PropTypes.func,
  handleClose: PropTypes.func,
};

export default TrackItemChangeAssignmentDueDateModal;

import {
  CardMedia as MUICardMedia,
  CardActionArea as MUICardActionArea,
  CardActionAreaProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ContentItem } from '~/app/shared-content-item/interfaces';

const CardMedia = styled(MUICardMedia)(() => ({
  height: '165px',
}));

const CardActionArea = styled(MUICardActionArea, {
  name: 'ContentItemCard',
  slot: 'cover',
})<CardActionAreaProps & { component: ElementType; to: string }>`
  position: relative;
`;

export type ContentItemCardCoverProps = CardActionAreaProps & {
  contentItem: ContentItem;
  route: string;
  children?: React.ReactNode;
};

export function ContentItemCardCover(props: ContentItemCardCoverProps) {
  const { contentItem, route, children, ...restCardActionAreaProps } = props;

  const coverImg = contentItem.cover_url ?? contentItem.cover ?? contentItem.default_cover;

  return (
    <CardActionArea disableRipple component={RouterLink} to={route} {...restCardActionAreaProps}>
      <CardMedia image={coverImg} />

      {children}
    </CardActionArea>
  );
}

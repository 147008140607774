import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { detailQueryKeyFactory, listQueryKeyFactory } from '~/app/data-fetching/utils';

export const articles = createQueryKeys('articles', {
  list: listQueryKeyFactory({ endpointName: 'articles_api:list_create' }),
  detail: detailQueryKeyFactory({ endpointName: 'articles_api:retrieve_update_destroy' }),
  detail_by_public_id: detailQueryKeyFactory({
    endpointName: 'articles_api:retrieve_update_destroy_by_public_id',
  }),
});

export type ArticlesQueryKeys = inferQueryKeys<typeof articles>;

import React, { Suspense } from 'react';

import { lazyWithRetry } from '~/services/utils';
import Loading from '~/app/shared/components/Loading';
import { ErrorBoundary } from '~/app/shared';

const ReadOnlyTextEditor = lazyWithRetry(
  () => import(/* webpackChunkName: "ReadOnlyTextEditor" */ './ReadOnlyTextEditor')
);

const LazyReadOnlyTextEditor = (props) => (
  <ErrorBoundary>
    <Suspense fallback={<Loading />}>
      <ReadOnlyTextEditor {...props} />
    </Suspense>
  </ErrorBoundary>
);

export default React.memo(LazyReadOnlyTextEditor);
